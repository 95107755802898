import { Component } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import BottomNav from "./componets/BottomNav/BottomNav";

import Navigation from "./componets/Navigation/Navigation";
import Notification from "./componets/Notifications/Notification";
import Profile from "./componets/Profile/Profile";

import Login from "./pages/login/Login";
import Paradas from "./pages/paradas/Paradas";
import Resumen from "./pages/resumen/Resumen";
import Rutas from "./pages/rutas/Rutas";
import Rutinas from "./pages/rutinas/Rutinas";
import Seguimiento from "./pages/seguimiento/Seguimiento";
import { fetchImgs } from "./redux/actions/imgActions";

import "./App.scss";
import Pasajeros from "./pages/pasajeros/Pasajeros";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  fetchImgs: () => {
    dispatch(fetchImgs());
  },
});

class App extends Component {
  constructor() {
    super();
    this.state = {
      showNotification: false,
      showPorfile: false,
      Auth: localStorage.getItem("auth_user"),
      notifications: {},
      itemNavSelected: "Resumen",
    };
    this.showNotificationFunction = this.showNotificationFunction.bind(this);
    this.showPorfileFunction = this.showPorfileFunction.bind(this);
    this.setElementNav = this.setElementNav.bind(this);
    this.selectItemNav = this.selectItemNav.bind(this);
  }

  componentDidMount() {
    this.props.fetchImgs();
  }

  showNotificationFunction() {
    if (!this.state.showNotification) {
      this.setState({
        showNotification: !this.state.showNotification,
        DesactivateNotification: false,
      });
    } else {
      setTimeout(() => {
        this.setState({
          showNotification: !this.state.showNotification,
        });
      }, 200);
      this.setState({
        DesactivateNotification: true,
      });
    }
  }

  showPorfileFunction() {
    this.setState({
      showPorfile: !this.state.showPorfile,
    });
  }

  setElementNav(element) {
    if (element) {
      this.setState({
        navElement: element,
      });
    } else {
      this.setState({
        navElement: null,
      });
    }
  }

  selectItemNav(item) {
    this.setState({
      itemNavSelected: item,
    });
  }

  render() {
    return (
      <div className="App">
        <Router>
          {!this.state.Auth ? (
            <Redirect
              to={{
                pathname: "/session/login",
              }}
            />
          ) : null}
          <Switch>
            <Route component={Login} exact path="/session/login" />
            <>
              <Navigation
                showNotificationFunction={this.showNotificationFunction}
                showPorfileFunction={this.showPorfileFunction}
                itemNavSelected={this.state.itemNavSelected}
                element1={this.state.navElement}
              />
              {this.state.showNotification ? (
                <Notification
                  showNotification={this.state.showNotification}
                  DesactivateNotification={this.state.DesactivateNotification}
                />
              ) : null}
              {this.state.showPorfile ? (
                <Profile showPorfile={this.state.showNotification} />
              ) : null}
              <div className="appMain">
                <div className="main">
                  <Route exact path="/">
                    <Resumen selectItemNav={this.selectItemNav} />
                  </Route>
                  <Route exact path="/seguimiento">
                    <Seguimiento selectItemNav={this.selectItemNav} />
                  </Route>
                  <Route exact path="/rutinas">
                    <Rutinas selectItemNav={this.selectItemNav} />
                  </Route>
                  <Route exact path="/paradas">
                    <Paradas
                      setElementNav={this.setElementNav}
                      selectItemNav={this.selectItemNav}
                    />
                  </Route>
                  <Route exact path="/rutas">
                    <Rutas
                      setElementNav={this.setElementNav}
                      selectItemNav={this.selectItemNav}
                    />
                  </Route>
                  <Route exact path="/pasajeros">
                    <Pasajeros
                      setElementNav={this.setElementNav}
                      selectItemNav={this.selectItemNav}
                    />
                  </Route>
                </div>
              </div>
              <BottomNav />
            </>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
