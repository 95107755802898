import localStorageService from "../services/localStorageService";
import axios from "axios";

import {
  SET_USER_DATA,
  USER_LOGGED_OUT,
  DATA_FAILED,
  GET_USER_DATA,
  GET_USER_DATA_FAILED,
  GET_USER_DATA_SUCCESS,
} from "../types/user.types";

export const getUserData = () => async (dispatch) => {
  var config = {
    method: "GET",
    url: "/api/miroute/session",
    headers: {},
  };
  dispatch({
    type: GET_USER_DATA,
    payload: "Loading",
  });
  await axios(config)
    .then(function (response) {
      console.log(response);
      dispatch({
        type: GET_USER_DATA_SUCCESS,
        payload: response.data,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_USER_DATA_FAILED,
        payload: error,
      });
      logoutUser();
    });
};

export const dataFailed = (errmess) => ({
  type: DATA_FAILED,
  payload: errmess,
});

export function setUserData(user) {
  localStorageService.setItem("auth_user", user);
  return (dispatch) => {
    dispatch({
      type: SET_USER_DATA,
      data: user,
    });
  };
}

export function logoutUser() {
  //motionAuthService.logout(); el axios anterior reemplazaria este llamado
  localStorage.removeItem("auth_user");
  window.location.replace("/session/login");
  axios
    .get("/web/session/logout")
    .then(function (response) {
      // handle success
      console.log(response);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    });
  return (dispatch) => {
    dispatch({
      type: USER_LOGGED_OUT,
    });
  };
}
