import {
  GET_UNITS_LIST,
  GET_UNITS_LIST_SUCCCESS,
  GET_UNITS_LIST_FAILED,
  GET_UNITS_LIST_UPDATE,
  SOCKET_UNIT,
  SOCKET_UNIT_SUCCESS,
  SOCKET_UNIT_FAILED,
} from "../types/units.types";

const initialState = {
  isLoading: true,
  errMess: null,
  units: [],
};

const UnitsReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_UNITS_LIST:
      return { ...state, isLoading: true };
    case GET_UNITS_LIST_SUCCCESS:
      return {
        ...state,
        isLoading: false,
        units: action.payload,
      };
    case GET_UNITS_LIST_FAILED:
      return { ...state, isLoading: false, errMess: action.payload };
    case GET_UNITS_LIST_UPDATE:
      return {
        ...state,
        isLoading: false,
        units: state.units,
      };
    case SOCKET_UNIT:
      return {
        ...state,
      };
    case SOCKET_UNIT_SUCCESS:
      return {
        ...state,
        unit: action.payload,
      };
    case SOCKET_UNIT_FAILED:
      return {
        ...state,
        errMessUnit: action.payload,
      };
    default:
      return state;
  }
};

export default UnitsReducer;
