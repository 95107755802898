import React, { Component } from "react";
import "./SeguimientoMap.scss";

import mapStyle from "../../../../mapStyle/mapStyle";
import Map from "google-map-react";
import MarkerVehicle from "../../../../componets/MarkerVehicle/MarkerVehicle";
import { connect } from "react-redux";
import { socketUnit } from "../../../../redux/actions/unitsActions";

const mapStateToProps = (state) => {
  return {
    UnitsReducer: state.UnitsReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  socketUnit: (unit) => {
    dispatch(socketUnit(unit));
  },
});

class SeguimientoMap extends Component {
  constructor() {
    super();
    this.state = {
      zoom: 10,
      center: [4.655319272448736, -74.06834005475413],
      mapsLoaded: false,
      map: null,
      maps: null,
      i: 0,
      numDeltas: 100,
      delay: 150,
      unit: null,
      prevUnit: null,
      routine: null,
      stops: [],
      directionsRenderer: null,
      socketUnit: null,
      transitionSock: null,
      deltaLat: 0,
      deltaLng: 0,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.routineSelected !== this.props.routineSelected) {
      if (this.props.routineSelected) {
        const newRoutine = this.props.routines.find(
          (r) => r.id === this.props.routineSelected
        );
        this.setState({ routine: newRoutine });
      } else {
        this.cleanRoute();
      }
    }
    if (prevProps.UnitsReducer !== this.props.UnitsReducer) {
      if (this.props.UnitsReducer.unit) {
        this.setState({ unit: this.props.UnitsReducer.unit });
        if (!this.state.prevUnit) {
          this.setState({ prevUnit: this.props.UnitsReducer.unit });
        }
      }
    }
    if (prevState.unit !== this.state.unit) {
      if (this.state.prevUnit) {
        this.setState({ prevUnit: prevState.unit });
        this.transition({
          prevPosition: this.state.prevUnit.position,
          currentPosition: this.state.unit.position,
          id: this.state.unit.id,
        });
      }
    }
    if (prevState.routine !== this.state.routine) {
      if (this.props.routineSelected) {
        this.clearSocket();
        this.createStops(this.state.routine);
        this.getUnitPosition(this.state.routine.unit);
      }
    }
    if (prevState.stops !== this.state.stops) {
      this.renderRouteMap(this.state.stops);
    }
  }

  componentWillUnmount() {
    this.clearSocket();
  }

  createMapOptions() {
    return {
      disableDefaultUI: true,
      disableDoubleClickZoom: true,
      zoom: 5,
      options: {
        gestureHandling: "greedy",
      },
      minZoom: 5,
      maxZoom: 17,
      center: [4.655319272448736, -74.06834005475413],
      mapTypeControl: false,
      styles: mapStyle.silverMode,
    };
  }

  onMapLoaded(map, maps) {
    this.setState({ mapsLoaded: true, map: map, maps: maps });
  }

  createStops(routine) {
    const newStops = routine.schedules.map((schedule) => {
      const data = {
        center: JSON.parse(schedule.center),
      };
      return data;
    });
    this.setState({ stops: newStops });
  }

  renderRouteMap(stops) {
    if (stops.length > 1) {
      if (this.state.maps) {
        const directionsRenderer = new this.state.maps.DirectionsRenderer({});
        const directionsService = new this.state.maps.DirectionsService();

        this.setState({ directionsRenderer: directionsRenderer });
        this.calculateAndDisplayRoute(
          directionsRenderer,
          directionsService,
          stops
        );
      }
    }
  }

  calculateAndDisplayRoute(directionsRenderer, directionsService, stops) {
    if (this.state.maps) {
      const wayPoints = stops.map((point, index) => {
        if (index !== 0 && index !== stops.length - 1) {
          let LatLng = {
            location: {
              lat: point.center.coordinates[0],
              lng: point.center.coordinates[1],
            },
          };
          return LatLng;
        }
      });
      const newWayPoints = wayPoints.filter((point) => {
        return point !== undefined;
      });
      directionsRenderer.setMap(this.state.map);
      directionsService.route(
        {
          origin: {
            lat: stops[0].center.coordinates[0],
            lng: stops[0].center.coordinates[1],
          },
          destination: {
            lat: stops[stops.length - 1].center.coordinates[0],
            lng: stops[stops.length - 1].center.coordinates[1],
          },
          waypoints: newWayPoints ? newWayPoints : [],
          // Note that Javascript allows us to access the constant
          // using square brackets and a string value as its
          // "property."
          travelMode: this.state.maps.TravelMode.DRIVING,
          avoidTolls: true,
        },
        (response, status) => {
          if (status === "OK") {
            directionsRenderer.setDirections(response);
          } else {
            console.log(response);
            console.log(status);
          }
        }
      );
    }
  }

  cleanRoute() {
    if (this.state.directionsRenderer != null) {
      this.state.directionsRenderer.setMap(null);
      clearInterval(this.state.socketUnit);
      this.setState({
        routine: null,
        directionsRenderer: null,
        unit: null,
        prevUnit: null,
      });
    }
  }

  transition(data) {
    let deltaLat;
    let deltaLng;

    if (data.currentPosition) {
      deltaLat =
        (data.currentPosition.latitud - data.prevPosition.latitud) /
        this.state.numDeltas;
      deltaLng =
        (data.currentPosition.longitud - data.prevPosition.longitud) /
        this.state.numDeltas;

      this.state.deltaLat = deltaLat;
      this.state.deltaLng = deltaLng;

      if (
        data.prevPosition.latitud !== data.currentPosition.latitud &&
        data.prevPosition.longitud !== data.currentPosition.longitud &&
        deltaLat !== 0 &&
        deltaLng !== 0
      ) {
        clearTimeout(this.state.transitionSock);
        this.moveMarker();
      }
    }
  }

  moveMarker() {
    const newTransitionSock = setTimeout(async () => {
      const newPosition = {
        latitud:
          parseFloat(this.state.prevUnit.position.latitud) +
          parseFloat(this.state.deltaLat),
        longitud:
          parseFloat(this.state.prevUnit.position.longitud) +
          parseFloat(this.state.deltaLng),
      };
      this.setState({
        prevUnit: { ...this.state.prevUnit, position: newPosition },
      });
      this.state.i = this.state.i + 1;

      if (this.state.i >= this.state.numDeltas) {
        clearTimeout(this.state.transitionSock);
        this.state.i = 0;
      } else {
        this.moveMarker();
      }
    }, this.state.delay);
    this.setState({ transitionSock: newTransitionSock });
  }

  getUnitPosition(unit) {
    this.props.socketUnit(unit);
    const interval = setInterval(() => {
      this.props.socketUnit(unit);
    }, 15000);
    this.setState({ socketUnit: interval });
  }

  setUnitPosition() {
    this.setState({ unit: this.props.UnitsReducer.unit });
  }

  clearSocket() {
    if (this.state.socketUnit) {
      clearInterval(this.state.socketUnit);
    }
  }
  render() {
    return (
      <div className="SeguimientoMap">
        <div className="Seguimiento-content-map">
          <Map
            key="google_mapComponent"
            defaultCenter={this.state.center}
            defaultZoom={this.state.zoom}
            bootstrapURLKeys={{
              key: "AIzaSyBwfkPvvIzAUCw0VFGSr8fjhAsYRg5_E4I",
              libraries: ["visualization", "directions", "places", "drawing"],
            }}
            options={this.createMapOptions}
            yesIWantToUseGoogleMapApiInternals={true}
            onGoogleApiLoaded={({ map, maps }) => this.onMapLoaded(map, maps)}
          >
            {this.state.unit && this.state.prevUnit ? (
              <MarkerVehicle
                key={this.state.unit.id}
                id={this.state.unit.id}
                lat={this.state.prevUnit.position.latitud}
                lng={this.state.prevUnit.position.longitud}
                type={this.state.unit.img_type}
                color={this.state.unit.color}
                orientation={this.state.unit.position.orientacion}
                speed={this.state.unit.position.velocidad}
                zoom={this.state.zoom}
              />
            ) : null}
          </Map>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SeguimientoMap);
