import React, { useState, useEffect } from "react";
import "./ExcelPassengers.scss";
import { ReactComponent as IcoExit } from "../../../../assets/motion_icon-detenido.svg";
import { ReactComponent as IcoMas } from "../../../../assets/Mas.svg";
import { useDispatch } from "react-redux";
import { createPassengers } from "../../../../redux/actions/passengersActions";
import { useSelector } from "react-redux";

export default function ExcelPassengers(props) {
  const [passenger, setpassenger] = useState({
    name: "",
    user: "",
    password: "",
  });

  const [passengers, setPassengers] = useState([]);
  const [valid, setValid] = useState(true);
  const dispatch = useDispatch();
  var passegersRedux = useSelector((state) => state.PassengerReducer);

  useEffect(() => {
    setPassengers(props.passengers);
  }, [props.passengers]);

  useEffect(() => {
    var exist = false;
    passengers.map((passenger) =>
      passegersRedux.passengers.find((value) =>
        value.email === passenger.user
          ? (exist = true)
          : console.log("no existe")
      )
    );
    if (exist) setValid(false);
    else setValid(true);
  }, [passengers]);

  const handleChange = (e) =>
    setpassenger((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const addpassenger = () => setPassengers([...passengers, passenger]);

  const removepassenger = (id) =>
    setPassengers(
      passengers.filter((passenger) => passenger.__rowNum__ !== id)
    );

  const savePassengers = () => {
    dispatch(createPassengers(passengers));
  };

  return (
    <div className="ExcelPassengers">
      <div className="Excel-content">
        <div className="Excel-margin column">
          <form className="Excel-form row">
            <input
              type="text"
              placeholder="Nombre Completo"
              name="name"
              value={passenger.name}
              onChange={handleChange}
            />
            <input
              type="text"
              placeholder="Correo electronico"
              name="user"
              value={passenger.user}
              onChange={handleChange}
            />
            <input
              type="text"
              placeholder="Contraseña"
              name="password"
              value={passenger.password}
              onChange={handleChange}
            />
            <div
              className="Excel-form-button"
              onClick={passenger.user !== "" ? addpassenger : null}
              style={{
                cursor: passenger.user !== "" ? "pointer" : "not-allowed",
              }}
            >
              <IcoMas className="ico-mas" />
            </div>
          </form>
          <div className="Excel-body">
            <div className="Excel-body-content">
              {passengers.map((passenger, index) => {
                return (
                  <div
                    className="Excel-body-item row"
                    key={index}
                    style={
                      passegersRedux.passengers.find(
                        (value) => value.email === passenger.user
                      )
                        ? { backgroundColor: "#c6007e" }
                        : {}
                    }
                  >
                    <div className="text">{passenger.name}</div>
                    <div className="text">{passenger.user}</div>
                    <div className="text">{passenger.password}</div>
                    <div
                      className="delete"
                      onClick={() => removepassenger(passenger.__rowNum__)}
                    >
                      <IcoExit className="ico-exit" />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="footer row">
            <div>
              {!valid ? (
                <div>¡Algunos e-mails ya fueron utilizados!</div>
              ) : null}
            </div>
            <div className="content-actions row">
              <button className="btn" onClick={props.closeExcel}>
                Cancelar
              </button>
              <button
                className="btn3"
                style={
                  !valid || !(passengers.length > 0)
                    ? { cursor: "no-drop" }
                    : {}
                }
                onClick={
                  !valid || !(passengers.length > 0) ? null : savePassengers
                }
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="Excel-background" onClick={props.closeExcel}></div>
    </div>
  );
}
