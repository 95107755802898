import React, { Component } from 'react'
import Map from 'google-map-react'
import './ModalRutas.scss'
import mapStyle from '../../../../mapStyle/mapStyle'
import update from 'react-addons-update'

import { connect } from 'react-redux'
import Schedules from './components/Schedules/Schedules'
import {
  createRoute,
  editRoute,
  getSingleRoute,
} from '../../../../redux/actions/routesActions'
import RouteConfig from './components/RouteConfig/RouteConfig'
import AddStops from './components/AddStops/AddStops'
import SelectUnits from './components/SelectUnits/SelectUnits'
import { NewRoute as routeContext } from '../../../../state/context/RouteContext'
import { RouteModel } from '../../../../state/models/routeModel'

const mapStateToProps = (state) => {
  return {
    stops: state.stops.stops,
    ImgsReducer: state.ImgsReducer,
    UnitsReducer: state.UnitsReducer,
    RoutesReducer: state.RoutesReducer,
    RoutinesReducer: state.RoutinesReducer,
    passengersReducer: state.PassengerReducer,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getSingleRoute: (id) => {
    dispatch(getSingleRoute(id))
  },
  createRoute: (newRoute) => {
    dispatch(createRoute(newRoute))
  },
  editRoute: (newRoute) => {
    dispatch(editRoute(newRoute))
  },
})

class ModalRutas extends Component {
  constructor() {
    super()
    this.state = {
      entryStops: [],
      exitStops: [],
      mapOptions: {
        disableDefaultUI: true,
        disableDoubleClickZoom: true,
        zoom: 11,
        options: {
          gestureHandling: 'greedy',
        },
        minZoom: 5,
        maxZoom: 17,
        center: { lat: 4.658883676657488, lng: -74.06437579086325 },
        mapTypeControl: false,
        styles: mapStyle.silverMode,
      },
      newRoute: {
        name: '',
        number: '',
        units: [],
        type: '',
        auto_routines: false,
        del_routine_completed: false,
        expiration: '',
        checkpoints_order: 'strict',
        paste_last_point: false,
      },
      selectedNav: 'CONFIG',
      schedules: [],
      units: [],
    }
    // Funcion renderizar paradas
    this.renderRouteMap = this.renderRouteMap.bind(this)
    this.selectNav = this.selectNav.bind(this)
    // Funcion para crear ruta
    this.createRoute = this.createRoute.bind(this)
    // Funcion para editar ruta
    this.editRoute = this.editRoute.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.changeExitStops = this.changeExitStops.bind(this)
  }

  static contextType = routeContext
  static defaultProps = {
    center: {
      lat: 4.6,
      lng: -74.1,
    },
    zoom: 11,
  }

  componentDidMount() {
    if (this.props.selectedRoute && (this.props.edit || this.props.copy)) {
      this.props.getSingleRoute(this.props.selectedRoute.id)
      const stops = this.props.selectedRoute.stops.map((stop) => {
        let getStop = {
          ...stop,
          center: JSON.parse(stop.center),
          polygon: JSON.parse(stop.polygon),
          id_d: `item-${stop.id}`,
        }
        return getStop
      })
      let routines = this.props.RoutinesReducer.routines.filter(
        (routine) => routine.route === this.props.selectedRoute.id,
      )

      const outUnits = this.props.UnitsReducer.units.filter(
        (unit) => unit.img_type.id === this.props.selectedRoute.type.id,
      )

      this.setState({
        exitStops: stops,
        schedules: routines,
        newRoute: {
          ...this.state.newRoute,
          name: this.props.copy
            ? `${this.props.selectedRoute.name} copia`
            : this.props.selectedRoute.name,
          number: this.props.selectedRoute.number,
          type: this.props.selectedRoute.type.id,
          units: this.props.selectedRoute.units,
        },
        units: outUnits,
      })
    } else {
      this.setState({
        stops: this.props.stops,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.exitStops !== this.state.exitStops) {
      this.renderRouteMap()
    }
    if (this.props.RoutesReducer.single_route) {
      if (
        prevProps.RoutesReducer.single_route !==
        this.props.RoutesReducer.single_route
      ) {
        const newRoute = this.context.newRoute
        const setRoute = this.context.setRoute
        const selectedRoute = this.props.RoutesReducer.single_route
        setRoute({
          ...newRoute,
          id: selectedRoute.id,
          name: this.props.copy
            ? `${selectedRoute.name} copia`
            : selectedRoute.name,
          number: selectedRoute.number,
          type: selectedRoute.type.id,
          units: selectedRoute.units,
          schedule: selectedRoute.schedule,
          available_passengers_ids: selectedRoute.available_passengers_ids,
          checkpoints_order: selectedRoute.checkpoints_order,
          del_routines_completed: selectedRoute.del_routines_completed,
        })
      }
    }
    if (prevProps.stops !== this.props.stops) {
      this.setStops()
    }
  }

  setStops() {
    if (!(this.props.edit || this.props.copy)) {
      this.setState({
        entryStops: this.props.stops,
      })
    }
  }

  componentWillUnmount() {
    const setRoute = this.context.setRoute
    setRoute(RouteModel)
  }

  createMapOptions = () => {
    return {
      disableDefaultUI: true,
      disableDoubleClickZoom: true,
      zoom: this.props.zoom,
      options: {
        gestureHandling: 'greedy',
        styles: mapStyle.silverMode,
      },

      minZoom: 5,
      maxZoom: 17,
      center: this.props.center,
      mapTypeControl: false,
    }
  }

  handleMapChange = ({ center, zoom, bounds }) => {
    this.setState({
      zoom: zoom,
    })
  }

  onMapLoaded(map, maps) {
    this.setState({
      ...this.state,
      mapsLoaded: true,
      map: map,
      maps: maps,
    })
    this.renderRouteMap()
  }

  handleChange(event) {
    const target = event.target
    const name = target.name
    const value = target.value
    const newRoute = this.context.newRoute
    const setRoute = this.context.setRoute

    this.setState({
      newRoute: {
        ...this.state.newRoute,
        [name]: value,
      },
    })
    setRoute({
      ...newRoute,
      [name]: value,
    })
  }

  //Listen exitStops
  changeExitStops(exitStops) {
    this.setState({
      exitStops,
    })
  }

  //Render route of exitStops
  renderRouteMap() {
    if (this.state.exitStops.length > 1) {
      if (this.state.maps) {
        const directionsRenderer = new this.state.maps.DirectionsRenderer({
          draggable: true,
        })
        const directionsService = new this.state.maps.DirectionsService()
        this.setState({
          directionsRenderer: directionsRenderer,
          directionsService: directionsService,
        })
        this.calculateAndDisplayRoute(directionsRenderer, directionsService)
      }
    }
  }

  calculateAndDisplayRoute(directionsRenderer, directionsService) {
    if (this.state.directionsRenderer) {
      this.state.directionsRenderer.setMap(null)
    }
    if (this.state.maps) {
      const wayPoints = this.state.exitStops.map((point, index) => {
        if (index !== 0 && index !== this.state.exitStops.length - 1) {
          let LatLng = {
            location: {
              lat: point.center.coordinates[0],
              lng: point.center.coordinates[1],
            },
          }
          return LatLng
        }
      })
      const newWayPoints = wayPoints.filter((point) => {
        return point !== undefined
      })
      directionsRenderer.setMap(this.state.map)
      directionsService.route(
        {
          origin: {
            lat: this.state.exitStops[0].center.coordinates[0],
            lng: this.state.exitStops[0].center.coordinates[1],
          },
          destination: {
            lat: this.state.exitStops[this.state.exitStops.length - 1].center
              .coordinates[0],
            lng: this.state.exitStops[this.state.exitStops.length - 1].center
              .coordinates[1],
          },
          waypoints: newWayPoints ? newWayPoints : [],
          // Note that Javascript allows us to access the constant
          // using square brackets and a string value as its
          // "property."
          travelMode: this.state.maps.TravelMode.DRIVING,
          avoidTolls: true,
        },
        (response, status) => {
          if (status === 'OK') {
            directionsRenderer.setDirections(response)
            this.setState({
              displayRoutes: response,
            })
          } else {
            console.log(response)
            console.log(status)
          }
        },
      )
    }
  }

  selectNav(nav) {
    this.setState({
      selectedNav: nav,
    })
  }

  createRoute() {
    const { newRoute } = this.state
    if (newRoute.name && newRoute.number) {
      let json_stops = []
      for (var i = 0; i < this.state.exitStops.length; i++) {
        json_stops.push({ stop_id: this.state.exitStops[i].id, sequence: i })
      }

      const newRoute = this.context.newRoute
      const setRoute = this.context.setRoute
      setRoute({
        ...newRoute,
        stop_ids: json_stops,
      })
      this.props.createRoute(newRoute)
    } else {
      console.log('falta informacion')
    }
  }

  editRoute() {
    let json_stops = []
    for (var i = 0; i < this.state.exitStops.length; i++) {
      json_stops.push({ stop_id: this.state.exitStops[i].id, sequence: i })
    }

    const newRoute = this.context.newRoute
    const setRoute = this.context.setRoute
    setRoute({
      ...newRoute,
      stop_ids: json_stops,
    })

    this.props.editRoute(newRoute)
  }

  render() {
    return (
      <div className="rutas-modal">
        <div className="rutas-modal-add row">
          <div className="rutas-modal-margin">
            <div className="row rutas-modal-add-header">
              <div className="header-col2 row">
                <input
                  className="input-header numero-ruta"
                  placeholder="NUMERO"
                  onChange={this.handleChange}
                  value={this.state.newRoute.number}
                  name="number"
                />
                <input
                  type="text"
                  placeholder="Nombre de la ruta"
                  className="input-header nombre-ruta"
                  onChange={this.handleChange}
                  value={this.state.newRoute.name}
                  name="name"
                />
              </div>
              <div className="header-col2 row">
                <button className="btn5" onClick={this.props.handleModal}>
                  Cancelar
                </button>
                <button
                  to={{
                    path: '/rutas/newRoute',
                    state: { data: this.state.exitStops },
                  }}
                  className="btn3"
                  onClick={
                    this.props.create || this.props.copy
                      ? this.createRoute
                      : this.editRoute
                  }
                >
                  {this.props.create
                    ? 'Crear'
                    : this.props.copy
                    ? 'Copiar'
                    : 'Actualizar'}
                </button>
              </div>
            </div>
            <div className="ruta-modal-navigation row">
              <div
                className={`navigation-item ${
                  this.state.selectedNav === 'CONFIG' ? 'selected' : ''
                }`}
                onClick={() => this.selectNav('CONFIG')}
              >
                CONFIGURACION
              </div>
              <div
                className={`navigation-item ${
                  this.state.selectedNav === 'PARADAS' ? 'selected' : ''
                }`}
                onClick={() => this.selectNav('PARADAS')}
              >
                PARADAS
              </div>
              <div
                className={`navigation-item ${
                  this.state.selectedNav === 'UNIDADES' ? 'selected' : ''
                }`}
                onClick={() => this.selectNav('UNIDADES')}
              >
                UNIDADES
              </div>
              <div
                className={`navigation-item ${
                  this.state.selectedNav === 'HORARIOS' ? 'selected' : ''
                }`}
                onClick={() =>
                  this.state.exitStops.length > 0
                    ? this.selectNav('HORARIOS')
                    : null
                }
              >
                HORARIOS
              </div>
            </div>
            <div className="rutas-modal-add-content row">
              {this.state.selectedNav === 'PARADAS' ? (
                <AddStops
                  unitsTypes={this.props.ImgsReducer.imgs_types}
                  entryStops={this.state.entryStops}
                  exitStops={this.state.exitStops}
                  selectUnitType={this.selectUnitType}
                  newRoute={this.state.newRoute} // Por cambiar al context
                  changeExitStops={this.changeExitStops}
                  maps={this.state.maps}
                />
              ) : this.state.selectedNav === 'HORARIOS' ? (
                <Schedules
                  exitStops={this.state.exitStops}
                  selectedRoute={this.props.selectedRoute}
                  directionsService={this.state.directionsService}
                  maps={this.state.maps}
                />
              ) : this.state.selectedNav === 'UNIDADES' ? (
                <SelectUnits newRoute={this.state.newRoute} />
              ) : this.state.selectedNav === 'CONFIG' ? (
                <RouteConfig
                  passegersR={
                    this.props.selectedRoute
                      ? this.props.selectedRoute.available_passengers_ids
                      : []
                  }
                />
              ) : null}
            </div>
          </div>
          <div className="Map-content">
            <Map
              key="google_mapComponent"
              defaultCenter={this.props.center}
              defaultZoom={this.props.zoom}
              bootstrapURLKeys={{
                key: 'AIzaSyBwfkPvvIzAUCw0VFGSr8fjhAsYRg5_E4I',
                libraries: ['places'],
              }}
              options={this.createMapOptions}
              yesIWantToUseGoogleMapApiInternals={true}
              onChange={this.handleMapChange}
              onGoogleApiLoaded={({ map, maps }) => this.onMapLoaded(map, maps)}
            ></Map>
          </div>
        </div>
        <div className="rutas-modal-back"></div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalRutas)
