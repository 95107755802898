import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import "./Resumen.scss";
//--------------------------------------------------
import Paradas from "./components/Paradas/Paradas";
import Rutas from "./components/Rutas/Rutas";
import Rutinas from "./components/Rutinas/Rutinas";
import Seguimiento from "./components/Seguimiento/Seguimiento";
import { fetchMetricData } from "../../redux/actions/metricsActions";

const mapStateToProps = (state) => {
  return {
    reducer_metric_data: state.reducer_metric_data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchMetricData: () => {
    dispatch(fetchMetricData());
  },
});

class Resumen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      metric_data: {
        routes: {
          data: [],
          total: 0,
        },
        routines: {
          totalRoutinesCount: 0,
          activeNowRoutinesCount: 0,
          scheduledRoutinesCount: 0,
          completedRoutinesCount: 0,
          activeNowRoutines: [],
          scheduledRoutines: [],
          completedRoutines: [],
          totalRoutines: [],
        },
      },
    };
  }

  componentDidMount() {
    this.props.selectItemNav("Resumen");
    this.props.fetchMetricData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reducer_metric_data !== this.props.reducer_metric_data) {
      if (this.props.reducer_metric_data.metric_data) {
        this.setState({
          metric_data: this.props.reducer_metric_data.metric_data,
        });
      }
    }
  }
  
  render() {
    return (
      <div className="margin-50px Resumen">
        <Rutas />
        <div className="row resumen-rutinas-paradas">
          <Rutinas routines={this.state.metric_data.routines} />
          <Paradas stopsSize={this.props.stops_size} />
        </div>
        <Seguimiento />
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Resumen)
);
