import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  fetchPassengers,
  savePassegersIds,
} from "../../../../../../redux/actions/passengersActions";
import Select from "react-select";
import "./RouteConfig.scss";
import useRoute from "../../../../../../state/hooks/useRoute";
import MotionSelect from "../../../../../../componets/Motion/MotionSelect/MotionSelect";
import MotionDateTime from "../../../../../../componets/Motion/MotionDateTime/MotionDateTime";
import SelectPassengers from "../../../../../../componets/SelectPassengers/SelectPassengers";
import MotionSelectTest from "../../../../../../componets/Motion/MotionSelect/MotionSelectTest";
import { getGroups } from "../../../../../../redux/actions/groupsActions";

const RouteConfig = (props) => {
  const { newRoute, setRoute } = useRoute();
  const [passengers, setPassengers] = useState([]);
  const [options, setOptions] = useState([]);
  const [passengersR, setPassengersR] = useState([]);
  const [orderOptions] = useState([
    { value: "strict", label: "Exacto" },
    { value: "omit", label: "Omisiones posibles" },
    { value: "dynamic", label: "Arbitrario" },
  ]);
  const [scheduleOptions] = useState([
    { value: "activation", label: "Relativa a la activacion" },
    { value: "day", label: "Relativa al dia" },
    { value: "absolute", label: "Abosuluta" },
  ]);
  const [dayOptions] = useState([
    { value: "weekly", label: "Semanal" },
    { value: "monthly", label: "Mensual" },
  ]);
  const [activeOptions] = useState([
    { value: "inbound", label: "Dentro de la geocerca" },
    { value: "outbound", label: "Fuera de la geocerca" },
  ]);
  const [groups, setGroups] = useState([]);
  const [optionsGroups, setOptionsGroups] = useState([]);
  const [selectGroupValue, setSelectGroupValue] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    // Run with the first render.
    //fetch passegers
    props.fetchPassengers();
    //fetch groups
    props.getGroups();

    //If is modal Edit or copy already there data
    if (props.passegersR) {
      setPassengersR(
        props.passegersR.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    }

    // returned function will be called on component unmount
    return () => {
      dispatch(savePassegersIds([]));
    };
  }, []);

  useEffect(() => {
    var values =
      passengersR != null ? passengersR.map((item) => item.value) : null;
    dispatch(savePassegersIds(values));
    setRoute({
      ...newRoute,
      passengers: values,
    });
  }, [passengersR]);

  useEffect(() => {
    //save passegers of store in the state
    setPassengers(props.passengersReducer.passengers);
  }, [props.passengersReducer]);

  useEffect(() => {
    //save groups of store in the state
    var newGroupsOptions = props.GroupsReducer.groups.map((group) => {
      return {
        value: group.id,
        label: group.name,
      };
    });
    console.log(newGroupsOptions);
    setOptionsGroups(newGroupsOptions);
  }, [props.GroupsReducer]);

  useEffect(() => {
    //Set passegers in options
    setOptions(
      passengers.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    );
  }, [passengers]);

  const handleChangePassanger = (value) => {
    setPassengersR(value);
  };

  const handleChangeRoute = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setRoute({
      ...newRoute,
      [name]: value,
    });
  };

  const handleChangescheduleRoute = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setRoute({
      ...newRoute,
      schedule: { ...newRoute.schedule, [name]: value },
    });
  };

  const handleCheckRoutineDel = () => {
    setRoute({
      ...newRoute,
      del_routines_completed: !newRoute.del_routines_completed,
    });
  };

  const handleCheckRoutinePastePoint = () => {
    setRoute({
      ...newRoute,
      paste_last_point: !newRoute.paste_last_point,
    });
  };

  const selectValue = (group) => {
    console.log(group);
    setSelectGroupValue(group);
  };

  return (
    <div className="RouteConfig">
      <form className="ModalRutas-paradas-form column">
        <div className="config-pane">
          <div className="config-col">
            <div className="config-item">
              <MotionSelect
                label="Orden de paradas"
                name="checkpoints_order"
                onChange={handleChangeRoute}
                value={newRoute.checkpoints_order}
                options={orderOptions}
              />
            </div>
            <div className="config-item" style={{ height: "39px" }}>
              <label style={{ marginBottom: "10px" }}>Seleccionar grupo</label>
              <MotionSelectTest
                placeholder="Seleccionar grupo"
                options={optionsGroups}
                valueSelected={selectGroupValue}
                selectValue={selectValue}
              />
            </div>
            <div className="config-item">
              <MotionSelect
                label="Selecione el tipo de rutina"
                name="schedule_type"
                onChange={handleChangescheduleRoute}
                value={newRoute.schedule.schedule_type}
                options={scheduleOptions}
              />
            </div>
            <div className="config-item">
              <div className="config-item-c">
                <div className="header-item-select-text">
                  Configuracion de rutinas
                </div>
                <div className="config-checks">
                  <input
                    type="checkbox"
                    className="m-checkbox__input"
                    onChange={handleCheckRoutineDel}
                    name="del_routines_completed"
                    value={newRoute.del_routines_completed}
                    checked={newRoute.del_routines_completed ? true : false}
                  />
                  <div className="m-label">
                    Eliminar rutinas completadas automaticamente
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="config-col">
            <div className="config-item" style={{ maxHeight: "200px" }}>
              <SelectPassengers
                label="Pasajeros"
                name="passengers"
                onChange={handleChangePassanger}
                value={passengersR}
                options={options}
              />
            </div>
            <div className="config-item ">
              {newRoute.schedule.schedule_type === "day" ? (
                <MotionSelect
                  label="Tipo de relatividad"
                  name="relative_day_type"
                  onChange={handleChangescheduleRoute}
                  value={newRoute.schedule.relative_day_type}
                  options={dayOptions}
                />
              ) : newRoute.schedule.schedule_type === "activation" ? (
                <MotionSelect
                  label="Tipo de activacion"
                  name="activator_geofence_type"
                  onChange={handleChangescheduleRoute}
                  value={newRoute.schedule.activator_geofence_type}
                  options={activeOptions}
                />
              ) : (
                <MotionDateTime
                  label="Seleccione fecha y hora"
                  name="absolute_arrival"
                  value={newRoute.schedule.absolute_arrival}
                  onChange={handleChangescheduleRoute}
                  hour
                />
              )}
            </div>
            <div className="config-item end">
              <div className="config-item-c end">
                <div className="config-checks">
                  <input
                    type="checkbox"
                    className="m-checkbox__input"
                    onChange={handleCheckRoutinePastePoint}
                    name="paste_last_point"
                    value={newRoute.paste_last_point}
                    checked={newRoute.paste_last_point ? true : false}
                  />
                  <div className="m-label">
                    Conectar ultimo punto con origen
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  passengersReducer: state.PassengerReducer,
  GroupsReducer: state.GroupsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPassengers: () => {
    dispatch(fetchPassengers());
  },
  getGroups: () => {
    dispatch(getGroups());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RouteConfig);
