export const RoutineModel = {
  id: "",
  name: "",
  route: "",
  date: "",
  state: "",
  unit: {
    id: "",
    name: "",
    unit_type: "",
  },
  schedules: [],
  available_passengers_ids: [],
  available_units: [],
};

export const ScheduleLineModel = {
  arrival: 0,
  stop_id: 0,
  id: 0,
  stop_name: "",
  stop_address: "",
  center: "",
  passengers: [],
};

export const RoutineSingleModel = {
  available_passengers_ids: [],
  available_units: [],
  date: "",
  id: "",
  name: "",
  route: "",
  schedules: [],
  state: "new",
  unit: {},
  group: {},
};
