import React, { Component } from "react";
import "./CardPasajeros.scss";

import { deleteRoute } from "../../../../redux/actions/routesActions";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Avatar from "../../../../assets/avatar.png";
import { ReactComponent as TempoIco } from "../../../../assets/motion_icon-temporizador.svg";
import PassangerIco from "../../../../assets/motion_icon-pasajeros.svg";
import { ReactComponent as EditIco } from "../../../../assets/motion_icon-edit.svg";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  deleteRoute: (id) => {
    dispatch(deleteRoute(id));
  },
});

class CardPasajeros extends Component {
  constructor() {
    super();
    this.state = {
      check: false,
    };
    this.handleCheck = this.handleCheck.bind(this);
    this.onDeleteRoute = this.onDeleteRoute.bind(this);
  }
  handleCheck() {
    this.setState({
      check: !this.state.check,
    });
  }

  onDeleteRoute() {
    this.props.deleteRoute(this.props.id);
  }

  render() {
    return (
      <li className="CardPasajeros">
        <div className="CardPasajeros-container column">
          <div className="column label-content">
            <div className="label">{this.props.name}</div>
          </div>
          <div className="pasajeros-container-card-background column">
            <div className="row" style={{ gap: "10px", alignItems: "center" }}>
              <img
                src={`data:image/jpeg;base64,${this.props.userImage}`}
                className="svg-avatar"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = PassangerIco;
                }}
              />
              <div className="column info-passanger">
                <div
                  className="rutas-container-card-text1 phone gray2 text-overflow"
                  style={{ cursor: "pointer" }}
                >
                  {this.props.phone ? this.props.phone : "+57 3214"}
                </div>
                <div
                  className="rutas-container-card-text1 email gray2 text-overflow"
                  style={{ cursor: "pointer" }}
                >
                  {this.props.email}
                </div>
              </div>
            </div>
            <div className="row" style={{ justifyContent: "space-between" }}>
              <div className="rutas-container-card-text4 gray3">
                <div className="row tempo-content">
                  <TempoIco className="ico-temp" />
                  <div>000</div>
                </div>
              </div>
              <div className="rutas-container-card-text4 update gray3">
                Actualizado: {this.props.updated}
              </div>
              <div className="rutas-container-card-text4 gray3">
                <EditIco className="edit-ico" />
              </div>
            </div>
          </div>
        </div>
      </li>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CardPasajeros)
);
