import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as IcoDown } from "./assets/desplazar-abajo.svg";

const MotionSelectContent = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Select = styled.div`
  height: 100%;
  width: 100%;
  padding: 0.55em 1.75em;
  border-radius: 1em;
  border: 0.15em solid #8c8c8c40;
  cursor: pointer;
  display: grid;
  grid-template-columns: 80% 20%;
  background-color: #fff;
`;

const List = styled.div`
  position: absolute;
  top: calc(100% + 0.5em);
  left: 0;
  display: ${(props) => (props.displayList ? "flex" : "none")};
  flex-direction: column;
  background-color: #fff;
  cursor: pointer;
  width: 100%;
  border-radius: 1em;
  border: 0.15em solid #8c8c8c40;
  box-shadow: 0px 0px 0.5em 0px rgba(0, 0, 0, 0.2);
  padding: 0.5em;
  z-index: 2;
`;

const Option = styled.div`
  padding: 0.5em;
  background-color: ${(props) => (props.selected ? "#f5f5f5" : "#fff")};
  &:hover {
    background-color: #f5f5f5;
  }
`;

const IcoDownStyled = styled(IcoDown)`
  width: 100%;
  max-width: 20px;
  height: inherit;
  display: flex;
  justify-self: self-end;
  & {
    path {
      fill: #8c8c8c;
    }
  }
`;

export default function MotionSelectTest(props) {
  const [options, setOptions] = useState([]);
  const [labelSelected, setLabelSelected] = useState("");
  const [displayList, setDisplayList] = useState(false);

  useEffect(() => {
    if (props.options) {
      setOptions(props.options);
    }
  }, [props.options]);

  useEffect(() => {
    if (props.valueSelected && props.options.length > 0) {
      let option = props.options.find(
        (option) => option.value === props.valueSelected
      );
      if (option) {
        setLabelSelected(option.label);
      }
    }
  }, [props.valueSelected]);

  const selectValue = (selectedOption) => {
    props.selectValue(selectedOption);
  };

  const onChangeDisplayList = () => {
    setDisplayList(!displayList);
  };

  const setValue = (value) => {
    selectValue(value);
    onChangeDisplayList();
  };

  return (
    <MotionSelectContent>
      <Select onClick={onChangeDisplayList}>
        <div>{labelSelected ? labelSelected : props.placeholder}</div>
        <IcoDownStyled />
      </Select>
      <List
        className="list"
        displayList={displayList ? displayList : undefined}
      >
        {props.searchElement ? props.searchElement : null}
        {options.map((option) => {
          return (
            <Option
              onClick={() => setValue(option.value)}
              selected={option.value === props.valueSelected}
              className={`option ${
                props.valueSelected === option.value ? "selected" : ""
              }`}
              key={option.value}
            >
              {option.label}
            </Option>
          );
        })}
        {props.extraElement ? props.extraElement : null}
      </List>
    </MotionSelectContent>
  );
}
