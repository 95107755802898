import React from "react";
import { connect } from "react-redux";
import { editRoutine } from "../../../../../../redux/actions/routinesActions";
import useRoutine from "../../../../../../state/hooks/useRoutine";
import { ReactComponent as IcoExit } from "../../../../../../assets/ico-exit.svg";
import { ReactComponent as IcoCheck } from "../../../../../../assets/check-ico.svg";
import "./Header.scss";

const mapStateToProps = (state) => {
  return {
    single_routine: state.RoutinesReducer.single_routine,
  };
};

const mapDispatchToProps = (dispatch) => ({
  editRoutine: (routine) => {
    dispatch(editRoutine(routine));
  },
});

function Header(props) {
  const { routine } = useRoutine();

  const updateRoutine = () => {
    props.editRoutine(routine);
  };

  return (
    <div className="head_rutinas row">
      <div className="title">{props.name}</div>
      <div className="actions row">
        <div className="ico-content" onClick={updateRoutine}>
          <IcoCheck className="ico check" />
        </div>
        <div className="ico-content" onClick={props.displayEditRoutine}>
          <IcoExit className="ico exit" />
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
