module.exports = {
  darkMode: [
    {
      featureType: "all",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#676767",
        },
      ],
    }, //No aplica
    {
      featureType: "all",
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#272727",
        },
        {
          lightness: 0,
        },
      ],
    }, //No aplica
    {
      featureType: "administrative",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#2B2B2B",
        },
      ],
    }, //No aplica
    {
      featureType: "administrative",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#144b53",
        },
        {
          lightness: 14,
        },
        {
          weight: 1.4,
        },
      ],
    }, //No aplica
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [
        {
          color: "#333333",
        },
      ],
    }, //No aplica
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#353535",
        },
        {
          lightness: 5,
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#2B2B2B",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#393939",
        },
        {
          lightness: 1,
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#2B2B2B",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#0b3d51",
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "geometry",
      stylers: [
        {
          color: "#292929",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [
        {
          color: "#313131",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#676767",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#272727",
        },
        {
          lightness: 0,
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#2B2B2B",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#2B2B2B",
        },
      ],
    },
  ],
  silverMode: [
    {
      elementType: "geometry",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#a8a8a8",
        },
      ],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#bdbdbd",
        },
      ],
    }, //No aplica aca
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#a8a8a8",
        },
      ],
    }, //Puntos de interes
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#8c8c8c",
        },
      ],
    },
    {
      featureType: "poi.business",
      stylers: [
        {
          color: "#a8a8a8",
        },
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "poi.business",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#c5c5c5",
        },
      ],
    },
    {
      featureType: "poi.business",
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#e2e2e2",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#8c8c8c",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          color: "#efefef",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#8c8c8c",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#c5c5c5",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#000000",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#a8a8a8",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#a8a8a8",
        },
        {
          weight: 1.4,
        },
      ],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [
        {
          color: "#c5c5c5",
        },
      ],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#40cee4",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
  ],
};
