import React from "react";
import styled from "styled-components";

const InputM = styled.input`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 36px;
  font-size: 14px;
  padding: 0.55em 1.75em;
`;

const MotionSdiv = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.75em;
`;

export default function MotionDateTime(props) {
  return (
    <MotionSdiv>
      <label htmlFor={props.id}>{props.label}</label>
      <InputM
        name={props.name}
        type={props.hour ? "datetime-local" : "date"}
        onChange={props.onChange}
        value={props.value}
        id={props.id}
      />
    </MotionSdiv>
  );
}
