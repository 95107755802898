import React, { useState, useEffect } from "react";
import styled from "styled-components";

const SelectInputPassenger = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const InputBox = styled.div`
  height: 29px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const List = styled.div`
  height: fit-content;
  width: 100%;
  max-height: 300px;
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: #fff;
  z-index: 1;
`;

const Option = styled.div`
  height: 30px;
  width: 100%;
  cursor: pointer;
  text-align: left;
  padding: 0.5rem;
  &:hover {
    background-color: #f5f5f5;
  }
`;

const InputSearch = styled.input`
  height: 39px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #8a8a8a;
`;

const ContainerSelecteds = styled.div`
  height: 100%;
  width: 100%;
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
`;

const OptionSelect = styled.div`
  width: 100px;
  height: 20px;
  display: grid;
  grid-template-columns: 1fr 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
`;

const TextOption = styled.div`
  font-size: 0.8rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0.5rem;
`;

const Title = styled.div`
  width: 100%;
  font-family: "Ubuntu", "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 1rem;
  color: #a8a8a8;
  line-height: normal;
  text-align: left;
`;

const DelSelection = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 0px 5px 5px 0px;
  &:hover {
    background-color: #d4409e;
  }
`;

function SelectPassengers(props) {
  const [optionsInit, setOptionsInit] = useState();
  const [optionsDisplay, setOptionsDisplay] = useState();
  const [optionsSelecteds, setOptionsSelecteds] = useState([]);
  const [displayList, setDisplayList] = useState(false);

  useEffect(() => {
    if (props.options) {
      setOptionsInit(props.options);
      clearInit();
    }
  }, [props.options]);

  useEffect(() => {
    if (props.value && props.value.length > 0) {
      setOptionsSelecteds(props.value);
      clearInit();
    }
  }, [props.value]);

  useEffect(() => {
    props.onChange(optionsSelecteds);
    clearInit();
  }, [optionsSelecteds]);

  const clearInit = () => {
    if (props.options && props.value) {
      var newListInit = props.options.filter((element) => {
        var match = props.value.find((element2) => {
          if (element.value === element2.value) {
            return true;
          } else {
            return false;
          }
        });
        if (!match) {
          return element;
        }
      });
      setOptionsInit(newListInit);
    }
  };

  const filter = (e) => {
    const { value } = e.target;
    const options = optionsInit.filter((option) =>
      option.label.toLowerCase().includes(value.toLowerCase())
    );
    setOptionsDisplay(options);
  };

  const selectOption = (value) => {
    const optionFound = optionsInit.find((option) => option.value === value);
    if (optionFound) {
      setOptionsInit(optionsInit.filter((option) => option.value !== value));
      setOptionsSelecteds([...optionsSelecteds, optionFound]);
    }
  };

  const removeOption = (value) => {
    const optionFound = optionsSelecteds.find(
      (option) => option.value !== value
    );
    setOptionsInit([...optionsInit, optionFound]);
    setOptionsSelecteds(
      optionsSelecteds.filter((option) => option.value !== value)
    );
  };

  return (
    <SelectInputPassenger
      onFocus={() => setDisplayList(true)}
      onMouseLeave={() => setDisplayList(false)}
    >
      <Title>{props.label}</Title>
      <InputBox>
        <InputSearch type="text" onChange={filter}></InputSearch>
        {displayList ? (
          <List>
            {optionsDisplay
              ? optionsDisplay.map((option) => (
                  <Option
                    key={option.value}
                    value={option.value}
                    onClick={() => selectOption(option.value)}
                  >
                    {option.label}
                  </Option>
                ))
              : optionsInit.map((option) => (
                  <Option
                    key={option.value}
                    value={option.value}
                    onClick={() => selectOption(option.value)}
                  >
                    {option.label}
                  </Option>
                ))}
          </List>
        ) : null}
      </InputBox>
      <ContainerSelecteds>
        {optionsSelecteds.map((option) => (
          <OptionSelect key={option.value}>
            <TextOption>{option.label}</TextOption>
            <DelSelection
              onClick={() => removeOption(option.value)}
            ></DelSelection>
          </OptionSelect>
        ))}
      </ContainerSelecteds>
    </SelectInputPassenger>
  );
}

export default SelectPassengers;
