import axios from "axios";
import {
  CREATE_PASSENGERS,
  CREATE_PASSENGERS_FAILED,
  CREATE_PASSENGERS_SUCCESS,
  GET_PASSENGERS,
  GET_PASSENGERS_FAILED,
  GET_PASSENGERS_SUCCESS,
  GET_PASSENGERS_TEAMPLTE,
  GET_PASSENGERS_TEAMPLTE_FAILED,
  GET_PASSENGERS_TEAMPLTE_SUCCESS,
  SAVE_PASSEGERS_IDS,
} from "../types/passengers.types";
import { logoutUser } from "./userActions";

export const fetchPassengers = () => async (dispatch) => {
  var config = {
    method: "GET",
    url: "/api/miroute/passengers",
    headers: {},
  };
  dispatch({
    type: GET_PASSENGERS,
    payload: "Loading",
  });
  await axios(config)
    .then(function (response) {
      dispatch({
        type: GET_PASSENGERS_SUCCESS,
        payload: response.data.result,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_PASSENGERS_FAILED,
        payload: error,
      });
      logoutUser();
      console.log(error);
    });
};

export function createPassengers(data) {
  return (dispatch) => {
    var body = JSON.stringify({
      jsonrpc: "2.0",
      params: {
        passengers: data,
      },
    });
    var config = {
      method: "POST",
      url: "/api/miroute/passegers/new",
      headers: {
        "Content-Type": "application/json",
      },
      data: body,
    };
    dispatch({ type: CREATE_PASSENGERS, payload: "Loading" });
    axios(config)
      .then(function (response) {
        dispatch({ type: CREATE_PASSENGERS_SUCCESS, payload: response.data });
        fetchPassengers();
      })
      .catch(function (error) {
        dispatch({ type: CREATE_PASSENGERS_FAILED, payload: error });
        console.log(error);
      });
  };
}

export function savePassegersIds(data) {
  return (dispatch) => {
    dispatch({ type: SAVE_PASSEGERS_IDS, payload: data });
  };
}

export const getPassengersTemplate = () => async (dispatch) => {
  var config = {
    method: "GET",
    url: "/api/miroute/passengers/template/xlsx",
    headers: {},
    responseType: "blob",
  };
  axios(config).then((resp) => {
    var a = document.createElement("a");
    a.href = URL.createObjectURL(resp.data);
    a.download = "Pasajeros template";
    a.click();
  });
};
