import React, { Component } from "react";
import "./Notification.scss";

import { ReactComponent as IcoRutas } from "../../assets/rutas-ico.svg";
import { ReactComponent as IcoParadas } from "../../assets/paradas-ico.svg";
import { ReactComponent as IcoCiclos } from "../../assets/Ciclos-ico.svg";
import { ReactComponent as IcoInformes } from "../../assets/informes-ico.svg";
import { ReactComponent as IcoRutinas } from "../../assets/rutinas-ico.svg";

class Notification extends Component {
  constructor() {
    super();
    this.state = {
      notification: [],
    };
  }

  componentDidMount() {
    this.setState({
      notification: [
        {
          id: "1",
          nombre: "Ruta finalizada",
          date: "15/04/2021",
          houre: "18:00",
          type: "Ruta",
          viewed: false,
        },
        {
          id: "2",
          nombre: "Rutina sin asignar",
          date: "13/05/2021",
          houre: "13:00",
          type: "Rutina",
          viewed: true,
        },
        {
          id: "3",
          nombre: "Ruta finalizada",
          date: "15/06/2021",
          houre: "15:00",
          type: "Ruta",
          viewed: true,
        },
      ],
    });
    setTimeout(() => {
      this.setState({
        active: true,
      });
    }, 100);
  }

  componentWillUnmount() {
    const notifications = this.state.notification;
    const newNotification = notifications.map((item) => ({
      id: item.id,
      nombre: item.nombre,
      date: item.date,
      houre: item.houre,
      type: item.type,
      viewed: true,
    }));
    this.setState({
      notification: newNotification,
    });
  }

  renderIcoForType(item) {
    if (item.type === "Ruta") {
      return (
        <>
          {item.viewed ? (
            <IcoRutas className="notification-ico1" />
          ) : (
            <IcoRutas className="notification-ico2" />
          )}
        </>
      );
    } else if (item.type === "Rutina") {
      return (
        <>
          {item.viewed ? (
            <IcoRutinas className="notification-ico1" />
          ) : (
            <IcoRutinas className="notification-ico2" />
          )}
        </>
      );
    } else if (item.type === "Paradas") {
      return (
        <>
          {item.viewed ? (
            <IcoParadas className="notification-ico1" />
          ) : (
            <IcoParadas className="notification-ico2" />
          )}
        </>
      );
    } else if (item.type === "Ciclos") {
      return (
        <>
          {item.viewed ? (
            <IcoCiclos className="notification-ico1" />
          ) : (
            <IcoCiclos className="notification-ico2" />
          )}
        </>
      );
    } else {
      return (
        <>
          {item.viewed ? (
            <IcoInformes className="notification-ico1" />
          ) : (
            <IcoInformes className="notification-ico2" />
          )}
        </>
      );
    }
  }

  render() {
    return (
      <div
        className={`${this.state.active ? "active" : ""} ${
          this.props.DesactivateNotification ? "desactivate" : ""
        }  Notification column`}
      >
        {this.state.notification.map((item) => {
          return (
            <div className="row notification-item" key={item.id}>
              <div className="column notification-item-ico">
                {this.renderIcoForType(item)}
              </div>
              <div className="column notification-item-description">
                <div className="blue2 Notification-title">{item.nombre}</div>
                <div className="red2 Notification-text">{item.date}</div>
                <div className="gray2 Notification-text">{item.houre}</div>
              </div>
            </div>
          );
        })}
        <div></div>
      </div>
    );
  }
}

export default Notification;
