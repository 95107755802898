import axios from "axios";

import {
  GET_STOPS_LIST,
  GET_STOPS_LIST_SUCCCESS,
  GET_STOPS_LIST_FAILED,
  CREATE_STOP,
  CREATE_STOP_SUCCESS,
  CREATE_STOP_FALED,
  END_CREATE_STOP,
  DELETE_STOP,
  DELETE_STOP_SUCCESS,
  DELETE_STOP_FALED,
} from "../types/stops.types";
import { logoutUser } from "./userActions";

export const fetchStops = (params) => async (dispatch) => {
  var config = {
    method: "GET",
    url: "/api/miroute/stops",
    headers: {},
    params: params,
  };
  dispatch({
    type: GET_STOPS_LIST,
    payload: "Loading",
  });
  await axios(config)
    .then(function (response) {
      console.log(response);
      const newResult = response.data.result.map((item) => {
        let result = {
          ...item,
          center: JSON.parse(item.center),
          polygon: JSON.parse(item.polygon),
          id_d: `item-${item.id}`,
        };
        return result;
      });
      dispatch({
        type: GET_STOPS_LIST_SUCCCESS,
        payload: newResult,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_STOPS_LIST_FAILED,
        payload: error,
      });
      // logoutUser();
      console.log("osea aca no entro o si?");
    });
};

export function createStop(newStop) {
  return async (dispatch) => {
    var data = JSON.stringify({
      jsonrpc: "2.0",
      params: newStop,
    });
    console.log(data);
    var config = {
      method: "POST",
      url: "/api/miroute/stops/new",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    dispatch({ type: CREATE_STOP, payload: "Loading" });
    await axios(config)
      .then(function (response) {
        dispatch({ type: CREATE_STOP_SUCCESS, payload: response.data });
        fetchStops();
      })
      .catch(function (error) {
        dispatch({ type: CREATE_STOP_FALED, payload: error });
        console.log(error);
      });
  };
}

export function deleteStop(id) {
  return async (dispatch) => {
    var config = {
      method: "GET",
      url: `/api/miroute/stops/delete/id=${id}`,
    };
    dispatch({ type: DELETE_STOP, payload: "Loading" });
    await axios(config)
      .then(function (response) {
        dispatch({ type: DELETE_STOP_SUCCESS, payload: response.data });
      })
      .catch(function (error) {
        dispatch({ type: DELETE_STOP_FALED, payload: error });
      });
  };
}

export function endCreateStop() {
  return (dispatch) => {
    dispatch({ type: END_CREATE_STOP, payload: "Salir" });
  };
}
