import {
  BLOCK_ROUTINE,
  BLOCK_ROUTINE_FAILED,
  BLOCK_ROUTINE_SUCCESS,
  CREATE_ROUTINE,
  CREATE_ROUTINE_END,
  CREATE_ROUTINE_FAILED,
  CREATE_ROUTINE_SUCCESS,
  DELETE_ROUTINE,
  DELETE_ROUTINE_END,
  DELETE_ROUTINE_FAILED,
  DELETE_ROUTINE_SUCCESS,
  EDIT_ROUTINE,
  EDIT_ROUTINE_END,
  EDIT_ROUTINE_FAILED,
  EDIT_ROUTINE_SUCCESS,
  EDIT_TIMELINE,
  EDIT_TIMELINE_FAILED,
  EDIT_TIMELINE_SUCCESS,
  GET_DAYS,
  GET_DAYSWEEK,
  GET_DAYSWEEK_FAILED,
  GET_DAYSWEEK_SUCCESS,
  GET_DAYS_FAILED,
  GET_DAYS_SUCCESS,
  GET_MONTHS,
  GET_MONTHS_FAILED,
  GET_MONTHS_SUCCESS,
  GET_ROUTINE,
  GET_ROUTINE_FAILED,
  GET_ROUTINE_SUCCESS,
  GET_SINGLE_ROUTINE,
  GET_SINGLE_ROUTINE_FAILED,
  GET_SINGLE_ROUTINE_SUCCESS,
} from "../types/routines.types";

const initialState = {
  isLoading: true,
  errMess: null,
  routines: [],
  days: [],
  daysWeek: [],
  months: [],
  single_routine: null,
};

const RoutinesReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_ROUTINE:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ROUTINE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        routines: action.payload,
      };
    case GET_ROUTINE_FAILED:
      return {
        ...state,
        isLoading: false,
        errMess: action.payload,
      };

    case GET_SINGLE_ROUTINE:
      return {
        ...state,
        isLoadingRoutine: true,
      };
    case GET_SINGLE_ROUTINE_SUCCESS:
      return {
        ...state,
        isLoadingRoutine: false,
        single_routine: action.payload,
      };
    case GET_SINGLE_ROUTINE_FAILED:
      return {
        ...state,
        isLoadingRoutine: false,
        errMess: action.payload,
      };

    case GET_DAYS:
      return {
        ...state,
      };
    case GET_DAYS_SUCCESS:
      return {
        ...state,
        days: action.payload,
      };
    case GET_DAYS_FAILED:
      return {
        ...state,
      };

    case GET_DAYSWEEK:
      return {
        ...state,
      };
    case GET_DAYSWEEK_SUCCESS:
      return {
        ...state,
        daysWeek: action.payload,
      };
    case GET_DAYSWEEK_FAILED:
      return {
        ...state,
      };

    case GET_MONTHS:
      return {
        ...state,
      };
    case GET_MONTHS_SUCCESS:
      return {
        ...state,
        months: action.payload,
      };
    case GET_MONTHS_FAILED:
      return {
        ...state,
      };

    case CREATE_ROUTINE:
      return {
        ...state,
        isLoadingCreate: true,
        created: false,
        errorCreated: false,
      };

    case CREATE_ROUTINE_SUCCESS:
      return {
        ...state,
        isLoadingCreate: false,
        created: true,
        errorCreated: false,
      };

    case CREATE_ROUTINE_FAILED:
      return {
        ...state,
        isLoadingCreate: false,
        created: false,
        errorCreated: true,
      };

    case CREATE_ROUTINE_END:
      return {
        ...state,
        isLoadingCreate: false,
        created: false,
        errorCreated: false,
      };

    case EDIT_ROUTINE:
      return {
        ...state,
        isLoadingEdit: true,
        edited: false,
        errorEdited: false,
      };

    case EDIT_ROUTINE_SUCCESS:
      return {
        ...state,
        isLoadingEdit: false,
        edited: true,
        errorEdited: false,
      };

    case EDIT_ROUTINE_FAILED:
      return {
        ...state,
        isLoadingEdit: false,
        edited: false,
        errorEdited: true,
      };

    case EDIT_ROUTINE_END:
      return {
        ...state,
        isLoadingEdit: false,
        edited: false,
        errorEdited: false,
      };

    case EDIT_TIMELINE:
      return {
        ...state,
      };

    case EDIT_TIMELINE_SUCCESS:
      return {
        ...state,
      };

    case EDIT_TIMELINE_FAILED:
      return {
        ...state,
      };

    case DELETE_ROUTINE:
      return {
        ...state,
        isLoadingDelete: true,
        delete: false,
        errorDelete: false,
      };

    case DELETE_ROUTINE_SUCCESS:
      return {
        ...state,
        isLoadingDelete: false,
        delete: true,
        errorDelete: false,
      };

    case DELETE_ROUTINE_FAILED:
      return {
        ...state,
        isLoadingDelete: false,
        delete: false,
        errorDelete: true,
      };

    case DELETE_ROUTINE_END:
      return {
        ...state,
        isLoadingDelete: false,
        delete: false,
        errorDelete: false,
      };

    case BLOCK_ROUTINE:
      return {
        ...state,
        isLoadingBlock: true,
      };

    case BLOCK_ROUTINE_SUCCESS:
      return {
        ...state,
        isLoadingBlock: false,
      };

    case BLOCK_ROUTINE_FAILED:
      return {
        ...state,
        isLoadingBlock: false,
      };

    default:
      return state;
  }
};

export default RoutinesReducer;
