import React, { Component } from "react";
import "./CardRutas.scss";

import { ReactComponent as DeleteIco } from "../../../../assets/motion_icon-detenido.svg";
import { ReactComponent as CopyIco } from "../../../../assets/motion_icon-widgets.svg";
import { deleteRoute } from "../../../../redux/actions/routesActions";
import { withRouter } from "react-router";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  deleteRoute: (id) => {
    dispatch(deleteRoute(id));
  },
});

class CardRutas extends Component {
  constructor() {
    super();
    this.state = {
      check: false,
    };
    this.handleCheck = this.handleCheck.bind(this);
    this.onDeleteRoute = this.onDeleteRoute.bind(this);
  }
  handleCheck() {
    this.setState({
      check: !this.state.check,
    });
  }

  onDeleteRoute() {
    this.props.deleteRoute(this.props.id);
  }

  render() {
    return (
      <li className="CardRutas">
        <div className="CardRutas-container row">
          <div
            className="column label-content"
            onClick={this.props.EditRoute}
            style={{ cursor: "pointer" }}
          >
            <div className="label">{this.props.number}</div>
          </div>
          <div className="rutas-container-card-background column">
            <div
              className="row"
              style={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <div
                className="rutas-container-card-text1 gray1 text-overflow"
                onClick={this.props.EditRoute}
                style={{ cursor: "pointer" }}
              >
                {this.props.name}
              </div>
              <div
                className="row CardRutas-content-icons"
                style={{ gridGap: "10px" }}
              >
                <DeleteIco
                  className="rutas-container-card-ico delete"
                  onClick={this.onDeleteRoute}
                />
                <CopyIco
                  className="rutas-container-card-ico copy"
                  onClick={this.props.CopyRoute}
                />
                <div
                  className={`rutinas-container-card-row-check ${
                    this.state.check ? "activate" : ""
                  } row`}
                  onClick={this.handleCheck}
                >
                  <div className="rutinas-container-card-row-check-background row">
                    <div className="rutinas-container-card-row-check-front"></div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="column "
              style={{ gridGap: "15px", cursor: "pointer" }}
              onClick={this.props.EditRoute}
            >
              <div className="row" style={{ gap: "10px" }}>
                <div className="gray1 rutas-container-card-text2">INICIO</div>
                <div className="gray2 rutas-container-card-text3 text-overflow">
                  {this.props.origin}
                </div>
              </div>
              <div className="row" style={{ gap: "10px" }}>
                <div className="gray1 rutas-container-card-text2">DESTINO</div>
                <div className="gray2 rutas-container-card-text3 text-overflow">
                  {this.props.destiny}
                </div>
              </div>
            </div>
            <div className="row" style={{ justifyContent: "space-between" }}>
              <div className="rutas-container-card-text4 gray3">
                <div>Horarios: 1</div>
              </div>
              <div className="rutas-container-card-text4 gray3">
                Actualizado: 17.08.2020
              </div>
            </div>
          </div>
        </div>
      </li>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CardRutas)
);
