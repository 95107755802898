import {
  GET_IMGTYPES,
  GET_IMGTYPES_FAILED,
  GET_IMGTYPES_SUCCESS,
} from "../types/imgTypes";

const initialState = {
  isLoading: true,
  errMess: null,
  imgs_types: [],
};

const ImgsReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_IMGTYPES:
      return { isLoading: true };
    case GET_IMGTYPES_SUCCESS:
      return {
        isLoading: false,
        imgs_types: action.payload,
      };
    case GET_IMGTYPES_FAILED:
      return {
        isLoading: false,
        errMess: action.payload,
      };
    default:
      return state;
  }
};

export default ImgsReducer;
