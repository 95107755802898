import React, { Component } from "react";
import Map from "google-map-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "./Paradas.scss";
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from "xlsx";
import mapStyle from "../../mapStyle/mapStyle";
import CardList from "./components/CardList/CardList";
import ModalCreated from "../../componets/ModalCreated/ModalCreated";
import ModalGroupCreate from "../../componets/ModalGroupCreate/ModalGroupCreate";
import { ReactComponent as CargarIco } from "../../assets/CargarIco.svg";
import { ReactComponent as MasIco } from "../../assets/Mas.svg";
import { ReactComponent as LevelUp } from "../../assets/desplazar-arriba.svg";
import { ReactComponent as LevelDown } from "../../assets/desplazar-abajo.svg";
import { withRouter } from "react-router";
import Marker from "../../componets/Marker/Marker";
import Select from "react-select";
import { fetchImgs } from "../../redux/actions/imgActions";
import Excel from "./components/Excel/Excel";
import MotionSelectTest from "../../componets/Motion/MotionSelect/MotionSelectTest";
import {
  fetchStops,
  createStop,
  endCreateStop,
} from "../../redux/actions/stopsActions";
import { getGroups } from "../../redux/actions/groupsActions";
import StopForm from "./components/StopForm/StopForm";
import ClusterMarker from "./components/ClusterMarker";
import supercluster from "points-cluster";

const mapStateToProps = (state) => {
  return {
    stops: state.stops,
    createStop: PropTypes.func.isRequired,
    ImgsReducer: state.ImgsReducer,
    GroupsReducer: state.GroupsReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchStops: (params) => {
    dispatch(fetchStops(params));
  },
  createStop: (newStop) => {
    dispatch(createStop(newStop));
  },
  fetchImgs: () => {
    dispatch(fetchImgs());
  },
  endCreateStop: () => {
    dispatch(endCreateStop());
  },
  getGroups: () => {
    dispatch(getGroups());
  },
});

class Paradas extends Component {
  constructor() {
    super();
    this.state = {
      stops: [],
      newStop: {
        name: "",
        address: "",
        geo_type: "",
        polygon: [],
        center: "",
        radius: "",
        type: [],
        stop_type: "activator",
        color: "#00249c",
        group: {},
      },
      editStop: {
        name: "",
        address: "",
        geo_type: "",
        polygon: [],
        center: "",
        radius: "",
        type: [],
        stop_type: "activator",
        color: "#00249c",
        group: {},
      },
      stopPosition: [],
      stopsPositions: [],
      map: null,
      maps: null,
      showNewStop: false,
      hojas: null,
      selectStop: "Poligono",
      suggestions: null,
      colors: [
        { id: 1, color: "#00249c" },
        { id: 2, color: "#c6007e" },
        { id: 3, color: "#01bebd" },
        { id: 4, color: "#8c8c8c" },
      ],
      radius: 50,
      poligonos: [],
      circles: [],
      displayModalGroup: false,
      groups: [],
      groupsOptions: [],
      selectGroupValue: null,
      circle: null,
      mapOptions: {
        disableDefaultUI: true,
        disableDoubleClickZoom: true,
        zoom: 11,
        options: {
          gestureHandling: "greedy",
        },
        minZoom: 5,
        maxZoom: 17,
        center: {
          lat: 4.6,
          lng: -74.1,
        },
        mapTypeControl: false,
        styles: mapStyle.silverMode,
      },
      clustersVisible: true,
      clusters: [],
      ContextMenu: {
        view: false,
        lat: "",
        lng: "",
      },
    };
    this.cleanPolygonsAndCircles = this.cleanPolygonsAndCircles.bind(this);
    this.onMapLoaded = this.onMapLoaded.bind(this);
    this.filter = this.filter.bind(this);
    this.showNewStopFunction = this.showNewStopFunction.bind(this);
    this.onChangeCordinates = this.onChangeCordinates.bind(this);
    this.geocodeAddress = this.geocodeAddress.bind(this);
    this.geocodeAddressLatLng = this.geocodeAddressLatLng.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.saveStop = this.saveStop.bind(this);
    this.saveStops = this.saveStops.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleMapChange = this.handleMapChange.bind(this);
    this.handleChangeAdress = this.handleChangeAdress.bind(this);
    this.togglePolygon = this.togglePolygon.bind(this);
    this.toggleCircle = this.toggleCircle.bind(this);
    this.selectAddressClick = this.selectAddressClick.bind(this);
    this.SelectTypeStop = this.SelectTypeStop.bind(this);
    this.selectMarker = this.selectMarker.bind(this);
    this.setColor = this.setColor.bind(this);
    this.FocusMarker = this.FocusMarker.bind(this);
    this.unfocusMarker = this.unfocusMarker.bind(this);
    this.renderPolygons = this.renderPolygons.bind(this);
    this.setCenterMap = this.setCenterMap.bind(this);
    this.setZoomMap = this.setZoomMap.bind(this);
    this.onChangeDisplayModalGroup = this.onChangeDisplayModalGroup.bind(this);
    this.selectValue = this.selectValue.bind(this);
    this.onChangeGroup = this.onChangeGroup.bind(this);
    this.changeRadiusCircle = this.changeRadiusCircle.bind(this);
    this.closeExcel = this.closeExcel.bind(this);
    this.exitCreated = this.exitCreated.bind(this);
  }

  static defaultProps = {
    center: {
      lat: 4.6,
      lng: -74.1,
    },
    zoom: 11,
  };

  componentDidMount() {
    this.setState({
      stops: this.props.stops.stops,
    });
    this.getStops();
    this.props.fetchImgs();
    this.setElementNav();
    this.props.selectItemNav("Paradas");
    this.props.getGroups();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.stops !== this.props.stops.stops &&
      prevProps.stops.stops !== this.props.stops.stops
    ) {
      this.setState({
        stops: this.props.stops.stops,
      });
    }
    if (this.state.maps) {
      if (prevState.stops !== this.state.stops) {
        this.assignMarkers();
      }
    }
    if (this.state.showNewStop !== prevState.showNewStop) {
      this.setElementNav();
    }
    if (this.state.groups !== this.props.GroupsReducer.groups) {
      let newGroupsOptions = [];
      newGroupsOptions = this.props.GroupsReducer.groups.map((group) => {
        return {
          value: group.id,
          label: group.name,
        };
      });
      newGroupsOptions.unshift({ value: "all", label: "Todos" });
      this.setState({
        groups: this.props.GroupsReducer.groups,
        groupsOptions: newGroupsOptions,
        selectGroupValue: "all",
      });
    }
    if (prevState.selectGroupValue !== this.state.selectGroupValue) {
      this.getStops();
    }
  }

  componentWillUnmount() {
    this.props.setElementNav();
  }

  getStops() {
    let params = {};
    params = {
      group: this.state.selectGroupValue,
    };
    this.props.fetchStops(params);
  }

  onMapLoaded(map, maps) {
    const self = this;
    this.setState({
      ...this.state,
      mapsLoaded: true,
      map: map,
      maps: maps,
    });
    maps.event.addListener(map, "dblclick", function (event) {
      const LatLng = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      self.geocodeAddressLatLng(LatLng);
      self.setState({
        ContextMenu: {
          view: true,
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        },
      });
      if (self.state.selectStop === "Circulo") {
        self.toggleCircle();
      } else {
        self.togglePolygon();
      }
    });
    if (this.props.stops.stops) {
      this.assignMarkers();
    }
  }

  handleMapChange = ({ center, zoom, bounds }) => {
    this.setState({
      zoom: zoom,
    });
    this.setState(
      {
        mapOptions: {
          ...this.state.mapOptions,
          center,
          zoom,
          bounds,
        },
      },
      () => {
        this.createClusters(this.props);
      }
    );
  };

  setCenterMap(c) {
    this.setState({
      center: c,
    });
  }

  setZoomMap(z) {
    this.setState({
      zoom: z,
    });
  }

  createClusters = (props) => {
    this.setState({
      clusters: this.state.mapOptions.bounds
        ? this.getClusters(props).map(({ wx, wy, numPoints, points, id }) => ({
            lat: wy,
            lng: wx,
            numPoints,
            id: points[0].id,
            points,
            idVehicle: id,
            emai: points[0].id,
          }))
        : [],
    });
  };

  getClusters = () => {
    console.log("getClusters");
    console.log(this.state.stops);
    const markersData = this.state.stops // fill(0) for loose mode
      .map((stop) => ({
        id: stop.id,
        lat: stop.center.coordinates[0],
        lng: stop.center.coordinates[1],
      }));

    console.log(markersData);

    const clusters = supercluster(markersData, {
      minZoom: 0,
      maxZoom: 16,
      radius: 60,
    });

    return clusters(this.state.mapOptions);
  };

  geocodeAddressLatLng(LatLng) {
    const geocoder = new this.state.maps.Geocoder();
    if (this.state.maps) {
      geocoder.geocode({ location: LatLng }, (results, status) => {
        if (status === "OK") {
          const address = results[0].formatted_address;
          this.setState({
            suggestions: null,
            newStop: {
              ...this.state.newStop,
              address: address,
            },
          });
        } else {
          console.log("peticion invalida");
        }
      });
    }
  }

  geocodeAddress(address) {
    const geocoder = new this.state.maps.Geocoder();
    if (this.state.maps) {
      geocoder.geocode({ address: address }, (results, status) => {
        if (status === "OK") {
          this.state.map.setCenter(results[0].geometry.location);
          this.state.map.setZoom(15);
          this.setState({
            suggestions: null,
            newStop: {
              ...this.state.newStop,
              address: address,
            },
            ContextMenu: {
              view: true,
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            },
          });
          if (this.state.selectStop === "Poligono") {
            this.togglePolygon();
          }
          if (this.state.selectStop === "Circulo") {
            this.toggleCircle();
          }
        } else {
          console.log("peticion invalida");
        }
      });
    }
  }

  async assignMarkers() {
    this.cleanPolygonsAndCircles();
    const stops = this.state.stops;
    for await (let stop of stops) {
      if (stop.type === "2") {
        this.renderPolygons(stop);
      }
      if (stop.type === "3") {
        this.renderCircles(stop);
      }
    }
  }

  cleanPolygonsAndCircles() {
    this.state.poligonos.forEach(async (poligono) => {
      await poligono.setMap(null);
    });
    this.state.circles.forEach(async (circle) => {
      await circle.setMap(null);
    });
    this.setState({
      poligonos: [],
      circles: [],
    });
  }

  renderPolygons(stop) {
    var verticesPoligono = [];
    const GJSON = stop.polygon;
    for (var i = 0; i < GJSON.coordinates[0].length; i++) {
      verticesPoligono.push({
        lat: GJSON.coordinates[0][i][0],
        lng: GJSON.coordinates[0][i][1],
      });
    }
    const poligono = new this.state.maps.Polygon({
      path: verticesPoligono,
      editable: false,
      draggable: false,
      fillColor: stop.color,
      strokeColor: stop.color,
    });
    console.log("estos son los poligonos", poligono);
    this.setState({
      poligonos: [...this.state.poligonos, poligono],
    });
    poligono.setMap(this.state.map);
  }

  renderCircles(stop) {
    const GJSON = stop.center;
    if (GJSON.coordinates) {
      const circle = new this.state.maps.Circle({
        strokeColor: stop.color,
        fillColor: stop.color,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillOpacity: 0.35,
        center: {
          lat: GJSON.coordinates[0],
          lng: GJSON.coordinates[1],
        },
        radius: stop.width,
      });
      this.setState({
        circles: [...this.state.circles, circle],
      });
      circle.setMap(this.state.map);
    }
  }

  filter(event) {
    var stop = event.target.value;
    const data = this.props.stops.stops;
    const newData = data.filter(function (item) {
      const itemData = item.name.toUpperCase();
      const stopData = stop.toUpperCase();
      return itemData.indexOf(stopData) > -1;
    });
    this.setState({
      stops: newData,
    });
  }

  showNewStopFunction() {
    if (!this.state.showNewStop) {
      this.setState({
        showNewStop: true,
      });
    } else {
      if (this.state.poligono) {
        this.state.poligono.setMap(null);
        this.setState({
          poligono: null,
        });
      }
      if (this.state.circle) {
        this.setState({
          circle: null,
        });
        this.state.circle.setMap(null);
      }
      this.setState({
        showNewStop: false,
      });
    }
  }

  handleChangeAdress(event) {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({
      newStop: {
        ...this.state.newStop,
        [name]: value,
      },
    });
    // Create a new session token.
    let sessionToken = new this.state.maps.places.AutocompleteSessionToken();
    var request = {
      input: value,
      type: ["address"],
      componentRestriction: { country: "de" },
      sessionToken: sessionToken,
    };
    // Pass the token to the autocomplete service.
    var autocompleteService = new this.state.maps.places.AutocompleteService();
    autocompleteService.getPlacePredictions(request, (results, status) => {
      this.setState({
        suggestions: null,
      });
      if (status === "OK") {
        for (var i = 0; i < results.length && i < 5; i++) {
          var result = results[i];
          if (this.state.suggestions === null) {
            this.setState({
              suggestions: [result],
            });
          } else {
            this.setState({
              suggestions: [...this.state.suggestions, result],
            });
          }
        }
      }
    });
  }

  onChangeCordinates(event) {
    try {
      const target = event.target;
      const name = target.name;
      console.log(this.state.ContextMenu);
      this.setState(
        {
          ContextMenu: {
            ...this.state.ContextMenu,
            view: true,
            [name]: parseFloat(event.target.value),
          },
        },
        () => {
          if (this.state.ContextMenu.lat && this.state.ContextMenu.lng) {
            console.log("cambio de coordenadas");
            this.geocodeAddressLatLng(this.state.ContextMenu);
            if (this.state.selectStop === "Circulo") {
              this.toggleCircle();
            } else {
              this.togglePolygon();
            }
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      newStop: {
        ...this.state.newStop,
        [name]: value,
      },
    });
  };

  handleFileChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const self = this;
    this.setState({
      [name]: value,
    });
    let hojas = [];
    if (name === "file") {
      let reader = new FileReader();
      if (target.files[0]) {
        reader.readAsArrayBuffer(target.files[0]);
        reader.onloadend = (e) => {
          var data = new Uint8Array(e.target.result);
          var workbook = XLSX.read(data, { type: "array" });
          workbook.SheetNames.forEach(function (SheetName) {
            var XL_row_object = XLSX.utils.sheet_to_row_object_array(
              workbook.Sheets[SheetName]
            );
            if (!XL_row_object[0].direccion && !XL_row_object[0].LtnLng) {
              alert(
                "Su archivo no contiene campo de direccion o cordenadas, por favor agregarlo"
              );
              target.value = "";
            } else {
              hojas.push({
                data: XL_row_object,
                SheetName,
              });
            }
          });
          if (hojas.length > 0) {
            self.setState({
              selectedFileDocument: target.files[0],
              hojas: hojas,
            });
          }
        };
      }
    }
  }

  changeRadiusCircle(i) {
    const radius = Math.round(this.state.circle.getRadius());
    if (i === 0) {
      if (radius > 10) {
        this.state.circle.setRadius(radius - 10);
        this.setState({
          radius: radius - 10,
        });
      }
    } else if (i === 1) {
      if (radius < 100) {
        this.state.circle.setRadius(radius + 10);
        this.setState({
          radius: radius + 10,
        });
      }
    }
  }

  onDataChange = (value) => {
    var values = value != null ? value.map((item) => item.value) : null;
    this.setState({
      newStop: {
        ...this.state.newStop,
        type: values,
      },
    });
  };

  saveStops() {
    const geocoder = new this.state.maps.Geocoder();
    const newAdress = this.state.hojas[0].data;
    newAdress.map((item) => {
      if (this.state.maps) {
        geocoder.geocode({ address: item.direccion }, (results, status) => {
          if (status === "OK") {
            const { poligono, circle } = this.state;
            const { name, address, type, color } = this.state.newStop;
            let newStop = {
              name: name,
              address: address,
              geo_type: poligono ? "poly" : circle ? "circle" : "",
              polygon: "",
              center: JSON.stringify({
                type: "Point",
                coordinates: [
                  results[0].geometry.location.lat(),
                  results[0].geometry.location.lng(),
                ],
              }),
              radius: this.state.radius,
              type: type,
              color: color,
            };
            this.props.createStop(newStop);
          } else {
            console.log("peticion invalida");
          }
        });
      }
    });
  }

  async saveStop() {
    const { poligono, circle } = this.state;
    let GeoJSON;
    let center;
    if (poligono) {
      const paths = poligono.getPath();
      var bounds = new this.state.maps.LatLngBounds();
      GeoJSON = {
        type: "Polygon",
        coordinates: [[]],
      };
      for (var i = 0; i < paths.length; i++) {
        GeoJSON.coordinates[0].push([
          paths.getAt(i).lat(),
          paths.getAt(i).lng(),
        ]);
        bounds.extend(paths.getAt(i));
      }
      center = {
        type: "Point",
        coordinates: [bounds.getCenter().lat(), bounds.getCenter().lng()],
      };
    }
    if (circle) {
      const lat = circle.center.lat();
      const lng = circle.center.lng();

      center = {
        type: "Point",
        coordinates: [lat, lng],
      };
    }
    const { name, address, type, color, stop_type, group } = this.state.newStop;
    if ((name, address)) {
      let newStop = {
        name: name,
        address: address,
        geo_type: poligono ? "poly" : circle ? "circle" : "",
        polygon: GeoJSON,
        center: JSON.stringify(center),
        radius: this.state.radius,
        type: type,
        color: color,
        stop_type: stop_type,
        group: group,
      };
      await this.props.createStop(newStop);
      this.props.fetchStops();
    } else {
      console.log("falta informacion");
    }
  }

  setElementNav() {
    const element = (
      <div
        className="row"
        style={{ marginLeft: "20px", gridGap: "10px", alignItems: "center" }}
      >
        <button
          onClick={this.showNewStopFunction}
          className="row btn-crear-parada btn2"
          style={{ cursor: "pointer" }}
        >
          {this.state.showNewStop ? "Cerrar" : "Crear parada"}
          <div className="paradas-content-lines">
            <div className="paradas-line line1"> </div>
            <div className="paradas-line line2"> </div>
          </div>
        </button>
        <div className="paradas-list-header-row2">
          <label htmlFor="file">
            <CargarIco
              className="ico-upload"
              style={{ width: "20px", height: "20px", cursor: "pointer" }}
            />
          </label>
          <input
            className="paradas-list-header-selecFile"
            type="file"
            name="file"
            id="file"
            onChange={this.handleFileChange}
            accept="file/xlsx"
            style={{ display: "none" }}
          />
        </div>
      </div>
    );
    this.props.setElementNav(element);
  }

  togglePolygon() {
    console.log(this.state.ContextMenu);
    var verticesPoligono = [
      {
        lat: this.state.ContextMenu.lat + 0.001,
        lng: this.state.ContextMenu.lng + 0.001,
      },
      {
        lat: this.state.ContextMenu.lat + 0.001,
        lng: this.state.ContextMenu.lng - 0.001,
      },
      {
        lat: this.state.ContextMenu.lat - 0.001,
        lng: this.state.ContextMenu.lng - 0.001,
      },
      {
        lat: this.state.ContextMenu.lat - 0.001,
        lng: this.state.ContextMenu.lng + 0.001,
      },
    ];
    const poligono = new this.state.maps.Polygon({
      path: verticesPoligono,
      editable: true,
      draggable: true,
      fillColor: this.state.newStop.color,
      strokeColor: this.state.newStop.color,
    });
    if (this.state.poligono) {
      this.state.poligono.setMap(null);
    }
    if (this.state.circle) {
      this.state.circle.setMap(null);
      this.setState({
        circle: null,
      });
    }
    this.setState({
      poligono: poligono,
      showNewStop: true,
    });
    poligono.setMap(this.state.map);
  }

  toggleCircle() {
    const circle = new this.state.maps.Circle({
      strokeColor: this.state.newStop.color,
      fillColor: this.state.newStop.color,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.35,
      center: {
        lat: this.state.ContextMenu.lat,
        lng: this.state.ContextMenu.lng,
      },
      radius: this.state.radius,
      editable: true,
    });
    if (this.state.circle) {
      this.state.circle.setMap(null);
    }
    if (this.state.poligono) {
      this.state.poligono.setMap(null);
      this.setState({
        poligono: null,
      });
    }
    this.setState({
      circle: circle,
      showNewStop: true,
    });
    circle.setMap(this.state.map);
  }

  selectAddressClick(event) {
    var address = event.target.innerText;
    this.geocodeAddress(address);
  }

  SelectTypeStop(event) {
    event.preventDefault();
    const value = event.target.value;
    this.setState({
      selectStop: value,
    });
  }

  FocusMarker(id) {
    this.setState({
      focusMarker: id,
    });
  }

  unfocusMarker() {
    this.setState({
      focusMarker: null,
    });
  }

  selectMarker(id) {
    const marker = this.state.stops.filter((stop) => {
      return stop.id === id;
    });
    const LatLng = { lat: marker[0].latitud, lng: marker[0].longitud };
    this.state.map.setCenter(LatLng);
    this.state.map.setZoom(17);
  }

  setColor(color) {
    this.setState({
      newStop: {
        ...this.state.newStop,
        color: color,
      },
    });
  }

  onChangeDisplayModalGroup() {
    this.setState({
      displayModalGroup: !this.state.displayModalGroup,
    });
  }

  selectValue(group) {
    this.setState({
      selectGroupValue: group,
    });
  }

  onChangeGroup(group) {
    if (group === "none") {
      this.setState({
        newStop: {
          ...this.state.newStop,
          group: {},
        },
      });
    } else {
      this.setState({
        newStop: {
          ...this.state.newStop,
          group: this.state.groups.find((item) => item.id === group),
        },
      });
    }
  }

  closeExcel() {
    this.setState({
      hojas: null,
    });
  }

  exitCreated() {
    this.props.fetchStops();
    this.props.endCreateStop();
  }

  render() {
    let options;
    if (this.props.ImgsReducer.imgs_types) {
      options = this.props.ImgsReducer.imgs_types.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    }
    return (
      <div className="Paradas">
        <div className="paradas-container row">
          {this.state.hojas ? (
            <Excel
              stops={this.state.hojas[0].data}
              map={this.state.map}
              maps={this.state.maps}
              closeExcel={this.closeExcel}
            />
          ) : null}
          <div className="column paradas-container-list">
            <div className="column list-header">
              <div className="column" style={{ width: "100%" }}>
                <div className="row paradas-list-header-row1">
                  <input
                    type="search"
                    placeholder={"Busqueda..."}
                    onChange={this.filter}
                    style={{ zIndex: 2 }}
                  />
                </div>
                <div className="row paradas-list-header-row1">
                  <MotionSelectTest
                    placeholder="Seleccionar grupo"
                    options={this.state.groupsOptions}
                    selectValue={this.selectValue}
                    extraElement={
                      <div
                        onClick={this.onChangeDisplayModalGroup}
                        style={{ padding: "0.5em" }}
                      >
                        Crear grupo...
                      </div>
                    }
                    valueSelected={this.state.selectGroupValue}
                  />
                </div>
                <form
                  className={`column paradas-list-form ${
                    !this.state.showNewStop ? "form-out" : ""
                  }`}
                >
                  <StopForm
                    stop={this.state.newStop}
                    onDataChange={this.onDataChange}
                    handleChange={this.handleChange}
                    handleChangeAdress={this.handleChangeAdress}
                    onChangeCordinates={this.onChangeCordinates}
                    suggestions={this.state.suggestions}
                    saveStop={this.saveStop}
                    setColor={this.setColor}
                    colors={this.state.colors}
                    circle={this.state.circle}
                    radius={this.state.radius}
                    SelectTypeStop={this.SelectTypeStop}
                    selectStop={this.state.selectStop}
                    changeRadiusCircle={this.changeRadiusCircle}
                    groups={this.state.groups}
                    onChangeGroup={this.onChangeGroup}
                    options={options}
                    selectAddressClick={this.selectAddressClick}
                    center={this.state.ContextMenu}
                  />
                </form>
              </div>
            </div>
            <div
              className="column paradas-list"
              style={{ transform: "translateY(0px)" }}
            >
              {this.state.stops.map((item) => {
                return (
                  <CardList
                    key={item.id}
                    id={item.id}
                    name={item.name}
                    // state={item.state}
                    stop={item}
                    lat={item.center.coordinates[0]}
                    lng={item.center.coordinates[1]}
                    selectMarker={this.selectMarker}
                    FocusMarker={this.FocusMarker}
                    unfocusMarker={this.unfocusMarker}
                    setCenterMap={this.setCenterMap}
                    setZoomMap={this.setZoomMap}
                  />
                );
              })}
            </div>
          </div>
          <div className="column paradas-container-map">
            <Map
              key="google_mapComponent"
              defaultCenter={this.props.center}
              defaultZoom={this.props.zoom}
              bootstrapURLKeys={{
                key: "AIzaSyBwfkPvvIzAUCw0VFGSr8fjhAsYRg5_E4I",
                libraries: ["places"],
              }}
              center={this.state.center}
              zoom={this.state.zoom}
              options={this.state.mapOptions}
              yesIWantToUseGoogleMapApiInternals={true}
              onChange={this.handleMapChange}
              onGoogleApiLoaded={({ map, maps }) => this.onMapLoaded(map, maps)}
            >
              {this.state.zoom < 14
                ? this.state.clustersVisible
                  ? this.state.clusters.map((item) => {
                      if (item.numPoints === 1) {
                        return (
                          <Marker
                            key={item.id}
                            id={item.id}
                            lat={item.lat}
                            lng={item.lng}
                            focusMarker={this.state.focusMarker}
                            setCenterMap={this.setCenterMap}
                            setZoomMap={this.setZoomMap}
                          />
                        );
                      } else {
                        return (
                          <ClusterMarker
                            key={item.id}
                            lat={item.lat}
                            lng={item.lng}
                            points={item.points}
                            setCenterMap={this.props.setCenterMap}
                            setZoomMap={this.handleZoom}
                          />
                        );
                      }
                    })
                  : this.state.stops.map((item) => {
                      return (
                        <Marker
                          key={item.id}
                          id={item.id}
                          lat={item.center.coordinates[0]}
                          lng={item.center.coordinates[1]}
                          focusMarker={this.state.focusMarker}
                          setCenterMap={this.setCenterMap}
                          setZoomMap={this.setZoomMap}
                        />
                      );
                    })
                : null}
            </Map>
          </div>
          {this.props.stops.isLoadingCreate ? (
            <ModalCreated loading={true} />
          ) : this.props.stops.errorCreated ? (
            <ModalCreated
              error={true}
              title={"Ups ocurrio un error en la creacion de la parada"}
              exit={this.exitCreated}
            />
          ) : this.props.stops.created ? (
            <ModalCreated
              succes={true}
              title={"Parada creada con éxito!"}
              exit={this.exitCreated}
            />
          ) : null}
          {this.state.displayModalGroup ? (
            <ModalGroupCreate exit={this.onChangeDisplayModalGroup} />
          ) : null}
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Paradas)
);
