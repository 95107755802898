import React, { Component } from "react";
import "./Seguimiento.scss";

export default class Seguimiento extends Component {
  render() {
    return (
      <div className="column resumen-seguimiento">
        <div className="gray2 title">Seguimiento</div>
        <div className="row resumen-seguimiento-items">
          <div className="column">
            <div className="blue1 sub-title">1292</div>
            <p className="gray3 text">Total</p>
          </div>
          <div className="column">
            <div className="red1 sub-title">396</div>
            <p className="gray3 text">Tiempo en contra</p>
          </div>
          <div className="column">
            <div className="sky2 sub-title">213</div>
            <p className="gray3 text">Tiempo a favor</p>
          </div>
          <div className="column">
            <div className="gray2 sub-title">358</div>
            <p className="gray3 text">No tiene rutina</p>
          </div>
          <div className="column">
            <div className="gray3 sub-title">325</div>
            <p className="gray3 text">No tiene unidades</p>
          </div>
        </div>
      </div>
    );
  }
}
