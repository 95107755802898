export const SET_USER_DATA = "USER_SET_DATA";
export const REMOVE_USER_DATA = "USER_REMOVE_DATA";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";

export const ADD_USER_TO_MOTION = "ADD_USER_TO_MOTION";
export const ADD_USER_TO_MOTION_SUCCESS = "ADD_USER_TO_MOTION_SUCCESS";
export const DATA_FAILED = "DATA_FAILED";

export const GET_USER_DATA = "GET_USER_DATA";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_FAILED = "GET_USER_DATA_FAILED";
