import React, { useState, useEffect } from "react";
import useRoute from "../../../../../../../../state/hooks/useRoute";
import "./Calendar.scss";
import {
  fetchDays,
  fetchDaysWeek,
  fetchMonths,
} from "../../../../../../../../redux/actions/routinesActions";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    RoutinesReducer: state.RoutinesReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchDays: () => {
    dispatch(fetchDays());
  },
  fetchDaysWeek: () => {
    dispatch(fetchDaysWeek());
  },
  fetchMonths: () => {
    dispatch(fetchMonths());
  },
});

function CalendarComponent(props) {
  const { newRoute, setRoute } = useRoute();
  const [days, setDays] = useState([]);
  const [months, setMonths] = useState([]);
  const [daysWeek, setDaysWeek] = useState([]);

  useEffect(() => {
    props.fetchDays();
    props.fetchDaysWeek();
    props.fetchMonths();
  }, []);

  useEffect(() => {
    if (props.RoutinesReducer.days) setDays(props.RoutinesReducer.days);
  }, [props.RoutinesReducer.days]);

  useEffect(() => {
    if (props.RoutinesReducer.daysWeek)
      setDaysWeek(props.RoutinesReducer.daysWeek);
  }, [props.RoutinesReducer.daysWeek]);

  useEffect(() => {
    if (props.RoutinesReducer.months) setMonths(props.RoutinesReducer.months);
  }, [props.RoutinesReducer.months]);

  const selectDay = (value) => {
    const exist = newRoute.schedule.day_number_ids.includes(value);
    let day_number_ids = [];
    if (exist) {
      // Si ya existe lo quitamos
      day_number_ids = newRoute.schedule.day_number_ids.filter(
        (day) => day !== value
      );
    } else {
      // Si no lo agregamos
      day_number_ids = [...newRoute.schedule.day_number_ids, value];
    }
    setRoute({
      ...newRoute,
      schedule: {
        ...newRoute.schedule,
        day_number_ids: day_number_ids,
      },
    });
  };

  const selectMonth = (value) => {
    const exist = newRoute.schedule.month_ids.includes(value);
    let month_ids = [];
    if (exist) {
      // Si ya existe lo quitamos
      month_ids = newRoute.schedule.month_ids.filter(
        (month) => month !== value
      );
    } else {
      // Si no lo agregamos
      month_ids = [...newRoute.schedule.month_ids, value];
    }

    setRoute({
      ...newRoute,
      schedule: {
        ...newRoute.schedule,
        month_ids: month_ids,
      },
    });
  };

  const selectDayWeek = (value) => {
    // validamos si ya esta seleccionado ese dia
    const exist = newRoute.schedule.week_day_ids.includes(value);
    let week_day_ids = [];
    if (exist) {
      // Si ya existe lo quitamos
      week_day_ids = newRoute.schedule.week_day_ids.filter(
        (day) => day !== value
      );
    } else {
      // Si no lo agregamos
      week_day_ids = [...newRoute.schedule.week_day_ids, value];
    }

    setRoute({
      ...newRoute,
      schedule: {
        ...newRoute.schedule,
        week_day_ids: week_day_ids,
      },
    });
  };

  return (
    <div className="Calendar">
      {newRoute.schedule.schedule_type === "day" ? (
        newRoute.schedule.relative_day_type === "weekly" ? (
          <div className="column weekly">
            <div className="title">Selecione los dias</div>
            <div className="column days-list">
              {daysWeek.map((day) => {
                return (
                  <div
                    key={day.id}
                    className={`dayWeek ${
                      newRoute.schedule.week_day_ids.includes(day.id)
                        ? "dayWeek-active"
                        : ""
                    }`}
                    onClick={() => selectDayWeek(day.id)}
                  >
                    <div className="row days-content">
                      <div className="day">{day.name}</div>
                      <div className="row day-types">
                        <div>Pares</div>
                        <div>Impares</div>
                        <div>Todos</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="column monthly">
            <div className="months column">
              <div className="title">Selecione los meses</div>
              <div className="months-list">
                {months.map((month) => {
                  return (
                    <div
                      key={month.id}
                      className={`month ${
                        newRoute.schedule.month_ids.includes(month.id)
                          ? "month-active"
                          : ""
                      }`}
                      onClick={() => selectMonth(month.id)}
                    >
                      {month.name}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="month-days column">
              <div className="title">Selecione los dias</div>
              <div className="month-days-list column">
                {days.map((day) => {
                  return (
                    <div
                      key={day.id}
                      className={`day ${
                        newRoute.schedule.day_number_ids.includes(day.id)
                          ? "day-active"
                          : ""
                      }`}
                      onClick={() => selectDay(day.id)}
                    >
                      {day.value}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )
      ) : null}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarComponent);
