import React from "react";
import { connect } from "react-redux";
import "./Schedules.scss";

import CalendarComponent from "./components/Calendar/Calendar";
import AddSchedule from "./components/AddShedule/AddSchedule";
import useRoute from "../../../../../../state/hooks/useRoute";

const mapStateToProps = (state) => {
  return {
    RoutesReducer: state.RoutesReducer,
    RoutinesReducer: state.RoutinesReducer,
  };
};

function Schedules(props) {
  const { newRoute } = useRoute();
  return (
    <div className="Schedules row">
      <div className="ModalRutas-horarios-content row">
        {newRoute.schedule.schedule_type !== "activation" ? (
          <CalendarComponent />
        ) : null}
        <AddSchedule
          exitStops={props.exitStops}
          selectedRoute={props.selectedRoute}
          directionsService={props.directionsService}
          maps={props.maps}
        />
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(Schedules);
