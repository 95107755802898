import React, { Component } from "react";
import "./Rutas.scss";

import { withRouter } from "react-router";
import { connect } from "react-redux";

import { fetchUnits } from "../../redux/actions/unitsActions";
import { fetchStops } from "../../redux/actions/stopsActions";

import ModalRutas from "./components/ModalRutas/ModalRutas";
import CardRutas from "./components/CardRutas/CardRutas";
import {
  endCreateRoute,
  endEditRoute,
  fetchRoutes,
} from "../../redux/actions/routesActions";
import {
  endCreateRoutine,
  fetchRoutines,
} from "../../redux/actions/routinesActions";
import ModalCreated from "../../componets/ModalCreated/ModalCreated";
import NewRouteProvider from "../../state/providers/NewRoute";
import rutasIco from "./assets/motion_icon-rutas.svg";
import NotFound from "../notfound/NotFound";

const mapStateToProps = (state) => {
  return {
    units: state.units,
    stops: state.stops,
    RoutesReducer: state.RoutesReducer,
    RoutinesReducer: state.RoutinesReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchUnits: () => {
    dispatch(fetchUnits());
  },
  fetchStops: () => {
    dispatch(fetchStops());
  },
  fetchRoutes: () => {
    dispatch(fetchRoutes());
  },
  fetchRoutines: () => {
    dispatch(fetchRoutines());
  },
  endCreateRoutine: () => {
    dispatch(endCreateRoutine());
  },
  endCreateRoute: () => {
    dispatch(endCreateRoute());
  },
  endEditRoute: () => {
    dispatch(endEditRoute());
  },
});

class Rutas extends Component {
  constructor() {
    super();
    this.state = {
      showModalAdd: false,
      ruta: [],
      stops: [],
      routes: [],
      showEditRoute: false,
      selectedRoute: null,
    };
    this.filter = this.filter.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.EditRoute = this.EditRoute.bind(this);
    this.CloseEditRoute = this.CloseEditRoute.bind(this);
    this.CopyRoute = this.CopyRoute.bind(this);
    this.CloseCopyRoute = this.CloseCopyRoute.bind(this);
    this.exitCreated = this.exitCreated.bind(this);
    this.exitEdite = this.exitEdite.bind(this);
  }

  componentDidMount() {
    this.setState({
      stops: this.props.stops.stops,
      routes: this.props.RoutesReducer.routes,
    });
    this.props.fetchStops();
    this.props.fetchUnits();
    this.props.fetchRoutes();
    this.props.fetchRoutines();
    this.props.selectItemNav("Rutas");
    this.setElementNav();
  }

  componentDidUpdate(prevProps) {
    if (
      this.state.routes !== this.props.RoutesReducer.routes &&
      prevProps.RoutesReducer.routes !== this.props.RoutesReducer.routes
    ) {
      this.setState({
        routes: this.props.RoutesReducer.routes,
      });
    }
  }

  handleModal() {
    this.props.fetchRoutes();
    this.props.fetchRoutines();
    this.props.fetchStops();
    this.setState({
      showModalAdd: !this.state.showModalAdd,
    });
  }

  onDataChange = (value, action) => {
    var values = value != null ? value.map((item) => item.value) : null;
    this.setState({
      rutas: {
        ...this.state.rutas,
        [action.name]: values,
      },
    });
  };

  filter(event) {
    var route = event.target.value;
    const data = this.props.RoutesReducer.routes;
    const newData = data.filter(function (item) {
      const itemData = item.name.toUpperCase();
      const routeData = route.toUpperCase();
      return itemData.indexOf(routeData) > -1;
    });

    this.setState({
      routes: newData,
    });
  }

  setElementNav() {
    const element = (
      <div
        className="row"
        style={{ marginLeft: "20px", gridGap: "10px", alignItems: "center" }}
      >
        <button
          onClick={this.handleModal}
          className="row btn-crear-parada btn2"
          style={{ cursor: "pointer" }}
        >
          Crear ruta
          <div className="paradas-content-lines">
            <div className="paradas-line line1"></div>
            <div className="paradas-line line2"></div>
          </div>
        </button>
        <div className="paradas-list-header-row2">
          <div className="rutas-header-filter">
            <input
              onChange={this.filter}
              type="search"
              name=""
              id=""
              className="input-search-rutas"
            />
          </div>
        </div>
      </div>
    );
    this.props.setElementNav(element);
  }

  componentWillUnmount() {
    this.props.setElementNav();
  }

  EditRoute(route) {
    this.props.fetchRoutes();
    this.props.fetchRoutines();
    this.props.fetchStops();
    this.setState({
      showEditRoute: true,
      selectedRoute: route,
    });
  }

  CloseEditRoute() {
    this.props.fetchRoutes();
    this.props.fetchRoutines();
    this.props.fetchStops();
    this.setState({
      showEditRoute: false,
      selectedRoute: null,
    });
  }

  CopyRoute(route) {
    this.props.fetchRoutes();
    this.props.fetchRoutines();
    this.props.fetchStops();
    this.setState({
      showCopyRoute: true,
      selectedRoute: route,
    });
  }

  CloseCopyRoute() {
    this.props.fetchRoutes();
    this.props.fetchRoutines();
    this.props.fetchStops();
    this.setState({
      showCopyRoute: false,
      selectedRoute: null,
    });
  }

  exitCreated() {
    this.props.fetchRoutes();
    this.props.fetchRoutines();
    this.props.fetchStops();
    this.props.endCreateRoute();
    this.handleModal();
    this.setState({
      showModalAdd: false,
    });
  }

  exitEdite() {
    this.props.fetchRoutes();
    this.props.fetchRoutines();
    this.props.fetchStops();
    this.props.endEditRoute();
    this.handleModal();
    this.setState({
      showModalAdd: false,
    });
  }

  render() {
    let options;
    if (this.state.stops) {
      options = this.state.stops.map((item) => ({
        value: [item.lat, item.lng],
        label: item.name,
      }));
    }

    return (
      <div className="main rutas">
        {this.state.routes.length < 1 ? (
          <NotFound
            image={rutasIco}
            title="No encontramos rutas en su cuenta!"
            description="Parece que aún no se han creado rutas desde su cuenta, proceda a crear una nueva ruta."
            button={
              <button
                onClick={this.handleModal}
                className="row btn-crear-parada btn2"
                style={{ cursor: "pointer" }}
              >
                Crear ruta
                <div className="paradas-content-lines">
                  <div className="paradas-line line1"></div>
                  <div className="paradas-line line2"></div>
                </div>
              </button>
            }
          />
        ) : (
          <div>
            <ul className="Rutas">
              {this.state.routes.map((route) => {
                let origin = {
                  name: "",
                };
                let destiny = {
                  name: "",
                };
                if (route.stops.length > 0) {
                  let originFound = route.stops.find(
                    (stop) => stop.sequence === 0
                  );
                  if (originFound) origin = originFound;
                  let destinyfound = route.stops.find(
                    (stop) => stop.sequence === route.stops.length - 1
                  );
                  if (destinyfound) destiny = destinyfound;
                }
                return (
                  <CardRutas
                    key={route.id}
                    id={route.id}
                    name={route.name}
                    number={route.number}
                    origin={origin.name}
                    destiny={destiny.name}
                    EditRoute={() => this.EditRoute(route)}
                    CopyRoute={() => this.CopyRoute(route)}
                  />
                );
              })}
            </ul>
          </div>
        )}

        <NewRouteProvider>
          {this.state.showModalAdd ? (
            <ModalRutas
              handleModal={this.handleModal}
              options={options}
              onDataChange={this.onDataChange}
              ruta={this.state.ruta}
              create={true}
            />
          ) : this.state.showEditRoute ? (
            <ModalRutas
              handleModal={this.CloseEditRoute}
              options={options}
              onDataChange={this.onDataChange}
              ruta={this.state.ruta}
              selectedRoute={this.state.selectedRoute}
              edit={true}
            />
          ) : this.state.showCopyRoute ? (
            <ModalRutas
              handleModal={this.CloseCopyRoute}
              options={options}
              onDataChange={this.onDataChange}
              ruta={this.state.ruta}
              selectedRoute={this.state.selectedRoute}
              copy={true}
            />
          ) : null}
        </NewRouteProvider>
        {this.props.RoutesReducer.isLoadingCreate ||
        this.props.RoutesReducer.isLoadingEdit ? (
          <ModalCreated loading={true} />
        ) : this.props.RoutesReducer.errorCreated ? (
          <ModalCreated
            error={true}
            title={"Ups ocurrio un error en la creacion de la ruta"}
            exit={this.exitCreated}
          />
        ) : this.props.RoutesReducer.created ? (
          <ModalCreated
            succes={true}
            title={"Ruta creada con éxito!"}
            exit={this.exitCreated}
          />
        ) : this.props.RoutesReducer.edited ? (
          <ModalCreated
            succes={true}
            title={"Ruta editada con éxito!"}
            exit={this.exitEdite}
          />
        ) : this.props.RoutesReducer.errorEdited ? (
          <ModalCreated
            error={true}
            title={"Ups ocurrio un error en la edicion de la ruta"}
            exit={this.exitEdite}
          />
        ) : null}
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Rutas));
