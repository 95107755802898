import React from "react";
import MotionSelect from "../../../../../../componets/Motion/MotionSelect/MotionSelect";
import useRoutine from "../../../../../../state/hooks/useRoutine";
import ScheduleLine from "./components/ScheduleLine/ScheduleLine";
import "./Schedule.scss";

export default function Schedule(props) {
  const { routine, setRoutine } = useRoutine();

  return (
    <div className="Schedule">
      <div className="titles row">
        <div className="Horario title">Horario</div>
        <div className="Parada title">Parada</div>
        <div className="Pasajeros title">Pasajeros</div>
      </div>
      <div className="body-content-item">
        {routine.schedules.map((schedule_line, index) => (
          <ScheduleLine
            key={index}
            arrival={schedule_line.arrival}
            schedule_line={schedule_line}
            index={index}
            passengersAvailable={props.passengersAvailable}
            passengers={props.passengers}
          />
        ))}
      </div>
    </div>
  );
}
