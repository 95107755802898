import React, { Component } from "react";
import {
  Sunburst,
  LabelSeries,
} from "react-vis";
import { AutoSizer } from "react-virtualized";
import "react-virtualized/styles.css";
import D3FlareData from "./data.json";

const LABEL_STYLE = {
  fontFamily: "Montserrat",
  fill: "#c5c5c5",
  fontWeight: "800",
  fontSize: "40px",
  textAnchor: "middle",
};

const LABEL_STYLE_TEXT = {
  fontFamily: "Ubuntu",
  fill: "#c5c5c5",
  fontWeight: "300",
  fontSize: "20px",
  textAnchor: "middle",
};

function getKeyPath(node) {
  if (node) {
    if (!node.parent) {
      return ["EAPPLY"];
    }
    const temp = node.data && node.data.name;
    const temp2 = node.name;
    return [temp || temp2].concat(getKeyPath(node.parent));
  }
}

function updateData(data, keyPath) {
  if (data.children) {
    data.children.map((child) => updateData(child, keyPath));
  }
  // add a fill to all the uncolored cells

  data.style = {
    ...data.style,
    fillOpacity: keyPath && !keyPath[data.name] ? 0.2 : 1,
  };

  return data;
}

const decoratedData = updateData(D3FlareData, false);

class ChartParadas extends Component {
  constructor() {
    super();
    this.state = {
      data: decoratedData,
      pathValue: false,
      finalValue: "Content",
      hoveredCell: false,
    };
  }

  render() {
    const { clicked, data, finalValue, hoveredCell } = this.state;
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <AutoSizer>
          {({ height, width }) => (
            <Sunburst
              animation
              className="basic-sunburst-example"
              hideRootNode
              onValueMouseOver={(node) => {
                if (node) {
                  if (clicked) {
                    return;
                  }
                  const path = getKeyPath(node).reverse();
                  const pathAsMap = path.reduce((res, row) => {
                    res[row] = true;
                    return res;
                  }, {});
                  this.setState({
                    finalValue: path[path.length - 1],
                    pathValue: path.join(" > "),
                    data: updateData(decoratedData, pathAsMap),
                    hoveredCell:
                      node.radius != null &&
                      node.angle != null &&
                      node.angle0 != null
                        ? node
                        : false,
                  });
                }
              }}
              onValueMouseOut={() => {
                this.setState({
                  pathValue: false,
                  finalValue: false,
                  data: updateData(decoratedData, false),
                  hoveredCell: false,
                });
                this.setState.hoveredCell = false;
              }}
              style={{
                stroke: "#FFF",
                strokeOpacity: 10,
                strokeWidth: "5",
              }}
              colorType="literal"
              getSize={(d) => d.value}
              getColor={(d) => d.hex}
              // margin={{ top: 50, bottom: 50, left: 50, right: 50 }}
              data={data}
              width={width}
              height={height}
            >
              {finalValue && (
                <LabelSeries
                  data={[
                    {
                      x: 0,
                      y: -20,
                      label: `${finalValue} `,
                      style: { ...LABEL_STYLE_TEXT },
                    },
                  ]}
                />
              )}
              {hoveredCell && (
                <LabelSeries
                  data={[
                    {
                      x: 0,
                      y: 50,
                      label: `${hoveredCell.value}`,
                      style: { ...LABEL_STYLE, fill: hoveredCell.hex },
                    },
                  ]}
                />
              )}
            </Sunburst>
          )}
        </AutoSizer>
      </div>
    );
  }
}

export default ChartParadas;
