import React, { useState, useEffect } from "react";
import Select from "react-select";
import styled from "styled-components";

const SelectMult = styled(Select)`
  width: 100%;
  position: relative;
  font-size: 14px;
  .css-yk16xz-control {
    border-radius: 1em;
    border: 0.15em solid #8c8c8c40;
  }
  .css-yk16xz-control:hover {
    border-radius: 1em;
    border: 0.15em solid #8c8c8c40;
  }
  .css-1pahdxg-control:hover {
    border-radius: 1em;
    border: 0.15em solid #8c8c8c40;
    box-shadow: none;
  }
`;

const SelectM = styled.select`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 36px;
  font-size: 14px;
  padding: 0.55em 1.75em;
`;

const MotionSdiv = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.75em;
`;

export default function MotionSelect(props) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (props.options) {
      setOptions(props.options);
    }
  }, [props.options]);

  return (
    <MotionSdiv>
      <label htmlFor={props.id}>{props.label}</label>
      {props.multi ? (
        <SelectMult
          options={props.options}
          isMulti
          name={props.name}
          onChange={props.onChange}
          value={props.value}
        />
      ) : (
        <SelectM
          name={props.name}
          onChange={props.onChange}
          value={props.value}
          id={props.id}
        >
          {props.placeholder ? (
            <option value="">{props.placeholder}</option>
          ) : null}
          {options.map((item) => {
            return (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            );
          })}
        </SelectM>
      )}
    </MotionSdiv>
  );
}
