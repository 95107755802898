import {
  GET_METRIC_DATA,
  GET_METRIC_DATA_FAILED,
  GET_METRIC_DATA_SUCCESS,
} from "../types/metrics.types";

const initialState = {
  isLoading: true,
  errMess: null,
  metric_data: {},
};

const MetricsReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_METRIC_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case GET_METRIC_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        metric_data: action.payload,
      };
    case GET_METRIC_DATA_FAILED:
      return {
        ...state,
        isLoading: false,
        errMess: action.payload,
      };
    default:
      return state;
  }
};

export default MetricsReducer;
