import axios from "axios";

import {
  GET_UNITS_LIST,
  GET_UNITS_LIST_SUCCCESS,
  GET_UNITS_LIST_FAILED,
  SOCKET_UNIT,
  SOCKET_UNIT_SUCCESS,
  SOCKET_UNIT_FAILED,
} from "../types/units.types";

export const fetchUnits = () => (dispatch) => {
  var config = {
    method: "GET",
    url: "/api/miroute/units",
    headers: {},
  };
  dispatch({ type: GET_UNITS_LIST, payload: "Loading" });
  axios(config)
    .then(function (response) {
      dispatch({
        type: GET_UNITS_LIST_SUCCCESS,
        payload: response.data.result,
      });
    })
    .catch(function (error) {
      dispatch({ type: GET_UNITS_LIST_FAILED, payload: error });
      console.log(error);
    });
};

export function socketUnit(unit) {
  return (dispatch) => {
    var config = {
      method: "GET",
      url: `/api/miroute/unit/id=${unit.id}&type=${unit.unit_type}`,
      headers: {},
    };
    dispatch({ type: SOCKET_UNIT, payload: "Loading" });
    axios(config)
      .then(function (response) {
        console.log(response)
        dispatch({
          type: SOCKET_UNIT_SUCCESS,
          payload: response.data.result,
        });
      })
      .catch(function (error) {
        dispatch({ type: SOCKET_UNIT_FAILED, payload: error });
        console.log(error);
      });
  };
}
