import React, { useEffect, useState } from "react";
import "./CalendarRoutines.scss";
import { ReactComponent as IcoNext } from "../../../../assets/flecha.svg";

export default function CalendarRoutines(props) {
  return (
    <div className="CalendarRoutines">
      <div className="ActionsCalendar">
        <button
          className="button-calendar btn1-dark next"
          onClick={() => props.onChangeDate("next")}
        >
          <IcoNext className="ico-page ico-next" />
        </button>
        <div className="title-day">Día</div>
        <button
          className="button-calendar btn1-dark prev"
          onClick={() => props.onChangeDate("prev")}
        >
          <IcoNext className="ico-page ico-prev" />
        </button>
      </div>
      <div className="title-date">
        Fecha - {props.currentDayString} {props.currentDayNumber}
      </div>
    </div>
  );
}
