import axios from "axios";

import {
  CREATE_ROUTINE,
  CREATE_ROUTINE_FAILED,
  CREATE_ROUTINE_SUCCESS,
  CREATE_ROUTINE_END,
  GET_ROUTINE,
  GET_ROUTINE_FAILED,
  GET_ROUTINE_SUCCESS,
  EDIT_ROUTINE,
  EDIT_ROUTINE_SUCCESS,
  EDIT_ROUTINE_FAILED,
  EDIT_ROUTINE_END,
  EDIT_TIMELINE,
  EDIT_TIMELINE_SUCCESS,
  EDIT_TIMELINE_FAILED,
  GET_DAYS,
  GET_DAYS_SUCCESS,
  GET_DAYS_FAILED,
  GET_DAYSWEEK,
  GET_DAYSWEEK_SUCCESS,
  GET_DAYSWEEK_FAILED,
  GET_MONTHS,
  GET_MONTHS_SUCCESS,
  GET_MONTHS_FAILED,
  DELETE_ROUTINE,
  DELETE_ROUTINE_SUCCESS,
  DELETE_ROUTINE_FAILED,
  BLOCK_ROUTINE,
  BLOCK_ROUTINE_SUCCESS,
  BLOCK_ROUTINE_FAILED,
  GET_SINGLE_ROUTINE,
  GET_SINGLE_ROUTINE_SUCCESS,
  GET_SINGLE_ROUTINE_FAILED,
} from "../types/routines.types";

export function getSingleRoutine(id) {
  return (dispatch) => {
    var config = {
      method: "GET",
      url: `/api/miroute/routine/id=${id}`,
      headers: {},
    };
    dispatch({
      type: GET_SINGLE_ROUTINE,
      payload: "Loading",
    });
    axios(config)
      .then(function (response) {
        dispatch({
          type: GET_SINGLE_ROUTINE_SUCCESS,
          payload: response.data.result,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_SINGLE_ROUTINE_FAILED,
          payload: error,
        });
        // logoutUser();
        console.log(error);
      });
  };
}

export const fetchRoutines = (params) => async (dispatch) => {
  var config = {
    method: "GET",
    url: `/api/miroute/routines`,
    headers: {},
    params: params,
  };
  dispatch({
    type: GET_ROUTINE,
    payload: "Loading",
  });
  await axios(config)
    .then(function (response) {
      dispatch({
        type: GET_ROUTINE_SUCCESS,
        payload: response.data.result,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_ROUTINE_FAILED,
        payload: error,
      });
      // logoutUser();
    });
};

export const updateRoutines = (params) => async (dispatch) => {
  console.log(params);
  var config = {
    method: "GET",
    url: `/api/miroute/routines`,
    headers: {},
    params: params,
  };
  await axios(config)
    .then(function (response) {
      dispatch({
        type: GET_ROUTINE_SUCCESS,
        payload: response.data.result,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_ROUTINE_FAILED,
        payload: error,
      });
      // logoutUser();
    });
};

export const fetchDays = () => async (dispatch) => {
  var config = {
    method: "GET",
    url: "/api/miroute/days",
    headers: {},
  };
  dispatch({
    type: GET_DAYS,
    payload: "Loading",
  });
  await axios(config)
    .then(function (response) {
      dispatch({
        type: GET_DAYS_SUCCESS,
        payload: response.data.result,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_DAYS_FAILED,
        payload: error,
      });
      // logoutUser();
    });
};

export const fetchDaysWeek = () => async (dispatch) => {
  var config = {
    method: "GET",
    url: "/api/miroute/weekday",
    headers: {},
  };
  dispatch({
    type: GET_DAYSWEEK,
    payload: "Loading",
  });
  await axios(config)
    .then(function (response) {
      dispatch({
        type: GET_DAYSWEEK_SUCCESS,
        payload: response.data.result,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_DAYSWEEK_FAILED,
        payload: error,
      });
      // logoutUser();
    });
};

export const fetchMonths = () => async (dispatch) => {
  var config = {
    method: "GET",
    url: "/api/miroute/months",
    headers: {},
  };
  dispatch({
    type: GET_MONTHS,
    payload: "Loading",
  });
  await axios(config)
    .then(function (response) {
      dispatch({
        type: GET_MONTHS_SUCCESS,
        payload: response.data.result,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_MONTHS_FAILED,
        payload: error,
      });
      // logoutUser();
    });
};

export function createRoutine(newRoute) {
  return (dispatch) => {
    var data = JSON.stringify({
      jsonrpc: "2.0",
      params: {
        name: newRoute.name,
        number: newRoute.number,
        stop_ids: newRoute.stop_ids,
        origin: JSON.stringify(newRoute.origin),
        destiny: JSON.stringify(newRoute.destiny),
        unit_type: "motion",
        motion_unit: 15,
        type: newRoute.type,
        units: newRoute.units,
        routines: newRoute.routines,
      },
    });
    var config = {
      method: "POST",
      url: "/api/miroute/routines/new",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    dispatch({ type: CREATE_ROUTINE, payload: "Loading" });
    axios(config)
      .then(function (response) {
        dispatch({ type: CREATE_ROUTINE_SUCCESS, payload: response.data });
        fetchRoutines();
      })
      .catch(function (error) {
        dispatch({ type: CREATE_ROUTINE_FAILED, payload: error });
        console.log(error);
      });
  };
}

export function endCreateRoutine() {
  return (dispatch) => {
    dispatch({ type: CREATE_ROUTINE_END, payload: "Salir" });
  };
}

export function editRoutine(routine) {
  return (dispatch) => {
    var data = JSON.stringify({
      jsonrpc: "2.0",
      params: routine,
    });
    var config = {
      method: "POST",
      url: "/api/miroute/routines/edit",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    dispatch({ type: EDIT_ROUTINE, payload: "Loading" });
    axios(config)
      .then(function (response) {
        dispatch({ type: EDIT_ROUTINE_SUCCESS, payload: response.data });
        fetchRoutines();
      })
      .catch(function (error) {
        dispatch({ type: EDIT_ROUTINE_FAILED, payload: error });
        console.log(error);
      });
  };
}

export function endEditRoutine() {
  return (dispatch) => {
    dispatch({ type: EDIT_ROUTINE_END, payload: "Salir" });
  };
}

export function deleteRoutine(id) {
  return (dispatch) => {
    var config = {
      method: "GET",
      url: `/api/miroute/routines/delete/${id}`,
    };
    dispatch({ type: DELETE_ROUTINE, payload: "Loading" });
    axios(config)
      .then(function (response) {
        setTimeout(() => {
          dispatch({ type: DELETE_ROUTINE_SUCCESS, payload: response.data });
          fetchRoutines();
        }, 2000);
      })
      .catch(function (error) {
        dispatch({ type: DELETE_ROUTINE_FAILED, payload: error });
        console.log(error);
      });
  };
}

export function blockRoutine(id) {
  return (dispatch) => {
    var config = {
      method: "GET",
      url: `/api/miroute/routines/block/${id}`,
    };
    dispatch({ type: BLOCK_ROUTINE, payload: "Loading" });
    axios(config)
      .then(function (response) {
        setTimeout(() => {
          dispatch({ type: BLOCK_ROUTINE_SUCCESS, payload: response.data });
          fetchRoutines();
        }, 2000);
      })
      .catch(function (error) {
        dispatch({ type: BLOCK_ROUTINE_FAILED, payload: error });
        console.log(error);
      });
  };
}

export function editTimeline(timeline) {
  return (dispatch) => {
    var data = JSON.stringify({
      jsonrpc: "2.0",
      params: {
        id: timeline.id,
        time: timeline.time,
      },
    });
    var config = {
      method: "POST",
      url: "/api/miroute/timeline/edit",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    dispatch({ type: EDIT_TIMELINE, payload: "Loading" });
    axios(config)
      .then(function (response) {
        setTimeout(() => {
          console.log("todavia no a hecho esto");
          dispatch({ type: EDIT_TIMELINE_SUCCESS, payload: response.data });
          fetchRoutines();
        }, 2000);
      })
      .catch(function (error) {
        dispatch({ type: EDIT_TIMELINE_FAILED, payload: error });
        console.log(error);
      });
  };
}
