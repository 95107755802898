import axios from "axios";

import {
  CREATE_ROUTE,
  CREATE_ROUTE_END,
  CREATE_ROUTE_FAILED,
  CREATE_ROUTE_SUCCESS,
  DELETE_ROUTE,
  DELETE_ROUTE_FAILED,
  DELETE_ROUTE_SUCCESS,
  EDIT_ROUTE,
  EDIT_ROUTE_END,
  EDIT_ROUTE_FAILED,
  EDIT_ROUTE_SUCCESS,
  GET_ROUTES,
  GET_ROUTES_FAILED,
  GET_ROUTES_SUCCESS,
  GET_SINGLE_ROUTE,
  GET_SINGLE_ROUTE_FAILED,
  GET_SINGLE_ROUTE_SUCCESS,
} from "../types/routes.types";
import { logoutUser } from "./userActions";

export function getSingleRoute(id) {
  return (dispatch) => {
    var config = {
      method: "GET",
      url: `/api/miroute/route/id=${id}`,
      headers: {},
    };
    dispatch({
      type: GET_SINGLE_ROUTE,
      payload: "Loading",
    });
    axios(config)
      .then(function (response) {
        console.log("esta es la informacion de solo una ruta");
        console.log(response);
        dispatch({
          type: GET_SINGLE_ROUTE_SUCCESS,
          payload: response.data.result,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_SINGLE_ROUTE_FAILED,
          payload: error,
        });
        // logoutUser();
        console.log(error);
      });
  };
}

export const fetchRoutes = () => async (dispatch) => {
  var config = {
    method: "GET",
    url: "/api/miroute/routes",
    headers: {},
  };
  dispatch({
    type: GET_ROUTES,
    payload: "Loading",
  });
  await axios(config)
    .then(function (response) {
      dispatch({
        type: GET_ROUTES_SUCCESS,
        payload: response.data.result,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_ROUTES_FAILED,
        payload: error,
      });
      // logoutUser();
      console.log(error);
    });
};

export function createRoute(newRoute) {
  return (dispatch) => {
    var data = JSON.stringify({
      jsonrpc: "2.0",
      params: newRoute,
    });
    console.log("se envio esto");
    console.log(data);
    var config = {
      method: "POST",
      url: "/api/miroute/routes/new",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    dispatch({ type: CREATE_ROUTE, payload: "Loading" });
    axios(config)
      .then(function (response) {
        dispatch({ type: CREATE_ROUTE_SUCCESS, payload: response.data });
        fetchRoutes();
      })
      .catch(function (error) {
        dispatch({ type: CREATE_ROUTE_FAILED, payload: error });
        console.log(error);
      });
  };
}

export function endCreateRoute() {
  return (dispatch) => {
    dispatch({ type: CREATE_ROUTE_END, payload: "Salir" });
  };
}

export function editRoute(route) {
  return (dispatch) => {
    var data = JSON.stringify({
      jsonrpc: "2.0",
      params: route,
    });
    var config = {
      method: "POST",
      url: "/api/miroute/routes/edit",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    dispatch({ type: EDIT_ROUTE, payload: "Loading" });
    axios(config)
      .then(function (response) {
        dispatch({ type: EDIT_ROUTE_SUCCESS, payload: response.data });
        fetchRoutes();
      })
      .catch(function (error) {
        dispatch({ type: EDIT_ROUTE_FAILED, payload: error });
        console.log(error);
      });
  };
}

export function endEditRoute() {
  return (dispatch) => {
    dispatch({ type: EDIT_ROUTE_END, payload: "Salir" });
  };
}

export function deleteRoute(id) {
  return (dispatch) => {
    var config = {
      method: "GET",
      url: `/api/miroute/routes/delete/${id}`,
    };
    dispatch({ type: DELETE_ROUTE, payload: "Loading" });
    axios(config)
      .then(function (response) {
        setTimeout(() => {
          dispatch({ type: DELETE_ROUTE_SUCCESS, payload: response.data });
          fetchRoutes();
        }, 3000);
      })
      .catch(function (error) {
        dispatch({ type: DELETE_ROUTE_FAILED, payload: error });
        console.log(error);
      });
  };
}
