import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MotionDateTime from "../../../../componets/Motion/MotionDateTime/MotionDateTime";
import MotionSelect from "../../../../componets/Motion/MotionSelect/MotionSelect";
import MotionSelectTest from "../../../../componets/Motion/MotionSelect/MotionSelectTest";
import { getSingleRoutine } from "../../../../redux/actions/routinesActions";
import useRoutine from "../../../../state/hooks/useRoutine";
import {
  RoutineModel,
  RoutineSingleModel,
} from "../../../../state/models/routineModel";
import Header from "./components/Header/Header";
import Schedule from "./components/Schedule/Schedule";
import "./ModalRutinas.scss";

const mapStateToProps = (state) => {
  return {
    single_routine: state.RoutinesReducer.single_routine,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSingleRoutine: (id) => {
    dispatch(getSingleRoutine(id));
  },
});

function ModalRutinas(props) {
  const { routine, setRoutine } = useRoutine(RoutineSingleModel);
  const [passengersAvailable, setPassengersAvailable] = useState([]);
  const [unitsAvailable, setUnitsAvailable] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupsOptions, setGroupsOptions] = useState([]);
  const [selectGroupValue, setSelectGroupValue] = useState(null);

  useEffect(() => {
    if (props.id) props.getSingleRoutine(props.id);
  }, []);

  useEffect(() => {
    if (props.groups) {
      setGroups(props.groups);
      let newGroupsOptions = props.groups.map((group) => {
        return {
          value: group.id,
          label: group.name,
        };
      });
      newGroupsOptions.unshift({ value: "none", label: "Ninguno" });
      setGroupsOptions(newGroupsOptions);
    }
  }, [props.groups]);

  useEffect(() => {
    if (props.single_routine) {
      setRoutine(props.single_routine);
    }
  }, [props.single_routine]);

  useEffect(() => {
    if (routine) {
      checkPassengersAvailable();
      checkUnitsAvailable();
      setGroupRoutine();
    }
  }, [routine]);

  const setGroupRoutine = () => {
    if (routine.group.id) {
      setSelectGroupValue(routine.group.id);
    }
  };

  const checkPassengersAvailable = () => {
    console.log(routine);
    let passengers_option = routine.available_passengers_ids.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    setPassengersAvailable(passengers_option);
  };

  const checkUnitsAvailable = () => {
    setUnitsAvailable(
      routine.available_units.map((unit) => {
        let data_unit = {
          value: unit.id,
          label: unit.name,
        };
        return data_unit;
      })
    );
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setRoutine({
      ...routine,
      [name]: value,
    });
  };

  const handleChangeUnit = (e) => {
    const value = e.target.value;
    const newUnit = routine.available_units.find(
      (unit) => parseInt(unit.id) === parseInt(value)
    );
    if (newUnit)
      setRoutine({
        ...routine,
        unit: newUnit,
      });
  };

  const onChangeGroup = (group) => {
    setSelectGroupValue(group);
    if (group === "none") {
      setRoutine({
        ...routine,
        group: {},
      });
    } else {
      setRoutine({
        ...routine,
        group: groups.find((item) => item.id === group),
      });
    }
  };

  return (
    <div className="ModalRutinas">
      {routine ? (
        <div className="modal-front">
          <Header
            name={routine.name}
            displayEditRoutine={props.displayEditRoutine}
          />
          <div className="body_rutinas column">
            <div className="inputs_row row">
              <div className="routine-item">
                <MotionDateTime
                  label="Seleccione fecha"
                  name="date"
                  value={routine.date}
                  onChange={handleChange}
                />
              </div>
              <div className="routine-item">
                <MotionSelect
                  label="Vehiculo"
                  name="unit"
                  placeholder="Unidad"
                  onChange={handleChangeUnit}
                  value={routine.unit.id}
                  options={unitsAvailable}
                />
              </div>
              <div className="routine-item column">
                <span className="label">Grupo</span>
                <div className="content-select">
                  <MotionSelectTest
                    placeholder="Seleccionar grupo"
                    options={groupsOptions}
                    selectValue={onChangeGroup}
                    valueSelected={selectGroupValue}
                  />
                </div>
              </div>
            </div>
            <Schedule
              passengersAvailable={passengersAvailable}
              passengers={routine.available_passengers_ids}
            />
          </div>
        </div>
      ) : null}
      <div className="modal-back"></div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalRutinas);
