import React, { Component } from "react";
import { connect } from "react-redux";
import "./ModalAddHour.scss";

import { ReactComponent as IcoDelete } from "../../../../../../assets/motion_icon-detenido.svg";
import { ReactComponent as IcoExit } from "../../../../../../assets/motion_icon-salir-cerrar.svg";
import { ReactComponent as IcoCheck } from "../../../../../../assets/check-ico.svg";

class ModalAddHour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: "01:00",
      unit: {},
    };
    this.remove = this.remove.bind(this);
    this.addHour = this.addHour.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeUnit = this.handleChangeUnit.bind(this);
  }

  componentDidMount() {
    if (this.props.time) {
      const { time } = this.props;
      var horas = Math.floor(time);
      var minutos = Math.floor((time - Math.floor(time)) * 60);
      let d1 = horas < 10 ? 0 : Math.floor(horas / 10);
      let d2 =
        horas >= 10
          ? Math.round((horas / 10 - Math.trunc(horas / 10)) * 10)
          : horas;
      let d3 = minutos < 10 ? 0 : Math.floor(minutos / 10);
      let d4 =
        minutos >= 10
          ? Math.round((minutos / 10 - Math.floor(minutos / 10)) * 10)
          : minutos;
      var endTime = `${d1}${d2}:${d3}${d4}`;
      this.setState({
        time: endTime,
      });
    }
    if (this.props.unit) {
      this.setState({
        unit: this.props.unit,
      });
    }
  }

  handleChange(e) {
    const target = e.target;
    const value = target.value;
    this.setState({
      time: value,
    });
  }

  handleChangeUnit(e) {
    const target = e.target;
    const value = target.value;
    const unit = this.props.units.find(
      (unit) => parseInt(unit.id) === parseInt(value)
    );

    this.setState({
      unit: unit,
    });
  }

  remove() {
    this.props.delete(this.props.index);
    this.props.close();
  }

  addHour() {
    const { time } = this.state;
    const twice = time.split(":");
    const hour = parseFloat(twice[0]);
    const minute = parseFloat(twice[1]) / 60;
    const endTime = hour + minute;

    this.props.check(endTime, this.props.index, this.state.unit);
    this.props.close();
  }

  render() {
    return (
      <div className="ModalAddHour">
        <div className="ModalAddHour-content column">
          <div className="ModalAddHour-header row">
            <IcoDelete
              className={`ico delete ${this.props.delete ? "" : "desactivate"}`}
              onClick={this.props.delete ? this.remove : null}
            />
            <IcoCheck
              className={`ico check ${this.props.check ? "" : "desactivate"}`}
              onClick={this.props.check ? this.addHour : null}
            />
            <IcoExit className="ico exit" onClick={this.props.close} />
          </div>
          <div className="ModalAddHour-body column">
            <input
              name=""
              id=""
              className="input-select"
              type="time"
              onChange={this.handleChange}
              value={this.state.time}
            />
            <select
              name=""
              id=""
              className="input-select"
              onChange={this.handleChangeUnit}
              value={this.state.unit.id}
            >
              <option key="unidadRef" value="">
                Unidad
              </option>
              {this.props.units.map((unit) => {
                return (
                  <option key={unit.id} value={unit.id}>
                    {unit.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddHour);
