import React, { Component } from "react";
import PropTypes from "prop-types";

import { List } from "immutable";



import MarkerGroup from "./MarkerGroup";
import MarkerCounter from "./MarkerCounter";




export class ClusterMarker extends Component {
  constructor(){
    super();
    this.state = {

    }
    this.handleClick= this.handleClick.bind(this);
  }
    
  //state = {
  //points: [this.props.visibleVehicles.latitud, this.props.visibleVehicles.longitud],
  // clusterFaceMarkers: this.state.points.slice(0, 2),
  //};
  handleClick(){
    var zoom = Math.round((this.props.$geoService.transform_._zoom ) + 2)
    this.props.setCenterMap([this.props.lat,this.props.lng]);
    this.props.setZoomMap(zoom)
  }
  render() {
    return (
        <MarkerGroup length={this.props.points.length} onClick={this.handleClick}>
          <MarkerCounter>
            {this.props.points.length}
          </MarkerCounter>
      </MarkerGroup>
    )
  }
}

ClusterMarker.propTypes = {
  points: PropTypes.array,
  users: PropTypes.instanceOf(List),
  selected: PropTypes.bool,
};

export default ClusterMarker;
