import React, { useState, useEffect } from "react";
import MotionSelect from "../../../../../../../../componets/Motion/MotionSelect/MotionSelect";
import useRoutine from "../../../../../../../../state/hooks/useRoutine";
import { ScheduleLineModel } from "../../../../../../../../state/models/routineModel";
import "./ScheduleLine.scss";

export default function ScheduleLine(props) {
  const { routine, setRoutine } = useRoutine();
  const [scheduleLine, setScheduleLine] = useState(ScheduleLineModel);
  const [lineTime, setLineTime] = useState({
    index: 0,
    d1: 0,
    d2: 0,
    d3: 0,
    d4: 0,
  });
  const [passengersValue, setPassengersValue] = useState([]);

  useEffect(() => {
    if (props.arrival) formatArrival();
  }, [props.arrival]);

  useEffect(() => {
    setScheduleLine(props.schedule_line);
  }, [props.schedule_line]);

  useEffect(() => {
    setPassengerValue();
  }, [scheduleLine]);

  const setPassengerValue = () => {
    let newPassengersValue = [];
    newPassengersValue = scheduleLine.passengers.map((passenger) => {
      let data_passenger = {
        value: passenger.id,
        label: passenger.name,
      };
      return data_passenger;
    });
    setPassengersValue(newPassengersValue);
  };

  const formatArrival = () => {
    const indextime = props.index;
    const time = props.arrival;
    var horas = Math.floor(time);
    var minutos = Math.floor((time - Math.floor(time)) * 60);

    let d1 = horas < 10 ? 0 : Math.floor(horas / 10);
    let d2 =
      horas >= 10
        ? Math.round((horas / 10 - Math.trunc(horas / 10)) * 10)
        : horas;
    let d3 = minutos < 10 ? 0 : Math.floor(minutos / 10);
    let d4 =
      minutos >= 10
        ? Math.round((minutos / 10 - Math.floor(minutos / 10)) * 10)
        : minutos;
    setLineTime({
      index: indextime,
      d1: d1,
      d2: d2,
      d3: d3,
      d4: d4,
    });
  };

  const handleChangeHour = (e) => {
    var charCode = e.which ? e.which : e.keyCode;
    if (!(charCode > 31 && (charCode < 48 || charCode > 57))) {
      const { indexinterval, indextime, ds } = e.target.attributes;
      let dsHour = Math.floor(parseInt(ds.value) / 100);
      let dsMinute = Math.floor(
        (parseInt(ds.value) / 100 - Math.floor(parseInt(ds.value) / 100)) * 100
      );

      const { name } = e.target;
      const { key } = e;

      let newHour = dsHour.toString().split("");
      let newMinute = dsMinute.toString().split("");

      if (name === "d1") {
        if (key <= 2) {
          if (dsHour < 10) {
            dsHour = parseInt(key + 0);
          } else {
            dsHour = parseInt(key + newHour[1]);
          }
        }
      } else if (name === "d2") {
        if (dsHour < 20 && dsHour >= 0 && key < 10) {
          if (dsHour < 10) {
            dsHour = parseInt(0 + key);
          } else {
            dsHour = parseInt(newHour[0] + key);
          }
        } else if (dsHour >= 20 && dsHour < 25 && key < 5) {
          dsHour = parseInt(newHour[0] + key);
        }
      } else if (name === "d3") {
        if (key < 6) {
          if (dsMinute.toString().length < 1) {
            dsMinute = parseInt(key + newMinute[1]);
          } else {
            dsMinute = parseInt(key + 0);
          }
        }
      } else if (name === "d4") {
        if (dsMinute < 60) {
          if (dsMinute.toString().length < 1) {
            dsMinute = parseInt(newMinute[0] + key);
          } else {
            dsMinute = parseInt(newMinute[0] + key);
          }
        }
      }
      const endTime = parseInt(dsHour) + dsMinute / 60;

      let schedules = [...routine.schedules];
      schedules[props.index] = { ...scheduleLine, arrival: endTime };

      setRoutine({
        ...routine,
        schedules: schedules,
      });
    }
  };

  const handleChangePassanger = (passengersSelected) => {
    let newPassengers = [];
    let schedules = [...routine.schedules];
    passengersSelected.map((passenger) => {
      const newPassenger = props.passengers.find(
        (passengerAvailable) => passenger.value === passengerAvailable.id
      );
      if (newPassenger) newPassengers.push(newPassenger);
    });

    schedules[props.index] = { ...scheduleLine, passengers: newPassengers };
    setRoutine({
      ...routine,
      schedules: schedules,
    });
  };

  return (
    <div className="ScheduleLineRoutine">
      <div
        className={`row content-hour ${
          lineTime.indextime === 0 ? "focus" : ""
        }`}
      >
        <div className="row">
          <input
            className="item-text item-hour"
            type="text"
            value={lineTime.d1}
            name="d1"
            ds={`${lineTime.d1}${lineTime.d2}${lineTime.d3}${lineTime.d4}`}
            indextime={lineTime.indextime}
            onKeyPress={handleChangeHour}
            readOnly
          />
          <input
            className="item-text item-hour"
            type="text"
            value={lineTime.d2}
            name="d2"
            ds={`${lineTime.d1}${lineTime.d2}${lineTime.d3}${lineTime.d4}`}
            indextime={lineTime.indextime}
            onKeyPress={handleChangeHour}
            readOnly
          />
          <div className="item-hour">:</div>
          <input
            className="item-text item-hour"
            type="text"
            value={lineTime.d3}
            name="d3"
            ds={`${lineTime.d1}${lineTime.d2}${lineTime.d3}${lineTime.d4}`}
            indextime={lineTime.indextime}
            onKeyPress={handleChangeHour}
            readOnly
          />
          <input
            className="item-text item-hour"
            type="text"
            value={lineTime.d4}
            name="d4"
            ds={`${lineTime.d1}${lineTime.d2}${lineTime.d3}${lineTime.d4}`}
            indextime={lineTime.indextime}
            onKeyPress={handleChangeHour}
            readOnly
          />
        </div>

        <div>{scheduleLine.stop_name}</div>
        <MotionSelect
          name="passengers"
          onChange={handleChangePassanger}
          value={passengersValue}
          options={props.passengersAvailable}
          multi
        />
      </div>
    </div>
  );
}
