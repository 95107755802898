import axios from "axios";
import {
  GET_GROUPS,
  GET_GROUPS_FAILED,
  GET_GROUPS_SUCCESS,
  POST_GROUPS,
  POST_GROUPS_FAILED,
  POST_GROUPS_SUCCESS,
} from "../types/groups.types";

export function getGroups(id) {
  return (dispatch) => {
    var config = {
      method: "GET",
      url: `/api/miroute/groups`,
      headers: {},
    };
    dispatch({
      type: GET_GROUPS,
      payload: "Loading",
    });
    axios(config)
      .then(function (response) {
        console.log("ESTOS SON LO GRUPOS", response);
        dispatch({
          type: GET_GROUPS_SUCCESS,
          payload: response.data.result,
        });
      })
      .catch(function (error) {
        dispatch({
          type: GET_GROUPS_FAILED,
          payload: error,
        });
      });
  };
}

export function postGroups(params) {
  return (dispatch) => {
    console.log(params);
    var body = JSON.stringify({
      jsonrpc: "2.0",
      params: params,
    });
    var config = {
      method: "POST",
      url: `/api/miroute/groups/new`,
      headers: {
        "Content-Type": "application/json",
      },
      data: body,
    };
    dispatch({
      type: POST_GROUPS,
      payload: "Loading",
    });
    axios(config)
      .then(function (response) {
        dispatch({
          type: POST_GROUPS_SUCCESS,
          payload: response.data.result,
        });
      })
      .catch(function (error) {
        dispatch({
          type: POST_GROUPS_FAILED,
          payload: error,
        });
      });
  };
}
