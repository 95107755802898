import {
  CREATE_ROUTE,
  CREATE_ROUTE_END,
  CREATE_ROUTE_FAILED,
  CREATE_ROUTE_SUCCESS,
  DELETE_ROUTE,
  DELETE_ROUTE_END,
  DELETE_ROUTE_FAILED,
  DELETE_ROUTE_SUCCESS,
  EDIT_ROUTE,
  EDIT_ROUTE_END,
  EDIT_ROUTE_FAILED,
  EDIT_ROUTE_SUCCESS,
  GET_ROUTES,
  GET_ROUTES_FAILED,
  GET_ROUTES_SUCCESS,
  GET_SINGLE_ROUTE,
  GET_SINGLE_ROUTE_FAILED,
  GET_SINGLE_ROUTE_SUCCESS,
} from "../types/routes.types";

const initialState = {
  isLoading: true,
  errMess: null,
  routes: [],
  single_route: false,
};

const RoutesReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_ROUTES:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ROUTES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        routes: action.payload,
      };
    case GET_ROUTES_FAILED:
      return {
        ...state,
        isLoading: false,
        errMess: action.payload,
      };
    case GET_SINGLE_ROUTE:
      return {
        ...state,
        isLoading: true,
      };
    case GET_SINGLE_ROUTE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        single_route: action.payload,
      };
    case GET_SINGLE_ROUTE_FAILED:
      return {
        ...state,
        isLoading: false,
        errMess: action.payload,
      };
    case CREATE_ROUTE:
      return {
        ...state,
        isLoadingCreate: true,
        created: false,
        errorCreated: false,
      };

    case CREATE_ROUTE_SUCCESS:
      return {
        ...state,
        isLoadingCreate: false,
        created: true,
        errorCreated: false,
      };

    case CREATE_ROUTE_FAILED:
      return {
        ...state,
        isLoadingCreate: false,
        created: false,
        errorCreated: true,
      };

    case CREATE_ROUTE_END:
      return {
        ...state,
        isLoadingCreate: false,
        created: false,
        errorCreated: false,
      };

    case EDIT_ROUTE:
      return {
        ...state,
        isLoadingEdit: true,
        edited: false,
        errorEdited: false,
      };

    case EDIT_ROUTE_SUCCESS:
      return {
        ...state,
        isLoadingEdit: false,
        edited: true,
        errorEdited: false,
      };

    case EDIT_ROUTE_FAILED:
      return {
        ...state,
        isLoadingEdit: false,
        edited: false,
        errorEdited: true,
      };

    case EDIT_ROUTE_END:
      return {
        ...state,
        isLoadingEdit: false,
        edited: false,
        errorEdited: false,
      };

    case DELETE_ROUTE:
      return {
        ...state,
        isLoadingDelete: true,
        delete: false,
        errorDelete: false,
      };

    case DELETE_ROUTE_SUCCESS:
      return {
        ...state,
        isLoadingDelete: false,
        delete: true,
        errorDelete: false,
      };

    case DELETE_ROUTE_FAILED:
      return {
        ...state,
        isLoadingDelete: false,
        delete: false,
        errorDelete: true,
      };

    case DELETE_ROUTE_END:
      return {
        ...state,
        isLoadingDelete: false,
        delete: false,
        errorDelete: false,
      };

    default:
      return state;
  }
};

export default RoutesReducer;
