import React, { Component } from "react";
import "./Pasajeros.scss";
import * as XLSX from "xlsx";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import CardPasajeros from "./components/CardPasajeros/CardPasajeros";
import ModalCreated from "../../componets/ModalCreated/ModalCreated";
import ExcelPassengers from "./components/ExcelPassengers/ExcelPassengers";
import {
  fetchPassengers,
  getPassengersTemplate,
} from "../../redux/actions/passengersActions";
import { ReactComponent as CargarIco } from "../../assets/CargarIco.svg";

const mapStateToProps = (state) => {
  return {
    passengersReducer: state.PassengerReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchPassengers: () => {
    dispatch(fetchPassengers());
  },
  getPassengersTemplate: () => {
    dispatch(getPassengersTemplate());
  },
});

class Pasajeros extends Component {
  constructor() {
    super();
    this.state = {
      showModalAdd: false,
      passengers: [],
      showEditRoute: false,
      selectedRoute: null,
      hojas: null,
      searchParams: ["name", "email"],
      q: "",
    };
    this.filter = this.filter.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.closeExcel = this.closeExcel.bind(this);
  }

  componentDidMount() {
    this.props.fetchPassengers();
    this.props.selectItemNav("Pasajeros");
    this.setElementNav();
    this.setState({
      passengers: this.props.passengersReducer.passengers,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.passengersReducer !== this.props.passengersReducer)
      this.setState({
        passengers: this.props.passengersReducer.passengers,
      });
  }

  handleModal() {
    this.setState({
      showModalAdd: !this.state.showModalAdd,
    });
  }

  onDataChange = (value, action) => {
    var values = value != null ? value.map((item) => item.value) : null;
    this.setState({
      rutas: {
        ...this.state.rutas,
        [action.name]: values,
      },
    });
  };

  filter(event) {
    var search = event.target.value;
    const data = this.props.passengersReducer.passengers;
    const searchParam = this.state.searchParams;
    const newData = data.filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem].toString().toLowerCase().indexOf(search.toLowerCase()) >
          -1
        );
      });
    });
    this.setState({
      passengers: newData,
    });
  }

  handleFileChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const self = this;
    this.setState({
      [name]: value,
    });
    let hojas = [];
    if (name === "file") {
      let reader = new FileReader();
      if (target.files[0]) {
        reader.readAsArrayBuffer(target.files[0]);
        reader.onloadend = (e) => {
          var data = new Uint8Array(e.target.result);
          var workbook = XLSX.read(data, { type: "array" });
          workbook.SheetNames.forEach(function (SheetName) {
            var XL_row_object = XLSX.utils.sheet_to_row_object_array(
              workbook.Sheets[SheetName]
            );
            if (
              !XL_row_object[0].name &&
              !XL_row_object[0].user &&
              !XL_row_object[0].password
            ) {
              alert(
                "Su archivo no contiene campo de nombre, email o password, por favor agregarlo"
              );
              target.value = "";
            } else {
              hojas.push({
                data: XL_row_object,
                SheetName,
              });
            }
          });
          if (hojas.length > 0) {
            self.setState({
              selectedFileDocument: target.files[0],
              hojas: hojas,
            });
          }
        };
      }
    }
  }

  getTemplatePassengers() {
    this.props.getPassengersTemplate();
  }

  closeExcel() {
    this.setState({
      hojas: null,
    });
  }

  setElementNav() {
    const element = (
      <div
        className="row"
        style={{ marginLeft: "20px", gap: "10px", alignItems: "center" }}
      >
        <div className="pasajeros-list-header-row2">
          <label htmlFor="file">
            <CargarIco
              className="ico-upload"
              style={{ width: "20px", height: "20px", cursor: "pointer" }}
            />
          </label>
          <input
            className="pasajeros-list-header-selecFile"
            type="file"
            name="file"
            id="file"
            onChange={this.handleFileChange}
            accept="file/xlsx"
            style={{ display: "none" }}
          />
        </div>
        <div className="pasajeros-list-header-row2">
          <CargarIco
            className="ico-download"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={this.props.getPassengersTemplate}
          />
        </div>
        <button
          onClick={this.handleModal}
          className="row btn-crear-parada btn2"
          style={{ cursor: "pointer" }}
        >
          Crear pasajero
          <div className="paradas-content-lines">
            <div className="paradas-line line1"></div>
            <div className="paradas-line line2"></div>
          </div>
        </button>
        <div className="pasajeros-list-header-row2">
          <div className="rutas-header-filter">
            <input
              onChange={this.filter}
              type="search"
              name=""
              id=""
              className="input-search-clientes"
            />
          </div>
        </div>
      </div>
    );
    this.props.setElementNav(element);
  }

  componentWillUnmount() {
    this.props.setElementNav();
  }

  render() {
    return (
      <div className="main pasajeros">
        <div>
          <ul className="Pasajeros">
            {this.state.hojas ? (
              <ExcelPassengers
                passengers={this.state.hojas[0].data}
                closeExcel={this.closeExcel}
              />
            ) : null}
            {this.state.showModalAdd ? (
              <ExcelPassengers passengers={[]} closeExcel={this.handleModal} />
            ) : null}
            {this.state.passengers.map((passenger) => {
              console.log(passenger);
              return (
                <CardPasajeros
                  key={passenger.id}
                  id={passenger.id}
                  name={passenger.name}
                  email={passenger.email}
                  userImage={passenger.image}
                  updated={passenger.updated}
                />
              );
            })}
          </ul>
        </div>
        {this.props.passengersReducer.isLoadingCreate ? (
          <ModalCreated loading={true} />
        ) : this.props.passengersReducer.errorCreated ? (
          <ModalCreated
            error={true}
            title={"Ups, ocurrio un error en la creacion del pasajero"}
            exit={this.exitCreated}
          />
        ) : this.props.passengersReducer.created ? (
          <ModalCreated
            succes={true}
            title={"Pasajero creado con éxito!"}
            exit={this.exitCreated}
          />
        ) : null}
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Pasajeros)
);
