export const GeofenceModel = {
  id: 0,
  name: "",
  state: "",
  address: "",
  center: "",
  polygon: "",
  color: "",
  type: "",
  stop_type: "",
  num_routes: 0,
};
