import { combineReducers } from "redux";

import StopsReducer from "./stopsReducer";
import LoginReducer from "./authReducer";
import UnitsReducer from "./unitsRedcuer";
import MetricsReducer from "./metricsReducer";
import ImgsReducer from "./imgReducer";
import RoutesReducer from "./routesReducer";
import RoutinesReducer from "./routinesReducer";
import UserReducer from "./userReducer";
import PassengerReducer from "./passengerReducer";
import GeofieldsReducer from "./geofieldsReducer";
import GroupsReducer from "./groupsReducer";

const RootReducer = combineReducers({
  stops: StopsReducer,
  login: LoginReducer,
  UnitsReducer: UnitsReducer,
  reducer_metric_data: MetricsReducer,
  ImgsReducer: ImgsReducer,
  RoutesReducer: RoutesReducer,
  RoutinesReducer: RoutinesReducer,
  UserReducer: UserReducer,
  PassengerReducer: PassengerReducer,
  GeofieldsReducer: GeofieldsReducer,
  GroupsReducer: GroupsReducer,
});

export default RootReducer;
