import React from "react";
import RutinasCard from "../RutinasCard/RutinasCard";
import "./RutinasContent.scss";

export default function RutinasContent(props) {
  return (
    <div className="RutinasContent">
      <div className="column-routines column scheduled">
        <div className="desc-column">
          <div className="sub-title-col">NO</div>
          <div className="title-col">INICIADO</div>
        </div>
        <div className="column-routines-cards column">
          {props.routines.map((routine) => {
            if (routine.status === "scheduled")
              return (
                <RutinasCard
                  key={routine.id}
                  id={routine.id}
                  routine={routine}
                  displayEditRoutine={props.displayEditRoutine}
                />
              );
          })}
        </div>
      </div>
      <div className="column-routines column running">
        <div className="desc-column">
          <div className="sub-title-col">EN</div>
          <div className="title-col">PROCESO</div>
        </div>
        <div className="column-routines-cards column">
          {props.routines.map((routine) => {
            if (routine.status === "running")
              return (
                <RutinasCard
                  key={routine.id}
                  id={routine.id}
                  routine={routine}
                  displayEditRoutine={props.displayEditRoutine}
                />
              );
          })}
        </div>
      </div>
      <div className="column-routines column completed">
        <div className="desc-column">
          <div className="sub-title-col">-</div>
          <div className="title-col">FINALIZADO</div>
        </div>
        <div className="column-routines-cards column">
          {props.routines.map((routine) => {
            if (routine.status === "completed")
              return (
                <RutinasCard
                  key={routine.id}
                  id={routine.id}
                  routine={routine}
                  displayEditRoutine={props.displayEditRoutine}
                />
              );
          })}
        </div>
      </div>
      <div className="column-routines column discarded">
        <div className="desc-column">
          <div className="sub-title-col">SIN</div>
          <div className="title-col">FINALIZAR</div>
        </div>
        <div className="column-routines-cards column">
          {props.routines.map((routine) => {
            if (routine.status === "discarded")
              return (
                <RutinasCard
                  key={routine.id}
                  id={routine.id}
                  routine={routine}
                  displayEditRoutine={props.displayEditRoutine}
                />
              );
          })}
        </div>
      </div>
    </div>
  );
}
