import React from "react";
import "./NotFound.scss";
import ico404 from "./assets/motion_icon-404.svg";

export default function NotFound(props) {
  return (
    <div className="NotFound">
      <div className="NotFound-image">
        <img
          src={props.image ? props.image : ico404}
          className="ico-found"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = ico404;
          }}
        />
      </div>
      <div className="NotFound-content column">
        <div className="NotFound-title">{props.title}</div>
        <div className="NotFound-description">{props.description}</div>
      </div>
      <div className="NotFound-button">{props.button}</div>
    </div>
  );
}
