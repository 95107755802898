import React, { Component } from "react";
import { Link } from "react-router-dom";
import ChartParadas from "./components/ChartParadas/ChartParadas";

import "./Paradas.scss";

class Paradas extends Component {
  render() {
    return (
      <div className="column resumen-paradas">
        <div className="row resumen-paradas-item1-column">
          <div>
            <Link to="/paradas">
              <div className="blue2 title">Paradas</div>
            </Link>
          </div>
        </div>
        <div
          className="row resumen-paradas-chart-column"
          style={{ width: "100%", height: "100%" }}
        >
          <ChartParadas />
        </div>
        <div className="row resumen-paradas-item2-column">
          <div className="sub-title-content column">
            <div className="row ">
              <div className="gray1 sub-title">
                Total <span>935</span>
              </div>
            </div>
            <div className="row ">
              <div className="gray3 sub-title">
                Ninguna <span>45</span>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="row">
              <div className="blue2 text">
                Buses <span>439</span>
              </div>
            </div>
            <div className="row">
              <div className="sky2 text">
                Colectivos <span>204</span>
              </div>
            </div>
            <div className="row">
              <div className="red2 text">
                Microvans <span>291</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Paradas;
