import styled from "styled-components";

const MarkerCounter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 14px;
  color: #fff;
  border-radius: 50%;
  font-weight: bold;
`;

export default MarkerCounter;
