export const RouteModel = {
  id: "",
  name: "",
  number: "",
  units: [],
  stop_ids: [], //ids de paradas
  origin: {
    type: "",
    coordinates: [],
  },
  destiny: {
    type: "",
    coordinates: [],
  },
  type: "",
  auto_routines: false, //Crear rutinas automaticamente
  del_routines_completed: false, //Eliminar rutinas completadas
  checkpoints_order: "strict",
  paste_last_point: false,
  passengers: [], //ids de pasajeros
  schedules: [],
  schedule: {
    schedule_type: "activation",
    //relativo al dia
    relative_day_type: "weekly", //tipo de relatividad
    week_day_ids: [], //ids de dias de la semana
    day_number_ids: [], //dias del mes
    month_ids: [], //ids de meses
    //relativo a la activacion
    activator_geofence: "", //Geocerca de activacion
    activator_geofence_type: "inbound", //Tipo de geocerca, fuera o dentro
    //absoluta
    absolute_arrival: "", //Date time de llegada,
    time_interval_ids: [], //arreglo de time_interval,
  },
};

export const time_interval = {
  start_arrival: "", //float de llegada
  end_arrival: "", //float de salida
  unit: "", //unidad
  schedule_lines_ids: [], //arreglo de schedule_line,
};

export const schedule_line = {
  arrival: "", //float de llegada
  stop_id: "", //Id de parada
};
