export const GET_STOPS_LIST = "GET_STOPS_LIST";
export const GET_STOPS_LIST_SUCCCESS = "GET_STOPS_LIST_SUCCCESS";
export const GET_STOPS_LIST_FAILED = "GET_STOPS_LIST_FAILED";
export const GET_STOPS_LIST_UPDATE = "GET_STOPS_LIST_UPDATE";

export const CREATE_STOP = "CREATE_STOP";
export const CREATE_STOP_SUCCESS = "CREATE_STOP_SUCCESS";
export const CREATE_STOP_FALED = "CREATE_STOP_FALED";
export const END_CREATE_STOP = "END_CREATE_STOP";

export const DELETE_STOP = "DELETE_STOP";
export const DELETE_STOP_SUCCESS = "DELETE_STOP_SUCCESS";
export const DELETE_STOP_FALED = "DELETE_STOP_FALED";
export const END_DELETE_STOP = "END_DELETE_STOP";

export const CREATE_MANY_STOP = "CREATE_MANY_STOP";
