import axios from "axios";
import {
  GET_METRIC_DATA,
  GET_METRIC_DATA_FAILED,
  GET_METRIC_DATA_SUCCESS,
} from "../types/metrics.types";

export const fetchMetricData = () => async (dispatch) => {
  var config = {
    method: "GET",
    url: "/api/miroute/metrics",
    headers: {},
  };
  dispatch({
    type: GET_METRIC_DATA,
    payload: "Loading",
  });
  await axios(config)
    .then(function (response) {
      console.log(response);
      dispatch({
        type: GET_METRIC_DATA_SUCCESS,
        payload: response.data.result,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_METRIC_DATA_FAILED,
        payload: error,
      });
      // logoutUser();
      console.log(error);
    });
};
