import React, { Component } from "react";

import { ReactComponent as MarkerIco } from "../../assets/motion_icon-marcador-geocerca.svg";

export default class Marker extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.setCenterMap([this.props.lat, this.props.lng]);
    this.props.setZoomMap(17);
  }

  render() {
    return (
      <div onClick={this.handleClick} style={{ cursor: "pointer" }}>
        <MarkerIco
          className={`paradas-map-marker ${
            this.props.focusMarker === this.props.id ? "focus" : ""
          }`}
        />
      </div>
    );
  }
}
