import React, { useState } from "react";
import "./RutinasMiniCard.scss";
import { ReactComponent as IcoUbicacion } from "../../assets/motion_icon-ubicacion.svg";

export default function RutinasMiniCard(props) {
  const [display_stop, setDisplay_stop] = useState(false);
  return (
    <div className="RutinasMiniCard">
      <div className="row content-icons">
        {props.sequence !== 0 ? (
          <div className="ico-item">
            <div className="point-pogress"></div>
          </div>
        ) : null}
        <div
          className="ico-item"
          onMouseOver={() => setDisplay_stop(true)}
          onMouseLeave={() => setDisplay_stop(false)}
        >
          <IcoUbicacion className="ico-stop" />
        </div>
      </div>
      {display_stop ? (
        <div className="row content-stop">
          <div className="stop-title">{props.stop_name}</div>
        </div>
      ) : null}
    </div>
  );
}
