import axios from "axios";
import {
  GET_GEOFIELDS,
  GET_GEOFIELDS_FAILED,
  GET_GEOFIELDS_SUCCCESS,
} from "../types/geofields.types";

export const fetchGeofields = () => async (dispatch) => {
  var config = {
    method: "GET",
    url: "/api/miroute/geofields",
    headers: {},
  };
  dispatch({
    type: GET_GEOFIELDS,
    payload: "Loading",
  });
  await axios(config)
    .then(function (response) {
      const newResult = response.data.result.map((item) => {
        let result = {
          ...item,
          center: JSON.parse(item.center),
          polygon: JSON.parse(item.polygon),
          id_d: `item-${item.id}`,
        };
        return result;
      });
      dispatch({
        type: GET_GEOFIELDS_SUCCCESS,
        payload: newResult,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_GEOFIELDS_FAILED,
        payload: error,
      });
      console.log("osea aca no entro o si?");
    });
};
