import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./Rutas.scss";

class Rutas extends Component {
  render() {
    return (
      <div className="column resumen-rutas">
        <div className="resumen-title1">
          <Link to="/rutas">Rutas</Link>
        </div>
        <div className="row resumen-group-items">
          <div className="column resumen-item-column blue2">
            <div className="resumen-item-num">1197</div>
            <p>Total</p>
          </div>
          <div className="column resumen-item-column gray2">
            <div className="resumen-item-num">184</div>
            <p>Buses</p>
          </div>
          <div className="column resumen-item-column gray2">
            <div className="resumen-item-num">262</div>
            <p>Microvans</p>
          </div>
          <div className="column resumen-item-column gray2">
            <div className="resumen-item-num">122</div>
            <p>Colectivos</p>
          </div>
          <div className="column resumen-item-column gray4">
            <div className="resumen-item-num">85</div>
            <p>Inactivos</p>
          </div>
          <div className="column resumen-item-column sky1">
            <div className="resumen-item-num">408</div>
            <p>Con Horario</p>
          </div>
          <div className="column resumen-item-column red1">
            <div className="resumen-item-num">136</div>
            <p>Sin Horario</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Rutas;
