import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ReactComponent as IcoDrag } from "../../../../../../assets/motion_icon-drag.svg";
import { ReactComponent as IcoExit } from "../../../../../../assets/motion_icon-detenido.svg";
import "./AddStops.scss";
import useRoute from "../../../../../../state/hooks/useRoute";
import { fetchGeofields } from "../../../../../../redux/actions/geofieldsActions";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    GeofieldsReducer: state.GeofieldsReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchGeofields: () => {
    dispatch(fetchGeofields());
  },
});

function AddStops(props) {
  const { newRoute, setRoute } = useRoute();
  const [unitsTypes, setUnitsTypes] = useState([]);
  const [entryStops, setEntryStops] = useState([]);
  const [stopsFiltered, setStopsFiltered] = useState([]);
  const [exitStops, setExitStops] = useState([]);
  const [geoAllows, setGeoAllows] = useState([]);
  const [geoExit, setGeoExit] = useState(null);
  const [maps, setmaps] = useState(null);

  useEffect(() => {
    setUnitsTypes(props.unitsTypes);
  }, [props.unitsTypes]);

  useEffect(() => {
    setEntryStops(props.entryStops);
    const activators = props.entryStops.filter(
      (stop) => stop.stop_type === "activator"
    );
    setGeoAllows(activators);
  }, [props.entryStops]);

  useEffect(() => {
    setExitStops(props.exitStops);
    props.fetchGeofields();
  }, []);

  useEffect(() => {
    setmaps(props.maps);
  }, [props.maps]);

  useEffect(() => {
    // setGeoAllows(props.GeofieldsReducer.geofields);
  }, [props.GeofieldsReducer]);

  useEffect(() => {
    props.changeExitStops(exitStops);
    let json_stops = exitStops.map((stop, index) => {
      let data = { stop_id: stop.id, sequence: index };
      return data;
    });
    setRoute({
      ...newRoute,
      stop_ids: json_stops,
    });
  }, [exitStops]);

  useEffect(() => {
    if (geoExit)
      setEntryStops(entryStops.filter((stop) => stop.id !== geoExit.id));
    else setEntryStops(props.entryStops);
  }, [geoExit]);

  const filter = (e) => {
    var stop = e.target.value;
    const newData = entryStops.filter(function (item) {
      const itemData = item.name.toUpperCase();
      const stopData = stop.toUpperCase();

      return itemData.indexOf(stopData) > -1;
    });
    setStopsFiltered(newData);
  };

  const clearFilter = (id) => {
    const data = stopsFiltered;
    const newData = data.filter((item) => {
      return item.id !== id;
    });
    setStopsFiltered(newData);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newExitStops = reorder(
      exitStops,
      result.source.index,
      result.destination.index
    );

    setExitStops(newExitStops);
  };

  const pushEntryStops = (id) => {
    const data = entryStops;
    const newData = data.filter((item) => {
      return item.id === id;
    });

    const newDataEntry = data.filter((e) => {
      return e.id !== id;
    });
    if (newRoute.schedule.schedule_type === "activation") {
      if (geoExit) {
        setEntryStops(newDataEntry);
        setExitStops([...exitStops, ...newData]);
      }
    } else {
      setEntryStops(newDataEntry);
      setExitStops([...exitStops, ...newData]);
    }
    clearFilter(id);
  };

  const pushExitStops = (id) => {
    const data = exitStops;
    const newData = data.filter((item) => {
      return item.id === id;
    });
    const newDataEntry = data.filter((e) => {
      return e.id !== id;
    });

    setEntryStops([...entryStops, ...newData]);
    setExitStops(newDataEntry);
  };

  const pushAllowGeo = (value) => {
    setRoute({
      ...newRoute,
      schedule: { ...newRoute.schedule, activator_geofence: value },
    });
    setGeoExit(value);
    setGeoAllows(geoAllows.filter((stop) => stop.id !== value.id));
  };

  const pushExitGeo = (value) => {
    setRoute({
      ...newRoute,
      schedule: { ...newRoute.schedule, activator_geofence: "" },
    });
    setGeoExit(null);
    setGeoAllows([...geoAllows, value]);
  };

  return (
    <div className="column AddStops">
      <div className="rutas-modal-add-content-form-item">
        <div className="rutas-modal-add-content-form-item-entry column">
          <div className="form-item-entry-header column">
            <select
              name="type"
              className="entry-header-select"
              onChange={props.selectUnitType}
              value={props.newRoute.type}
            >
              <option value="vehiculo">Clase de vehiculo</option>
              {unitsTypes.map((unit_type) => {
                return (
                  <option value={unit_type.id} key={unit_type.id}>
                    {unit_type.name}
                  </option>
                );
              })}
            </select>
            <input
              type="search"
              name=""
              id=""
              placeholder="Buscar"
              className="entry-header-search"
              onChange={filter}
            />
          </div>
          <div className="rutas-modal-add-content-form-item-entry-stop-scroll column">
            {newRoute.schedule.schedule_type === "activation" &&
            geoAllows.length > 0 &&
            !geoExit ? (
              <div className="rutas-modal-add-content-form-item-entry-stop-content column">
                <div>Geocerca activadora</div>
                {geoAllows.map((item) => {
                  return (
                    <div
                      className="rutas-modal-add-content-form-item-entry-stop"
                      key={item.id}
                      onClick={() => pushAllowGeo(item)}
                    >
                      <div className="entry-stop-content row">
                        <div className="row paradas-content-text">
                          <div className="text">{item.name}</div>
                        </div>
                        <div className="paradas-content-lines">
                          <div className="paradas-line line1"></div>
                          <div className="paradas-line line2"></div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}

            <div className="rutas-modal-add-content-form-item-entry-stop-content column">
              <div>Paradas</div>
              {stopsFiltered.length > 0
                ? stopsFiltered.map((item) => {
                    return (
                      <div
                        className="rutas-modal-add-content-form-item-entry-stop"
                        key={item.id}
                        onClick={() => pushEntryStops(item.id)}
                      >
                        <div className="entry-stop-content row">
                          <div className="row paradas-content-text">
                            <div className="text">{item.name}</div>
                          </div>
                          <div className="paradas-content-lines">
                            <div className="paradas-line line1"></div>
                            <div className="paradas-line line2"></div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : entryStops.map((item) => {
                    return (
                      <div
                        className="rutas-modal-add-content-form-item-entry-stop"
                        key={item.id}
                        onClick={() => pushEntryStops(item.id)}
                      >
                        <div className="entry-stop-content row">
                          <div className="row paradas-content-text">
                            <div className="text">{item.name}</div>
                          </div>
                          <div className="paradas-content-lines">
                            <div className="paradas-line line1"></div>
                            <div className="paradas-line line2"></div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
        <div className="rutas-modal-add-content-form-item-exit column">
          <div>
            {geoExit ? (
              <div>
                <div>Geocerca activadora</div>
                <div
                  className="rutas-modal-add-content-form-item-exit-stop"
                  onClick={() => pushExitGeo(geoExit)}
                >
                  <div className="row paradas-content-text">
                    <div className="row paradas-content-1">
                      <div className="row" style={{ gap: "5px" }}>
                        <div className="text" style={{ width: "15px" }}>
                          {geoExit.id}
                        </div>
                      </div>
                      <div className="text">{geoExit.name}</div>
                    </div>
                    <div className="paradas-content-2">
                      <IcoExit className="icoPLay-exit" />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="stops">
              {(provided, snapshot) => (
                <div
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                  style={{ gap: "15px" }}
                  className="column"
                >
                  <div>Paradas</div>
                  {exitStops.map((item, index) => {
                    return (
                      <Draggable
                        key={item.id}
                        draggableId={item.id_d}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="rutas-modal-add-content-form-item-exit-stop"
                            key={item.id}
                            onClick={() => pushExitStops(item.id)}
                          >
                            <div className="row paradas-content-text">
                              <div className="row paradas-content-1">
                                <div className="row" style={{ gap: "5px" }}>
                                  <div
                                    className="text"
                                    style={{ width: "15px" }}
                                  >
                                    {index}
                                  </div>
                                  <IcoDrag className="icoPLay-drag" />
                                </div>
                                <div className="text">{item.name}</div>
                              </div>
                              <div className="paradas-content-2">
                                <IcoExit className="icoPLay-exit" />
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AddStops);
