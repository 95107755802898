import {
  GET_GEOFIELDS,
  GET_GEOFIELDS_FAILED,
  GET_GEOFIELDS_SUCCCESS,
} from "../types/geofields.types";

const initialState = {
  isLoading: true,
  errMess: null,
  geofields: [],
};

const GeofieldsReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_GEOFIELDS:
      return { ...state, isLoading: true };
    case GET_GEOFIELDS_SUCCCESS:
      return {
        ...state,
        isLoading: false,
        geofields: action.payload,
      };
    case GET_GEOFIELDS_FAILED:
      return { ...state, isLoading: false, errMess: action.payload };

    default:
      return state;
  }
};

export default GeofieldsReducer;
