import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import { ReactComponent as LogoRoute } from "../../assets/route-control_logo.svg";
import { ReactComponent as IcoMotion } from "../../assets/motion-favicon-web.svg";
import { ReactComponent as IcoParadas } from "../../assets/paradas-ico.svg";
import { ReactComponent as IcoRutinas } from "../../assets/rutinas-ico.svg";
import { ReactComponent as IcoResumen } from "../../assets/resumen-ico.svg";

import "./BottomNav.scss";

class BottomNav extends Component {
  render() {
    const { pathname } = this.props.location;
    return (
      <div className="BottomNav">
        <div className="BottomNav-content row">
          <Link
            to="/"
            className={`nav-item ${pathname === "/" ? "nav-selected" : ""}`}
          >
            <IcoResumen className="seguimiento-ico" />
            {pathname === "/" ? <h2>Resumen</h2> : null}
          </Link>

          <Link
            to="/seguimiento"
            className={`nav-item ${
              pathname === "/seguimiento" ? "nav-selected" : null
            } `}
          >
            <IcoMotion className="seguimiento-ico" />
            {pathname === "/seguimiento" ? <h2>Seguimiento</h2> : null}
          </Link>

          <Link
            to="/rutinas"
            className={`nav-item ${
              pathname === "/rutinas" ? "nav-selected" : ""
            } `}
          >
            <IcoRutinas className="seguimiento-ico" />
            {pathname === "/rutinas" ? <h2>Rutinas</h2> : null}
          </Link>
          <Link
            to="/paradas"
            className={`nav-item ${
              pathname === "/paradas" ? "nav-selected" : ""
            } `}
          >
            <IcoParadas className="seguimiento-ico" />
            {pathname === "/paradas" ? <h2>Paradas</h2> : null}
          </Link>
          <Link
            to="/rutas"
            className={`nav-item ${
              pathname === "/rutas" ? "nav-selected" : ""
            }`}
          >
            <LogoRoute className="route-ico2" />
            {pathname === "/rutas" ? <h2>Rutas</h2> : null}
          </Link>
          {this.props.element1 ? <>{this.props.element1}</> : null}
        </div>
      </div>
    );
  }
}

export default withRouter(BottomNav);
