import React, { useState, useEffect } from "react";
import "./CardList.scss";

import { ReactComponent as RutaIco } from "../../../../assets/rutas-ico.svg";
import { ReactComponent as ConfigIco } from "../../../../assets/motion_icon-config.svg";
import { ReactComponent as DetenidoIco } from "../../../../assets/motion_icon-detenido.svg";
import { GeofenceModel } from "../../../../state/models/stopModel";
import { deleteStop } from "../../../../redux/actions/stopsActions";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  deleteStop: (id) => {
    dispatch(deleteStop(id));
  },
});

function CardList(props) {
  const [stop, setStop] = useState(GeofenceModel);
  const [routesLength, setroutesLength] = useState(false);

  useEffect(() => {
    if (props.stop) formatStop(props.stop);
  }, [props.stop]);

  const formatStop = (stop) => {
    setStop(stop);
    if (stop.num_routes > 0) {
      setroutesLength(true);
    }
  };

  const handleClick = () => {
    props.setCenterMap([props.lat, props.lng]);
    props.setZoomMap(17);
  };

  const onDeleteStop = () => {
    if (!routesLength) {
      props.deleteStop(stop.id);
    }
  };

  return (
    <div
      className="column list-card"
      onMouseOver={() => props.FocusMarker(props.id)}
      onMouseOut={props.unfocusMarker}
    >
      <div className="row card-list-header text-overflow" onClick={handleClick}>
        {stop.name}
      </div>
      <div
        className="row"
        style={{ alignItems: "center", justifyContent: "space-between" }}
      >
        <div className="row ico-cardList-info">
          <RutaIco className="ico-cardList icoRoutes-cardList" />
          <div className="num-info">{stop.num_routes}</div>
        </div>
        <div className="row" style={{ alignItems: "center", gridGap: "15px" }}>
          <ConfigIco className="ico-cardList icoConfig-cardList" />
          <DetenidoIco
            className={`ico-cardList icoDelete-cardList ${
              routesLength ? "desactivate" : ""
            }`}
            onClick={onDeleteStop}
          />
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CardList);
