import React, { Component } from "react";
import "./Rutinas.scss";

import RutinasCard from "./components/RutinasCard/RutinasCard";
import { connect } from "react-redux";
import {
  fetchRoutines,
  updateRoutines,
} from "../../redux/actions/routinesActions";
import NotFound from "../notfound/NotFound";
import rutinasIco from "./assets/rutinas-ico.svg";
import CalendarRoutines from "./components/CalendarRoutines/CalendarRoutines";
import RutinasContent from "./components/RutinasContent/RutinasContent";
import { getGroups } from "../../redux/actions/groupsActions";
import ModalGroupCreate from "../../componets/ModalGroupCreate/ModalGroupCreate";
import MotionSelectTest from "../../componets/Motion/MotionSelect/MotionSelectTest";
import ModalRutinas from "./components/ModalRutinas/ModalRutinas";
import NewRoutineProvider from "../../state/providers/NewRoutine";

const mapStateToProps = (state) => {
  return {
    RoutinesReducer: state.RoutinesReducer,
    GroupsReducer: state.GroupsReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchRoutines: (params) => {
    dispatch(fetchRoutines(params));
  },
  updateRoutines: (params) => {
    dispatch(updateRoutines(params));
  },
  getGroups: () => {
    dispatch(getGroups());
  },
});

const today = new Date();

class Rutinas extends Component {
  constructor() {
    super();
    this.state = {
      routines: [],
      isLoading: true,
      errMess: null,
      showEditRoutine: false,
      timer: null,
      selectDay: today,
      currentDayString: "",
      currentDayNumber: 0,
      displayModalGroup: false,
      groups: [],
      groupsOptions: [],
      selectGroupValue: null,
    };
    this.exitDelete = this.exitDelete.bind(this);
    this.displayEditRoutine = this.displayEditRoutine.bind(this);
    this.getRoutines = this.getRoutines.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeDisplayModalGroup = this.onChangeDisplayModalGroup.bind(this);
    this.onChangeGroup = this.onChangeGroup.bind(this);
  }

  componentDidMount() {
    this.props.selectItemNav("Rutinas");
    this.getRoutines();
    this.getGroups();
    this.setState({
      routines: this.props.RoutinesReducer.routines,
      isLoading: this.props.RoutinesReducer.isLoading,
      errMess: this.props.RoutinesReducer.errMess,
      selectDay: today,
      currentDayString: this.getDayName(today.toDateString(), "es"),
      currentDayNumber: today.getDate(),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.routines !== this.props.RoutinesReducer.routes &&
      prevProps.RoutinesReducer !== this.props.RoutinesReducer
    ) {
      this.setState({
        routines: this.props.RoutinesReducer.routines,
        isLoading: this.props.RoutinesReducer.isLoading,
        errMess: this.props.RoutinesReducer.errMess,
      });
    }
    if (this.state.groups !== this.props.GroupsReducer.groups) {
      let newGroupsOptions = [];
      newGroupsOptions = this.props.GroupsReducer.groups.map((group) => {
        return {
          value: group.id,
          label: group.name,
        };
      });
      newGroupsOptions.unshift({ value: "all", label: "Todos" });
      this.setState({
        groups: this.props.GroupsReducer.groups,
        groupsOptions: newGroupsOptions,
        selectGroupValue: "all",
      });
    }
    if (prevState.selectGroupValue !== this.state.selectGroupValue) {
      this.getRoutines();
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.timer);
  }

  getDayName(dateStr, locale) {
    var date = new Date(dateStr);
    return date.toLocaleDateString(locale, { weekday: "long" });
  }

  getRoutines(newDate) {
    let date;
    if (newDate) date = newDate.toLocaleDateString();
    else date = this.state.selectDay.toLocaleDateString();
    date = date.replaceAll("/", "-");
    let params = {};
    params = {
      date: date,
      group: this.state.selectGroupValue,
    };
    this.props.fetchRoutines(params);
    if (!this.state.timer) {
      const timer = setInterval(() => {
        date = this.state.selectDay.toLocaleDateString();
        date = date.replaceAll("/", "-");
        params = {
          date: date,
          group: this.state.selectGroupValue,
        };
        this.props.updateRoutines(params);
      }, 30000);
      this.setState({
        timer: timer,
      });
    }
  }

  getGroups() {
    this.props.getGroups();
  }

  addDays(date, days, oper) {
    var result = new Date(date);
    if (oper === "+") result.setDate(result.getDate() + days);
    else result.setDate(result.getDate() - days);
    return result;
  }

  onChangeDate(action) {
    let newDate;
    if (action === "next") {
      newDate = this.addDays(this.state.selectDay, 1, "+");
    } else if (action === "prev") {
      newDate = this.addDays(this.state.selectDay, 1, "-");
    }
    this.setState({
      selectDay: newDate,
      currentDayString: this.getDayName(newDate.toDateString(), "es"),
      currentDayNumber: newDate.getDate(),
    });
    this.getRoutines(newDate);
  }

  displayEditRoutine(routine) {
    this.setState({
      showEditRoutine: !this.state.showEditRoutine,
      selectedRoutine: routine,
    });
  }

  onChangeDisplayModalGroup() {
    this.setState({
      displayModalGroup: !this.state.displayModalGroup,
    });
  }

  onChangeGroup(group) {
    this.setState({
      selectGroupValue: group,
    });
  }

  exitDelete() {
    this.props.fetchRoutines();
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div>
          <div className="matx-loader">
            <img src={process.env.PUBLIC_URL + "/logoMotion.png"} alt="" />
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
    } else if (this.state.errMess) {
      <NotFound
        image={rutinasIco}
        title="Lo sentimos, ocurrio un error al cargar sus rutinas!"
        description="Si aun no as configurado una ruta para que genere rutinas, recuerda que pueses hacerlo desde la pestaña de rutas."
      />;
    } else {
      return (
        <div className="Rutinas">
          <NewRoutineProvider>
            <CalendarRoutines
              currentDayString={this.state.currentDayString}
              currentDayNumber={this.state.currentDayNumber}
              onChangeDate={this.onChangeDate}
            />
            <div className="panel-routines column">
              <div className="rutinas-select-groups-content">
                <MotionSelectTest
                  placeholder="Seleccionar grupo"
                  options={this.state.groupsOptions}
                  selectValue={this.onChangeGroup}
                  extraElement={
                    <div
                      onClick={this.onChangeDisplayModalGroup}
                      style={{ padding: "0.5em" }}
                    >
                      Crear grupo...
                    </div>
                  }
                  valueSelected={this.state.selectGroupValue}
                />
              </div>
              <RutinasContent
                routines={this.state.routines}
                displayEditRoutine={this.displayEditRoutine}
              />
            </div>
            {this.state.displayModalGroup ? (
              <ModalGroupCreate exit={this.onChangeDisplayModalGroup} />
            ) : null}
            {this.state.showEditRoutine ? (
              <ModalRutinas
                id={this.state.selectedRoutine.id}
                routine={this.state.selectedRoutine}
                displayEditRoutine={this.displayEditRoutine}
                groups={this.state.groups}
              />
            ) : null}
          </NewRoutineProvider>
        </div>
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Rutinas);
