import React, { Component } from "react";
import { connect } from "react-redux";
import "./Excel.scss";

import { ReactComponent as IcoExit } from "../../../../assets/ico-exit.svg";
import { ReactComponent as IcoMas } from "../../../../assets/Mas.svg";
import { createStop } from "../../../../redux/actions/stopsActions";

const mapStateToProps = (state) => {
  return {
    ImgsReducer: state.ImgsReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createStop: (newStop) => {
    dispatch(createStop(newStop));
  },
});

class Excel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stops: [],
      address: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.addStop = this.addStop.bind(this);
    this.deleteStop = this.deleteStop.bind(this);
    this.saveStops = this.saveStops.bind(this);
  }

  componentDidMount() {
    this.setState({
      stops: this.props.stops,
    });
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  addStop() {
    if (this.state.address !== "") {
      const id = this.state.stops[this.state.stops.length - 1].__rowNum__ + 1;
      const { address } = this.state;
      this.setState({
        stops: [
          ...this.state.stops,
          {
            __rowNum__: id,
            direccion: address,
          },
        ],
        address: "",
      });
    }
  }

  deleteStop(id) {
    const stops = this.state.stops.filter((stop) => {
      return stop.__rowNum__ !== id;
    });
    this.setState({
      stops: stops,
    });
  }

  saveStops() {
    const geocoder = new this.props.maps.Geocoder();
    const newAdress = this.state.stops;
    let json_types = [];
    for (var i = 0; i < this.props.ImgsReducer.imgs_types.length; i++) {
      json_types.push(this.props.ImgsReducer.imgs_types[i].id);
    }
    newAdress.map((item) => {
      if (this.props.maps) {
        geocoder.geocode({ address: item.direccion }, (results, status) => {
          if (status === "OK") {
            let newStop = {
              name: item.direccion,
              address: item.direccion,
              geo_type: "circle",
              polygon: "",
              center: JSON.stringify({
                type: "Point",
                coordinates: [
                  results[0].geometry.location.lat(),
                  results[0].geometry.location.lng(),
                ],
              }),
              radius: 50,
              type: json_types,
              color: "#8c8c8c",
            };
            this.props.createStop(newStop);
          } else {
            console.log("peticion invalida");
          }
        });
      }
    });
  }

  render() {
    return (
      <div className="Excel">
        <div className="Excel-content">
          <div className="Excel-margin column">
            <div className="Excel-header row">
              <IcoExit
                className="close-modal"
                onClick={this.props.closeExcel}
              />
            </div>
            <form className="Excel-form row">
              <input
                type="text"
                placeholder="Direccion"
                name="address"
                value={this.state.address}
                onChange={this.handleChange}
              />
              <div
                className="Excel-form-button"
                onClick={this.addStop}
                style={{
                  cursor: this.state.address !== "" ? "pointer" : "not-allowed",
                }}
              >
                <IcoMas className="ico-mas" />
              </div>
            </form>
            <div className="Excel-body">
              <div className="Excel-body-content">
                {this.state.stops.map((stop) => (
                  <div className="Excel-body-item row" key={stop.__rowNum__}>
                    <div className="text">{stop.direccion}</div>
                    <div
                      className="delete"
                      onClick={() => this.deleteStop(stop.__rowNum__)}
                    >
                      <IcoExit className="ico-exit" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <button className="btn1" onClick={this.saveStops}>
              Guardar
            </button>
          </div>
        </div>
        <div className="Excel-background"></div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Excel);
