import {
  GET_STOPS_LIST,
  GET_STOPS_LIST_SUCCCESS,
  GET_STOPS_LIST_FAILED,
  GET_STOPS_LIST_UPDATE,
  CREATE_STOP,
  CREATE_MANY_STOP,
  CREATE_STOP_SUCCESS,
  CREATE_STOP_FALED,
  END_CREATE_STOP,
  DELETE_STOP,
  DELETE_STOP_SUCCESS,
  DELETE_STOP_FALED,
} from "../types/stops.types";

const initialState = {
  isLoading: true,
  errMess: null,
  stops: [],
  deleteStop: {
    loading: false,
    success: false,
    error: false,
  },
};

const StopsReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_STOPS_LIST:
      return { ...state, isLoading: true };
    case GET_STOPS_LIST_SUCCCESS:
      return {
        ...state,
        isLoading: false,
        stops: action.payload,
      };
    case GET_STOPS_LIST_FAILED:
      return { ...state, isLoading: false, errMess: action.payload };
    case GET_STOPS_LIST_UPDATE:
      return {
        ...state,
        isLoading: false,
        stops: state.stops,
      };
    case CREATE_STOP:
      return {
        ...state,
        isLoadingCreate: true,
      };
    case CREATE_STOP_SUCCESS:
      return {
        ...state,
        created: true,
        isLoadingCreate: false,
      };
    case CREATE_STOP_FALED:
      return {
        ...state,
        errorCreated: action.payload,
        isLoadingCreate: false,
      };
    case CREATE_MANY_STOP:
      return {
        ...state,
      };
    case DELETE_STOP:
      return {
        ...state,
        deleteStop: {
          ...state.deleteStop,
          loading: true,
        },
      };
    case DELETE_STOP_SUCCESS:
      return {
        ...state,
        deleteStop: {
          ...state.deleteStop,
          loading: false,
          success: true,
        },
      };
    case DELETE_STOP_FALED:
      return {
        ...state,
        deleteStop: {
          ...state.deleteStop,
          loading: false,
          error: true,
        },
      };
    case END_CREATE_STOP:
      return {
        ...state,
        isLoadingCreate: false,
        created: false,
        errorCreated: false,
      };
    default:
      return state;
  }
};

export default StopsReducer;
