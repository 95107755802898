import React, { useState, useEffect } from "react";
import "./SeguimientoCard.scss";

import { ReactComponent as IcoUnit } from "../../assets/motion_icon-unidad.svg";
import { ReactComponent as IcoUbicacion } from "../../assets/motion_icon-ubicacion.svg";
import { RoutineModel } from "../../../../state/models/routineModel";

export default function SeguimientoCard(props) {
  const [origin, setOrigin] = useState("");
  const [destiny, setDestiny] = useState("");
  const [routine, setRoutine] = useState(RoutineModel);

  useEffect(() => {
    if (props.routine) formatRoutineData(props.routine);
  }, [props.routine]);

  const formatRoutineData = (routine) => {
    setRoutine(routine);
    if (routine.schedules.length > 0) {
      setOrigin(routine.schedules[0].stop_name);
      setDestiny(routine.schedules[routine.schedules.length - 1].stop_name);
    }
  };

  return (
    <div
      className={`SeguimientoCard ${
        props.routineSelected === routine.id ? "activate" : ""
      }`}
      onClick={() => props.selectRoutine(routine.id)}
    >
      <div className="seguimiento-content column">
        <div className="row seguimiento-card-header">
          <div className="seguimiento-title">{routine.name}</div>
          <div className="row seguimiento-sub-titles">
            <div className="row seguimiento-sub-titles-content">
              <IcoUbicacion className="SeguimientoCard-ico sky" />
              <div className="SeguimientoCard-num-stops">
                {routine.schedules.length}
              </div>
            </div>
          </div>
        </div>
        <div className="column SeguimientoCard-text">
          <div className="row SeguimientoCard-text-item">
            <span>UNIDAD</span>
            <div>{routine.unit.name}</div>
          </div>
          <div className="row SeguimientoCard-text-item">
            <span>INICIO</span>
            <div>{origin}</div>
          </div>
          <div className="row SeguimientoCard-text-item">
            <span>DESTINO</span>
            <div>{destiny}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
