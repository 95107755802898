import {
  GET_USER_DATA,
  GET_USER_DATA_FAILED,
  GET_USER_DATA_SUCCESS,
} from "../types/user.types";

const initialState = {
  isLoading: true,
  errMess: null,
  user: {},
};

const UserReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_USER_DATA:
      return {
        isLoading: true,
        errMess: false,
        user: {},
      };
    case GET_USER_DATA_SUCCESS:
      return {
        isLoading: false,
        errMess: false,
        user: action.payload,
      };
    case GET_USER_DATA_FAILED:
      return {
        isLoading: false,
        errMess: action.payload,
        user: {},
      };
    default:
      return state;
  }
};

export default UserReducer;
