import {
  XAxis,
  YAxis,
  XYPlot,
  AreaSeries,
  LineSeries,
  GradientDefs,
  HorizontalGridLines,
} from "react-vis";
import { AutoSizer } from "react-virtualized";
import "react-virtualized/styles.css";

import React, { useState, useEffect } from "react";

export default function ChartRutinas(props) {
  const [selectedIndex, setSelectedIndex] = useState();
  const [activeNowRoutines, setActiveNowRoutines] = useState([]);
  const [scheduledRoutines, setScheduledRoutines] = useState([]);
  const [completedRoutines, setCompletedRoutines] = useState([]);
  const [totalRoutines, setTotalRoutines] = useState([]);
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);

  useEffect(() => {
    if (props.routines) getChartData();
  }, [props.routines]);

  const getChartData = () => {
    const newActiveNowRoutines = props.routines.activeNowRoutines.map(
      (routine) => {
        let new_data = {
          x: new Date(routine.date),
          y: routine.total,
        };
        return new_data;
      }
    );
    setActiveNowRoutines(newActiveNowRoutines);
    const newScheduledRoutines = props.routines.scheduledRoutines.map(
      (routine) => {
        let new_data = {
          x: new Date(routine.date),
          y: routine.total,
        };
        return new_data;
      }
    );
    setScheduledRoutines(newScheduledRoutines);
    const newCompletedRoutines = props.routines.completedRoutines.map(
      (routine) => {
        let new_data = {
          x: new Date(routine.date),
          y: routine.total,
        };
        return new_data;
      }
    );
    setCompletedRoutines(newCompletedRoutines);
    const totalRoutines = props.routines.totalRoutines.map((routine) => {
      let new_data = {
        x: new Date(routine.date),
        y: routine.total,
      };
      return new_data;
    });
    setTotalRoutines(totalRoutines);
  };

  const _getSeriesColor = (index) => {
    if (selectedIndex !== null && selectedIndex !== index) {
      return "#ddd";
    }
    return null;
  };

  const _onChartMouseLeave = () => {
    setSelectedIndex(null);
  };

  const _onSeriesMouseOvers = (selectedIndex) => {
    setSelectedIndex(selectedIndex);
  };

  const _onSeriesMouseOut = () => {
    setSelectedIndex(null);
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <AutoSizer>
        {({ height, width }) => (
          <XYPlot
            height={height}
            width={width}
            className="chartRutinas"
            xType="time"
            yDomain={[0, 20]}
            onMouseLeave={_onChartMouseLeave}
          >
            <HorizontalGridLines />
            <XAxis className="chart-rutina-ejex" />
            <YAxis />
            <GradientDefs>
              <linearGradient id="SkyGradient" x1="0" x2="0" y1="0" y2="1">
                <stop offset="0%" stopColor="#01BEBD80" stopOpacity={1} />
                <stop offset="100%" stopColor="#40CEE460" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="RedGradient" x1="0" x2="0" y1="0" y2="100%">
                <stop offset="0%" stopColor="#C6007E80" stopOpacity={1} />
                <stop offset="100%" stopColor="#D4409E60" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="Gray1Gradient" x1="0" x2="0" y1="0" y2="100%">
                <stop offset="0%" stopColor="#C5C5C5" stopOpacity={1} />
                <stop offset="100%" stopColor="#C5C5C5" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="Gray2Gradient" x1="0" x2="0" y1="0" y2="100%">
                <stop offset="0%" stopColor="#e2e2e2" stopOpacity={1} />
                <stop offset="100%" stopColor="#e2e2e220" stopOpacity={0} />
              </linearGradient>
            </GradientDefs>

            <AreaSeries
              animation
              style={{ zIndex: "-1" }}
              data={totalRoutines}
              opacity={selectedIndex && selectedIndex !== 4 ? 0.2 : 1}
              color={"url(#Gray2Gradient)"}
              curve={"curveMonotoneX"}
            />
            <AreaSeries
              animation
              style={{ zIndex: "-1" }}
              data={scheduledRoutines}
              color={"url(#Gray1Gradient)"}
              opacity={selectedIndex && selectedIndex !== 3 ? 0.2 : 1}
              curve={"curveMonotoneX"}
            />
            <AreaSeries
              animation
              style={{ zIndex: "-1" }}
              data={completedRoutines}
              opacity={selectedIndex && selectedIndex !== 2 ? 0.2 : 1}
              color={"url(#RedGradient)"}
              curve={"curveMonotoneX"}
            />
            {/* <AreaSeries
              animation
              style={{ zIndex: "-1" }}
              data={activeNowRoutines}
              opacity={selectedIndex && selectedIndex !== 1 ? 0.2 : 1}
              color={"url(#SkyGradient)"}
              // curve={"curveMonotoneX"}
            /> */}

            <LineSeries
              animation
              curve={"curveMonotoneX"}
              data={totalRoutines}
              opacity={selectedIndex && selectedIndex !== 4 ? 0.2 : 1}
              stroke="#e2e2e2"
              onSeriesMouseOut={_onSeriesMouseOut}
              onSeriesMouseOver={() => _onSeriesMouseOvers(4)}
            />
            <LineSeries
              animation
              curve={"curveMonotoneX"}
              data={scheduledRoutines}
              opacity={selectedIndex && selectedIndex !== 3 ? 0.2 : 1}
              stroke="#8c8c8c"
              strokeStyle="solid"
              onSeriesMouseOut={_onSeriesMouseOut}
              onSeriesMouseOver={() => _onSeriesMouseOvers(3)}
            />
            <LineSeries
              animation
              curve={"curveMonotoneX"}
              data={completedRoutines}
              opacity={selectedIndex && selectedIndex !== 2 ? 0.2 : 1}
              stroke="#c6007e"
              strokeStyle="solid"
              onSeriesMouseOut={_onSeriesMouseOut}
              onSeriesMouseOver={() => _onSeriesMouseOvers(2)}
            />
            {/* <LineSeries
              animation
              // curve={"curveMonotoneX"}
              data={activeNowRoutines}
              opacity={selectedIndex && selectedIndex !== 1 ? 0.2 : 1}
              stroke="#01bebd"
              strokeStyle="solid"
              onSeriesMouseOut={_onSeriesMouseOut}
              onSeriesMouseOver={() => _onSeriesMouseOvers(1)}
            /> */}
            {/* <Crosshair values={crosshairValues} /> */}
          </XYPlot>
        )}
      </AutoSizer>
    </div>
  );
}
