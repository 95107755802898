import {
  GET_GROUPS,
  GET_GROUPS_FAILED,
  GET_GROUPS_SUCCESS,
  POST_GROUPS,
  POST_GROUPS_FAILED,
  POST_GROUPS_SUCCESS,
} from "../types/groups.types";

const initialState = {
  isLoading: true,
  errMess: null,
  groups: [],
};

const GroupsReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_GROUPS:
      return {
        ...state,
        isLoading: true,
      };
    case GET_GROUPS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        groups: action.payload,
      };
    case GET_GROUPS_FAILED:
      return {
        ...state,
        isLoading: false,
        errMess: action.payload,
      };
    case POST_GROUPS:
      return {
        ...state,
        isLoading: true,
      };
    case POST_GROUPS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case POST_GROUPS_FAILED:
      return {
        ...state,
        isLoading: false,
        errMess: action.payload,
      };
    default:
      return state;
  }
};

export default GroupsReducer;
