import React, { Component } from "react";
import "./ModalCreated.scss";

import { ReactComponent as LogoCreated } from "../../assets/CreatedIco.svg";
import { ReactComponent as LogoError } from "../../assets/ico-exit.svg";
import LoadingGif from "../../assets/motion-small-loader.gif";

export default class ModalCreated extends Component {
  componentDidMount() {
    if (this.props.error || this.props.succes) {
      setTimeout(() => {
        this.props.exit();
      }, 3000);
    }
  }
  componentDidUpdate() {
    if (this.props.error || this.props.succes) {
      setTimeout(() => {
        this.props.exit();
      }, 3000);
    }
  }
  render() {
    return (
      <div className="ModalCreated">
        <div className="column frontModal">
          {this.props.loading ? (
            <img src={LoadingGif} className="logo" alt="" />
          ) : (
            <>
              <div>
                {this.props.error ? (
                  <LogoError className="logo error" />
                ) : (
                  <LogoCreated className="logo" />
                )}
              </div>
              <div className="title">{this.props.title}</div>
            </>
          )}
        </div>
        <div className="ModalCreated" onClick={this.props.exit}></div>
      </div>
    );
  }
}
