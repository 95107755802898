import React, { useState, useEffect } from "react";
import { ReactComponent as MasIco } from "../../../../assets/Mas.svg";
import { ReactComponent as LevelUp } from "../../../../assets/desplazar-arriba.svg";
import { ReactComponent as LevelDown } from "../../../../assets/desplazar-abajo.svg";
import Select from "react-select";
import MotionSelectTest from "../../../../componets/Motion/MotionSelect/MotionSelectTest";

export default function StopForm(props) {
  const [stop, setStop] = useState(null);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [groups, setGroups] = useState([]);
  const [groupsOptions, setGroupsOptions] = useState([]);
  const [selectGroupValue, setSelectGroupValue] = useState(null);

  useEffect(() => {
    if (props.stop) {
      setStop(props.stop);
    }
  }, [props.stop]);

  useEffect(() => {
    if (props.groups) {
      setGroups(props.groups);
      let newGroupsOptions = props.groups.map((group) => {
        return {
          value: group.id,
          label: group.name,
        };
      });
      newGroupsOptions.unshift({ value: "none", label: "Ninguno" });
      setGroupsOptions(newGroupsOptions);
    }
  }, [props.groups]);

  useEffect(() => {
    if (stop) {
      setGroupStop();
    }
  }, [stop]);

  const setGroupStop = () => {
    if (stop.group.id) {
      setSelectGroupValue(stop.group.id);
    }
  };

  const onChangeGroup = (group) => {
    setSelectGroupValue(group);
    props.onChangeGroup(group);
  };

  return (
    <div className="column paradas-list-form-content">
      {stop ? (
        <>
          <div className="row buttons-content">
            <div className="button-item-content">
              <button
                className={`${
                  props.selectStop === "Poligono" ? "btn3" : "btn4"
                }`}
                value="Poligono"
                onClick={props.SelectTypeStop}
              >
                Polígono
              </button>
            </div>
            <div className="button-item-content">
              <button
                className={`${
                  props.selectStop === "Circulo" ? "btn3" : "btn4"
                }`}
                value="Circulo"
                onClick={props.SelectTypeStop}
              >
                Círculo
              </button>
            </div>
          </div>
          <div className="paradas-form-body column">
            <div className="form-item-content">
              <MotionSelectTest
                placeholder="Seleccionar grupo"
                options={groupsOptions}
                selectValue={onChangeGroup}
                valueSelected={selectGroupValue}
              />
            </div>
            <div className="form-item-content">
              <input
                type="text"
                placeholder="Direccion"
                className="gray3 paradas-list-form-input input-search"
                value={stop.address}
                onChange={props.handleChangeAdress}
                name="address"
                autoComplete="off"
              />
              {props.suggestions ? (
                <div className="input-search-list">
                  <div className="input-search-list-content column">
                    {props.suggestions.map((suggestion) => {
                      return (
                        <div
                          className="input-search-list-item"
                          key={suggestion.place_id}
                          onClick={props.selectAddressClick}
                        >
                          {suggestion.description}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="row form-item-content" style={{justifyContent: 'space-between'}}>
              <div className="form-item-content" style={{width: '120px'}} >
                <input
                  type="text"
                  placeholder="Latitud"
                  className="gray3 paradas-list-form-input"
                  value={props.center.lat}
                  onChange={props.onChangeCordinates}
                  name="lat"
                />
              </div>
              <div className="form-item-content" style={{width: '120px'}}>
                <input
                  type="text"
                  placeholder="Longitud"
                  className="gray3 paradas-list-form-input"
                  value={props.center.lng}
                  onChange={props.onChangeCordinates}
                  name="lng"
                />
              </div>
            </div>
            <div className="form-item-content">
              <input
                type="text"
                placeholder="Nombre"
                className="gray3 paradas-list-form-input"
                value={stop.name}
                onChange={props.handleChange}
                name="name"
              />
            </div>
            <div className="form-item-content">
              {props.circle ? (
                <div className="radius-select">
                  <div> {props.radius}m </div>
                  <div className="content-level column">
                    <LevelUp
                      className="ico-level"
                      onClick={() => props.changeRadiusCircle(1)}
                    />
                    <LevelDown
                      className="ico-level"
                      onClick={() => props.changeRadiusCircle(0)}
                    />
                  </div>
                </div>
              ) : null}
            </div>
            <div className="form-item-content">
              <div
                placeholder="Color"
                className="gray3 paradas-list-form-input input-color row"
                name="color"
              >
                <div> Color </div>
                <div
                  className="input-color-item"
                  style={{
                    backgroundColor: stop.color,
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowColorPicker(!showColorPicker)}
                  ></div>
                  {showColorPicker ? (
                    <div className="color-picker">
                      {props.colors.map((item) => (
                        <div
                          key={item.id}
                          className="color-picker-item-content"
                        >
                          <div
                            className="color-picker-item"
                            style={{ backgroundColor: item.color }}
                            onClick={() => props.setColor(item.color)}
                          ></div>
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="form-item-content-select">
              <Select
                options={props.options}
                name="UnitsIds"
                isMulti
                onChange={props.onDataChange}
              />
            </div>
          </div>
          <button
            type="button"
            onClick={props.saveStop}
            className="btn1 row button-save"
          >
            Crear <MasIco className="ico-mas" />
          </button>
        </>
      ) : null}
    </div>
  );
}
