import React, { useState, useEffect } from "react";
import SeguimientoCard from "../SeguimientoCard/SeguimientoCard";
import "./SeguimientoList.scss";

export default function SeguimientoList(props) {
  const [routines, setRoutines] = useState([]);

  useEffect(() => {
    if (props.routines) {
      setRoutines(props.routines);
    }
    console.log(props.routines);
  }, [props.routines]);

  return (
    <div className="SeguimientoList">
      <div className="Seguimiento-content-list">
        {routines.map((routine, index) => {
          return (
            <SeguimientoCard
              key={routine.id}
              routine={routine}
              routineSelected={props.routineSelected}
              selectRoutine={props.selectRoutine}
            />
          );
        })}
      </div>
    </div>
  );
}
