export const GET_PASSENGERS = "GET_PASSENGERS";
export const GET_PASSENGERS_SUCCESS = "GET_PASSENGERS_SUCCESS";
export const GET_PASSENGERS_FAILED = "GET_PASSENGERS_FAILED";

export const CREATE_PASSENGER = "CREATE_PASSENGER";
export const CREATE_PASSENGER_SUCCESS = "CREATE_PASSENGER_SUCCESS";
export const CREATE_PASSENGER_FAILED = "CREATE_PASSENGER_FAILED";
export const CREATE_PASSENGER_END = "CREATE_PASSENGER_END";

export const CREATE_PASSENGERS = "CREATE_PASSENGER";
export const CREATE_PASSENGERS_SUCCESS = "CREATE_PASSENGER_SUCCESS";
export const CREATE_PASSENGERS_FAILED = "CREATE_PASSENGER_FAILED";
export const CREATE_PASSENGERS_END = "CREATE_PASSENGER_END";

export const EDIT_PASSENGER = "EDIT_PASSENGER";
export const EDIT_PASSENGER_SUCCESS = "EDIT_PASSENGER_SUCCESS";
export const EDIT_PASSENGER_FAILED = "EDIT_PASSENGER_FAILED";
export const EDIT_PASSENGER_END = "EDIT_PASSENGER_END";

export const DELETE_PASSENGER = "EDIT_PASSENGER";
export const DELETE_PASSENGER_SUCCESS = "EDIT_PASSENGER_SUCCESS";
export const DELETE_PASSENGER_FAILED = "EDIT_PASSENGER_FAILED";
export const DELETE_PASSENGER_END = "EDIT_PASSENGER_END";

export const SAVE_PASSEGERS_IDS = "SAVE_PASSEGERS_IDS";
