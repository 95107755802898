export const GET_ROUTINE = "GET_ROUTINE";
export const GET_ROUTINE_SUCCESS = "GET_ROUTINE_SUCCESS";
export const GET_ROUTINE_FAILED = "GET_ROUTINE_FAILED";

export const GET_SINGLE_ROUTINE = "GET_SINGLE_ROUTINE";
export const GET_SINGLE_ROUTINE_SUCCESS = "GET_SINGLE_ROUTINE_SUCCESS";
export const GET_SINGLE_ROUTINE_FAILED = "GET_SINGLE_ROUTINE_FAILED";

export const GET_DAYS = "GET_DAYS";
export const GET_DAYS_SUCCESS = "GET_DAYS_SUCCESS";
export const GET_DAYS_FAILED = "GET_DAYS_FAILED";

export const GET_DAYSWEEK = "GET_DAYSWEEK";
export const GET_DAYSWEEK_SUCCESS = "GET_DAYSWEEK_SUCCESS";
export const GET_DAYSWEEK_FAILED = "GET_DAYSWEEK_FAILED";

export const GET_MONTHS = "GET_MONTHS";
export const GET_MONTHS_SUCCESS = "GET_MONTHS_SUCCESS";
export const GET_MONTHS_FAILED = "GET_MONTHS_FAILED";

export const CREATE_ROUTINE = "CREATE_ROUTINE";
export const CREATE_ROUTINE_SUCCESS = "CREATE_ROUTINE_SUCCESS";
export const CREATE_ROUTINE_FAILED = "CREATE_ROUTINE_FAILED";
export const CREATE_ROUTINE_END = "CREATE_ROUTINE_END";

export const EDIT_ROUTINE = "EDIT_ROUTINE";
export const EDIT_ROUTINE_SUCCESS = "EDIT_ROUTINE_SUCCESS";
export const EDIT_ROUTINE_FAILED = "EDIT_ROUTINE_FAILED";
export const EDIT_ROUTINE_END = "EDIT_ROUTINE_END";

export const DELETE_ROUTINE = "DELETE_ROUTINE";
export const DELETE_ROUTINE_SUCCESS = "DELETE_ROUTINE_SUCCESS";
export const DELETE_ROUTINE_FAILED = "DELETE_ROUTINE_FAILED";
export const DELETE_ROUTINE_END = "DELETE_ROUTINE_END";

export const BLOCK_ROUTINE = "BLOCK_ROUTINE";
export const BLOCK_ROUTINE_SUCCESS = "BLOCK_ROUTINE_SUCCESS";
export const BLOCK_ROUTINE_FAILED = "BLOCK_ROUTINE_FAILED";
export const BLOCK_ROUTINE_END = "BLOCK_ROUTINE_END";

export const EDIT_TIMELINE = "EDIT_TIMELINE";
export const EDIT_TIMELINE_SUCCESS = "EDIT_TIMELINE_SUCCESS";
export const EDIT_TIMELINE_FAILED = "EDIT_TIMELINE_FAILED";
