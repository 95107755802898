import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ChartRutinas from "./components/ChartRutinas/ChartRutinas";

import "./Rutinas.scss";

export default function Rutinas(props) {
  const [routines, setRoutines] = useState({
    totalRoutinesCount: 0,
    activeNowRoutinesCount: 0,
    scheduledRoutinesCount: 0,
    completedRoutinesCount: 0,
    activeNowRoutines: [],
    scheduledRoutines: [],
    completedRoutines: [],
    totalRoutines: [],
  });

  useEffect(() => {
    if (props.routines) formatRoutineMetric(formatRoutineMetric);
  }, [props.routines]);

  const formatRoutineMetric = () => {
    setRoutines(props.routines);
  };

  return (
    <div className="column resumen-rutinas">
      <div className="row resumen-rutinas-item-row">
        <div className="blue2 title">
          <Link to="/rutinas">Rutinas</Link>
        </div>
        <div className="row sub-resumen-rutinas">
          <div className="column resumen-item-column">
            <div className="row">
              <div className="sky2 item-text">
                Activas Ahora <span>{routines.activeNowRoutinesCount}</span>
              </div>
            </div>
            <div className="row">
              <div className="red2 item-text">
                Completadas <span>{routines.completedRoutinesCount}</span>
              </div>
            </div>
          </div>
          <div className="column resumen-item-column">
            <div className="row">
              <div className="gray1 item-text">
                No completadas <span>{routines.scheduledRoutinesCount}</span>
              </div>
            </div>
            <div className="row">
              <div className="gray3 item-text">
                Total <span>{routines.totalRoutinesCount}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChartRutinas routines={routines} />
    </div>
  );
}
