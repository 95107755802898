import React, { Component } from "react";
import { connect } from "react-redux";
import { logout } from "../../redux/actions/authActions";
import "./Profile.scss";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch(logout());
  },
});

class Profile extends Component {
  render() {
    return (
      <div className="Profile">
        <div className="Profile-content">
          <a
            href="/session/login"
            onClick={this.props.logout()}
            className="profile-text"
          >
            Cerrar sesión
          </a>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
