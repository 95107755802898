import React, { useState, useEffect } from "react";
import "./ModalGroupCreate.scss";
import { connect } from "react-redux";
import { ReactComponent as IcoExit } from "../../assets/ico-exit.svg";
import { ReactComponent as IcoCheck } from "../../assets/check-ico.svg";
import { getGroups, postGroups } from "../../redux/actions/groupsActions";

function ModalGroupCreate(props) {
  const [name, setName] = useState("");

  useEffect(() => {
    return () => {
      props.getGroups();
    };
  }, []);

  const onChangeName = (e) => {
    setName(e.target.value);
  };

  const createGroup = () => {
    if (name.length > 0) {
      props.postGroups({ name: name });
      setName("");
      props.exit();
    }
  };

  return (
    <div className="ModalGroupCreate">
      <div className="frontModal">
        <div className="header row">
          <div className="title">Crear nuevo grupo</div>
          <div className="actions-modal row">
            <div className="ico-content" onClick={createGroup}>
              <IcoCheck className="ico check" />
            </div>
            <div className="ico-content" onClick={props.exit}>
              <IcoExit className="ico exit" />
            </div>
          </div>
        </div>
        <div className="body">
          <div className="input-content column">
            <span>Nombre del grupo</span>
            <input
              type="text"
              placeholder="Nombre"
              value={name}
              onChange={onChangeName}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  postGroups: (params) => {
    dispatch(postGroups(params));
  },
  getGroups: () => {
    dispatch(getGroups());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalGroupCreate);
