var paradas = [
  {
    id: 1,
    lat: 4.754,
    lng: -74.0466,
    name: "Op Julio",
    state: false,
  },
  {
    id: 2,
    lat: 4.692335,
    lng: -74.1206,
    name: "Op May",
    state: false,
  },
  {
    id: 3,
    lat: 4.751351631938948,
    lng: -74.04639093169978,
    name: "Op Feb",
    state: false,
  },
];

var rutas = [
  {
    id: 1,
    stopsLines: [1, 2],
    name: "Op Feb",
    origin: { lat: 4.754, lng: -74.0466 },
    wayponts: {},
    destination: { lat: 4.692335, lng: -74.1206 },
  },
];

var rutinas = [
  {
    id: 2,
    idRutas: 1,
    idUnidad: 3,
    name: "Op Feb",
    origin: { lat: 4.754, lng: -74.0466 },
    wayponts: {},
    destination: { lat: 4.692335, lng: -74.1206 },
    unit: 1,
  },
  {
    idRutas: 1,
    idUnidad: 3,
    idRutina: 2,
    name: "Op Feb",
    origin: { lat: 4.754, lng: -74.0466 },
    wayponts: {},
    destination: { lat: 4.692335, lng: -74.1206 },
    unit: 2,
  },
];

export default { paradas, rutas, rutinas };
