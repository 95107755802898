import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Navigation.scss";

import { ReactComponent as LogoRoute } from "../../assets/route-control_logo.svg";
import { ReactComponent as LogoMotion } from "../../assets/motion-logo-negativo-web.svg";
import { ReactComponent as IcoMotion } from "../../assets/motion-favicon-web.svg";
import { ReactComponent as IcoParadas } from "../../assets/paradas-ico.svg";
import { ReactComponent as IcoNotificaciones } from "../../assets/notificaciones-ico.svg";
import { ReactComponent as IcoRutinas } from "../../assets/rutinas-ico.svg";
import IcoUserDefault from "../../assets/profile-user.png";
import { ReactComponent as IcoClients } from "../../assets/usuario.svg";
import { ReactComponent as IcoResumen } from "../../assets/resumen-ico.svg";
import { getUserData } from "../../redux/actions/userActions";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    UserReducer: state.UserReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUserData: () => {
    dispatch(getUserData());
  },
});

class Navigation extends Component {
  constructor() {
    super();
    this.state = {
      user: {
        name: "",
        email: "",
        image: "",
      },
    };
  }
  componentDidMount() {
    this.props.getUserData();
  }

  componentDidUpdate() {
    const { UserReducer } = this.props;
    const { user } = this.state;
    if (!UserReducer.isLoading || !UserReducer.errMess) {
      if (user !== UserReducer.user) {
        this.setState({
          user: UserReducer.user,
        });
      }
    }
  }

  render() {
    return (
      <div className="Navigation">
        <div className="Navigation-content row">
          <div className="nav-logos-main">
            <a href="https://monitoringinnovation.com">
              <LogoMotion className="motion-ico" />
            </a>
            <Link className="nav-item-logos-main" to="/">
              <h1>MI Route</h1>
            </Link>
            <div className="nav-gourp-items">
              <Link
                to="/"
                className={`nav-item ${
                  this.props.itemNavSelected === "Resumen" ? "nav-selected" : ""
                }`}
              >
                <IcoResumen className="seguimiento-ico" />
                {this.props.itemNavSelected === "Resumen" ? (
                  <h2>Resumen</h2>
                ) : null}
              </Link>

              <Link
                to="/seguimiento"
                className={`nav-item ${
                  this.props.itemNavSelected === "Seguimiento"
                    ? "nav-selected"
                    : null
                } `}
              >
                <IcoMotion className="seguimiento-ico" />
                {this.props.itemNavSelected === "Seguimiento" ? (
                  <h2>Seguimiento</h2>
                ) : null}
              </Link>

              <Link
                to="/rutinas"
                className={`nav-item ${
                  this.props.itemNavSelected === "Rutinas" ? "nav-selected" : ""
                } `}
              >
                <IcoRutinas className="seguimiento-ico" />
                {this.props.itemNavSelected === "Rutinas" ? (
                  <h2>Rutinas</h2>
                ) : null}
              </Link>
              <Link
                to="/paradas"
                className={`nav-item ${
                  this.props.itemNavSelected === "Paradas" ? "nav-selected" : ""
                } `}
              >
                <IcoParadas className="seguimiento-ico" />
                {this.props.itemNavSelected === "Paradas" ? (
                  <h2>Paradas</h2>
                ) : null}
              </Link>
              <Link
                to="/rutas"
                className={`nav-item ${
                  this.props.itemNavSelected === "Rutas" ? "nav-selected" : ""
                }`}
              >
                <LogoRoute className="route-ico2" />
                {this.props.itemNavSelected === "Rutas" ? <h2>Rutas</h2> : null}
              </Link>
              <Link
                to="/pasajeros"
                className={`nav-item ${
                  this.props.itemNavSelected === "Pasajeros"
                    ? "nav-selected"
                    : ""
                }`}
              >
                <IcoClients className="seguimiento-ico" />
                {this.props.itemNavSelected === "Pasajeros" ? (
                  <h2>Pasajeros</h2>
                ) : null}
              </Link>
              {this.props.element1 ? <>{this.props.element1}</> : null}
            </div>
          </div>

          <div className="row">
            <div className="nav-user">
              <div
                className="nav-user-dates row"
                onClick={this.props.showPorfileFunction}
              >
                <h2>{this.state.user.name}</h2>
                <div className="nav-ico-avatar">
                  <img
                    src={`data:image/jpeg;base64,${this.state.user.image}`}
                    className="nav-ico-svg-avatar"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = IcoUserDefault;
                    }}
                  />
                </div>
              </div>
              <div
                className="nav-user-notifications"
                onClick={this.props.showNotificationFunction}
              >
                <IcoNotificaciones className="seguimiento-ico" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
