import {
  CREATE_PASSENGERS,
  CREATE_PASSENGERS_FAILED,
  CREATE_PASSENGERS_SUCCESS,
  GET_PASSENGERS,
  GET_PASSENGERS_FAILED,
  GET_PASSENGERS_SUCCESS,
  SAVE_PASSEGERS_IDS,
} from "../types/passengers.types";

const initialState = {
  isLoading: true,
  errMess: null,
  passengers: [],
  isLoadingCreate: false,
  created: false,
  passegersIds: [],
};

const PassengerReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_PASSENGERS:
      return {
        ...state,
        isLoading: true,
      };
    case GET_PASSENGERS_SUCCESS:
      return {
        ...state,
        passengers: action.payload,
        isLoading: false,
      };
    case GET_PASSENGERS_FAILED:
      return {
        ...state,
        errMess: action.payload,
        isLoading: false,
      };
    case CREATE_PASSENGERS:
      return {
        ...state,
        isLoadingCreate: true,
      };
    case CREATE_PASSENGERS_SUCCESS:
      return {
        ...state,
        created: true,
        isLoadingCreate: false,
      };
    case CREATE_PASSENGERS_FAILED:
      return {
        ...state,
        created: false,
        isLoadingCreate: false,
      };
    case SAVE_PASSEGERS_IDS:
      return {
        ...state,
        passegersIds: action.payload,
      };
    default:
      return state;
  }
};

export default PassengerReducer;
