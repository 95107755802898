import React, { Component } from "react";
import "./Seguimiento.scss";
import { connect } from "react-redux";
import mapStyle from "../../mapStyle/mapStyle";
import data from "../../data";
import SeguimientoCard from "./components/SeguimientoCard/SeguimientoCard";
import Map from "google-map-react";
import { fetchRoutines } from "../../redux/actions/routinesActions";
import { fetchRoutes } from "../../redux/actions/routesActions";
import { socketUnit } from "../../redux/actions/unitsActions";
import MarkerVehicle from "../../componets/MarkerVehicle/MarkerVehicle";
import SeguimientoMap from "./components/SeguimientoMap/SeguimientoMap";
import SeguimientoList from "./components/SeguimientoList/SeguimientoList";

const mapStateToProps = (state) => {
  return {
    UnitsReducer: state.UnitsReducer,
    RoutesReducer: state.RoutesReducer,
    RoutinesReducer: state.RoutinesReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchRoutines: (params) => {
    dispatch(fetchRoutines(params));
  },
  socketUnit: (unit) => {
    dispatch(socketUnit(unit));
  },
});

class Seguimiento extends Component {
  constructor() {
    super();
    this.state = {
      rutinas: [],
      zoom: 10,
      center: [4.655319272448736, -74.06834005475413],
      exitStops: [],
      selectedRoute: false,
      i: 0,
      numDeltas: 30,
      delay: 100,
      prevUnit: {},
      routines: [],
      routineSelected: false,
    };
    this.selectRoutine = this.selectRoutine.bind(this);
  }

  componentDidMount() {
    this.props.selectItemNav("Seguimiento");
    var params = {
      state_routine: "running",
    };
    this.props.fetchRoutines(params);
  }

  componentDidUpdate(prevProps) {
    const { routines } = this.props.RoutinesReducer;
    if (routines !== prevProps.RoutinesReducer.routines) {
      this.setState({ routines });
    }
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  selectRoutine(routine) {
    this.setState({
      routineSelected: !this.state.routineSelected ? routine : null,
    });
  }

  render() {
    return (
      <div className="Seguimiento">
        <SeguimientoMap
          routineSelected={this.state.routineSelected}
          routines={this.state.routines}
        />
        <SeguimientoList
          routineSelected={this.state.routineSelected}
          selectRoutine={this.selectRoutine}
          routines={this.state.routines}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Seguimiento);
