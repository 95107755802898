import React, { useEffect, useState } from "react";
import "./RutinasCard.scss";

import RutinasMiniCard from "../RutinasMiniCard/RutinasMiniCard";
import { ReactComponent as IcoUnit } from "../../assets/motion_icon-unidad.svg";
import { ReactComponent as IcoPersonRosa } from "../../assets/persona_rosa.svg";
import { ReactComponent as IcoPersonBlue } from "../../assets/persona_azul.svg";
import { ReactComponent as DeleteRoutine } from "../../../../assets/motion_icon-detenido.svg";
import { ReactComponent as BlockRoutine } from "../../../../assets/motion_icon-clave.svg";
import { ReactComponent as EditRoutine } from "../../../../assets/motion_icon-edit.svg";
import {
  blockRoutine,
  deleteRoutine,
} from "../../../../redux/actions/routinesActions";
import { connect } from "react-redux";
import { RoutineModel } from "../../../../state/models/routineModel";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  deleteRoutine: (id) => {
    dispatch(deleteRoutine(id));
  },
  blockRoutine: (id) => {
    dispatch(blockRoutine(id));
  },
});

function RutinasCard(props) {
  const [routine, setRoutine] = useState(RoutineModel);
  const [position, setPosition] = useState(0);

  useEffect(() => {
    setRoutine(props.routine);
    setTimeout(() => {
      setPosition(props.routine.position);
    }, 1000);
  }, [props.routine]);

  const onDeleteRoutine = () => {
    props.deleteRoutine(routine.id);
  };

  const onBlockRoutine = () => {
    if (routine.state !== "block") props.blockRoutine(routine.id);
  };

  const onEditRoutine = () => {
    props.displayEditRoutine(routine);
  };

  console.log(routine);

  return (
    <div className="RutinasCard column">
      <div
        className={`${
          routine.status === "running"
            ? "active"
            : routine.status === "completed"
            ? "completed"
            : ""
        } RutinasCard-content`}
      >
        <div className="row rutinas-card-header">
          <div className="row rutinas-card-sub-header">
            <div className="RutinasCard-title">
              <div className="text-overflow">{routine.name}</div>
            </div>
            <div className="desc-counts row">
              <div className="content-counts row">
                <div className="content-ico-count">
                  <IcoUnit className="ico ico-unit" />
                </div>
                <div className="content-num-passenger row">
                  <div className="title">{position}</div>
                  <div className="between">/</div>
                  <div className="num-passengers">
                    {routine.schedules.length}
                  </div>
                </div>
              </div>
            </div>
            <div className="desc-counts row">
              <div className="content-counts row">
                <div className="content-ico-count">
                  <IcoPersonRosa className="ico ico-passenger" />
                </div>
                <div className="content-num-passenger row">
                  <div className="title">{routine.passenger_logs}</div>
                  <div className="between">/</div>
                  <div className="num-passengers">
                    {routine.passengers_total}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="rutinas-card-action-display"></div>
        </div>
        {/* <div className="rutinas-card-content">
          <div className="content-unit row">
            <div
              className="unit-animation"
              style={{ transform: `translateX(${position * 100}px)` }}
            > 
              <IcoUnit className="ico-unit" />
            </div>
          </div>
          <div className="content-stops row">
            {routine.schedules.map((schedule, index) => {
              return (
                <RutinasMiniCard
                  key={schedule.id}
                  sequence={index}
                  stop_name={schedule.stop_name}
                />
              );
            })}
          </div>
        </div> */}
        <div className="RutinasCard-footer row">
          <div className="row footer-text-content">
            <div className="footer-text">20:15</div>
            <div className="footer-text">20:15</div>
          </div>
          <div className="rutinas-card-actions row">
            <div className="row rutinas-card-actions-item">
              <EditRoutine
                className="RutinasCard-EditIco RutinasCard-ico"
                onClick={onEditRoutine}
              />
            </div>
            <div className="row rutinas-card-actions-item">
              <BlockRoutine
                className={`${
                  routine.state === "block" ? "Blocked" : ""
                } RutinasCard-BlockIco RutinasCard-ico`}
                onClick={onBlockRoutine}
              />
            </div>
            <div className="row rutinas-card-actions-item">
              <DeleteRoutine
                className="RutinasCard-DeleteIco RutinasCard-ico"
                onClick={onDeleteRoutine}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RutinasCard);
