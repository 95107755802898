import React, { useState, useEffect, useContext } from "react";
import "./AddSchedule.scss";
import ModalAddHour from "../../../ModalAddHour/ModalAddHour";
import useRoute from "../../../../../../../../state/hooks/useRoute";
import { ReactComponent as IcoMas } from "../../../../../../../../assets/Mas.svg";

export default function AddSchedule(props) {
  const { newRoute, setRoute } = useRoute();
  const [editschedule, setEditschedule] = useState(false);
  const [indexschedule, setindexschedule] = useState(0);
  const [showAddHour, setShowAddHour] = useState(false);
  const [loadingAddSchedule, setLoadingAddSchedule] = useState(false);

  useEffect(() => {
    if (
      newRoute.schedule.time_interval_ids.length < 1 &&
      !props.selectedRoute
    ) {
      addSchedule();
    }
  }, []);

  const handleEditModal = (index) => {
    setEditschedule(!editschedule);
    setindexschedule(index);
  };

  //Crea nuevo programacion
  const addSchedule = async (time, indexschedule, unit) => {
    setLoadingAddSchedule(true);
    let time_interval = {
      start_arrival: "",
      end_arrival: "",
      schedule_lines_ids: [],
      unit: unit,
    };

    var index = 0;
    // Para cada parada tenemos que crear un schedule_line

    let interval = setInterval(async () => {
      let i = index;
      let hour;
      let stop_id = props.exitStops[i].id;
      //Si ya existe un schedule_line buscamos en intervalo de tiempo con direction service sino lo empezamos desde el horario seleccionado
      if (i > 0) {
        await props.directionsService.route(
          {
            origin: {
              lat: props.exitStops[i - 1].center.coordinates[0],
              lng: props.exitStops[i - 1].center.coordinates[1],
            },
            destination: {
              lat: props.exitStops[i].center.coordinates[0],
              lng: props.exitStops[i].center.coordinates[1],
            },
            // Note that Javascript allows us to access the constant
            // using square brackets and a string value as its
            // "property."
            travelMode: props.maps.TravelMode.DRIVING,
            drivingOptions: {
              departureTime: new Date(Date.now()), // for the time N milliseconds from now.
              trafficModel: "optimistic",
            },
          },
          (response, status) => {
            if (status === "OK") {
              let before_time =
                time_interval.schedule_lines_ids[index - 1].arrival;
              // Get first route duration
              var route = response.routes[0];
              var duration = 0;

              route.legs.forEach(function (leg) {
                // The leg duration in seconds.
                duration += leg.duration.value;
              });
              hour = (duration + duration * 0.15) / 60 / 60;
              let time_line = {
                arrival: before_time + hour,
                stop_id: stop_id,
              };
              time_interval.schedule_lines_ids.push(time_line);
            } else {
              console.log(status);
            }
          }
        );
      } else {
        let time_line = {
          arrival: time ? time : 0.0,
          stop_id: stop_id,
        };
        time_interval.schedule_lines_ids.push(time_line);
      }
      index++;
      if (index === props.exitStops.length) {
        clearInterval(interval);
        if (time_interval.schedule_lines_ids.length > 0) {
          time_interval = {
            ...time_interval,
            start_arrival: time_interval.schedule_lines_ids[0].arrival,
            end_arrival:
              time_interval.schedule_lines_ids[
                time_interval.schedule_lines_ids.length - 1
              ].arrival,
          };
        }
        let newTimeInterval = [];
        newTimeInterval = [...newRoute.schedule.time_interval_ids];

        if (indexschedule || indexschedule === 0) {
          newTimeInterval[indexschedule] = time_interval;
        } else {
          newTimeInterval.push(time_interval);
        }
        setRoute({
          ...newRoute,
          schedule: {
            ...newRoute.schedule,
            time_interval_ids: newTimeInterval,
          },
        });
        setLoadingAddSchedule(false);
      }
    }, 800);
  };

  const removeschedule = () => {
    let newTimeInterval = [];
    newTimeInterval = [...newRoute.schedule.time_interval_ids];
    newTimeInterval.splice(indexschedule, 1);
    setRoute({
      ...newRoute,
      schedule: {
        ...newRoute.schedule,
        time_interval_ids: newTimeInterval,
      },
    });
  };

  const handleChangeHour = (e) => {
    var charCode = e.which ? e.which : e.keyCode;
    if (!(charCode > 31 && (charCode < 48 || charCode > 57))) {
      const { indexinterval, indextime, ds } = e.target.attributes;
      let dsHour = Math.floor(parseInt(ds.value) / 100);
      let dsMinute = Math.floor(
        (parseInt(ds.value) / 100 - Math.floor(parseInt(ds.value) / 100)) * 100
      );

      const { name } = e.target;
      const { key } = e;

      let newHour = dsHour.toString().split("");
      let newMinute = dsMinute.toString().split("");

      if (name === "d1") {
        if (key <= 2) {
          if (dsHour < 10) {
            dsHour = parseInt(key + 0);
          } else {
            dsHour = parseInt(key + newHour[1]);
          }
        }
      } else if (name === "d2") {
        if (dsHour < 20 && dsHour >= 0 && key < 10) {
          if (dsHour < 10) {
            dsHour = parseInt(0 + key);
          } else {
            dsHour = parseInt(newHour[0] + key);
          }
        } else if (dsHour >= 20 && dsHour < 25 && key < 5) {
          dsHour = parseInt(newHour[0] + key);
        }
      } else if (name === "d3") {
        if (key < 6) {
          if (dsMinute.toString().length < 1) {
            dsMinute = parseInt(key + newMinute[1]);
          } else {
            dsMinute = parseInt(key + 0);
          }
        }
      } else if (name === "d4") {
        if (dsMinute < 60) {
          if (dsMinute.toString().length < 1) {
            dsMinute = parseInt(newMinute[0] + key);
          } else {
            dsMinute = parseInt(newMinute[0] + key);
          }
        }
      }
      const endTime = parseInt(dsHour) + dsMinute / 60;
      const ischedule = parseInt(indexinterval.value);
      const iTime = parseInt(indextime.value);

      let newTimeInterval = [];
      newTimeInterval = [...newRoute.schedule.time_interval_ids];

      newTimeInterval[ischedule].schedule_lines_ids[iTime] = {
        arrival: endTime,
        stop_id: newTimeInterval[ischedule].schedule_lines_ids[iTime].stop_id,
      };

      setRoute({
        ...newRoute,
        schedule: {
          ...newRoute.schedule,
          time_interval_ids: newTimeInterval,
        },
      });
    }
  };

  return (
    <div className="AddSchedule column">
      <div className="actions-title row">
        <div className="title"> Selecione los horarios</div>
        <div className="ModalRutas-horarios-actions">
          <div className="icon-content">
            <IcoMas className="IcoMas" onClick={setShowAddHour} />
            {editschedule ? (
              <ModalAddHour
                index={indexschedule}
                close={handleEditModal}
                check={addSchedule}
                time={
                  newRoute.schedule.time_interval_ids[indexschedule]
                    .schedule_lines_ids[0].arrival
                }
                delete={removeschedule}
                units={newRoute.units}
                unit={newRoute.schedule.time_interval_ids[indexschedule].unit}
              />
            ) : null}
            {showAddHour ? (
              <ModalAddHour
                close={() => setShowAddHour(false)}
                check={addSchedule}
                units={newRoute.units}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className="days-body row">
        {!loadingAddSchedule ? (
          newRoute.schedule.time_interval_ids.map((time_interval, index) => {
            const indexInterval = index;
            return (
              <div className="column body-content-item" key={indexInterval}>
                {time_interval.schedule_lines_ids.map(
                  (schedule_line, index) => {
                    const indextime = index;
                    const time = schedule_line.arrival;
                    var horas = Math.floor(time);
                    var minutos = Math.floor((time - Math.floor(time)) * 60);

                    let d1 = horas < 10 ? 0 : Math.floor(horas / 10);
                    let d2 =
                      horas >= 10
                        ? Math.round((horas / 10 - Math.trunc(horas / 10)) * 10)
                        : horas;
                    let d3 = minutos < 10 ? 0 : Math.floor(minutos / 10);
                    let d4 =
                      minutos >= 10
                        ? Math.round(
                            (minutos / 10 - Math.floor(minutos / 10)) * 10
                          )
                        : minutos;

                    return (
                      <div
                        className={`row content-hour ${
                          index === 0 ? "focus" : ""
                        }`}
                        key={index}
                      >
                        {index === 0 ? (
                          <div
                            className="row"
                            onClick={() => handleEditModal(indexInterval)}
                          >
                            <div className="item-hour">{`${d1}`}</div>
                            <div className="item-hour">{`${d2}`}</div>
                            <div className="item-hour">:</div>
                            <div className="item-hour">{`${d3}`}</div>
                            <div className="item-hour">{`${d4}`}</div>
                          </div>
                        ) : (
                          <>
                            <input
                              className="item-text item-hour"
                              type="text"
                              value={d1}
                              name="d1"
                              ds={`${d1}${d2}${d3}${d4}`}
                              indexinterval={indexInterval}
                              indextime={indextime}
                              onKeyPress={handleChangeHour}
                              readOnly
                            />
                            <input
                              className="item-text item-hour"
                              type="text"
                              value={d2}
                              name="d2"
                              ds={`${d1}${d2}${d3}${d4}`}
                              indexinterval={indexInterval}
                              indextime={indextime}
                              onKeyPress={handleChangeHour}
                              readOnly
                            />
                            <div className="item-hour">:</div>
                            <input
                              className="item-text item-hour"
                              type="text"
                              value={d3}
                              name="d3"
                              ds={`${d1}${d2}${d3}${d4}`}
                              indexinterval={indexInterval}
                              indextime={indextime}
                              onKeyPress={handleChangeHour}
                              readOnly
                            />
                            <input
                              className="item-text item-hour"
                              type="text"
                              value={d4}
                              name="d4"
                              ds={`${d1}${d2}${d3}${d4}`}
                              indexinterval={indexInterval}
                              indextime={indextime}
                              onKeyPress={handleChangeHour}
                              readOnly
                            />
                          </>
                        )}
                      </div>
                    );
                  }
                )}
              </div>
            );
          })
        ) : (
          <div>Cargando...</div>
        )}

        <div className="column Addschedule-header">
          {props.exitStops.map((stop, index) => {
            return (
              <div
                className={`row header-content-item ${
                  index === 0 ? "focus" : ""
                }`}
                key={index}
              >
                <div className="item-index">{index}</div>
                <div className="item-text">{stop.name}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
