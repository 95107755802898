export const GET_ROUTES = "GET_ROUTES";
export const GET_ROUTES_SUCCESS = "GET_ROUTES_SUCCESS";
export const GET_ROUTES_FAILED = "GET_ROUTES_FAILED";

export const GET_SINGLE_ROUTE = "GET_SINGLE_ROUTE";
export const GET_SINGLE_ROUTE_SUCCESS = "GET_SINGLE_ROUTE_SUCCESS";
export const GET_SINGLE_ROUTE_FAILED = "GET_SINGLE_ROUTE_FAILED";

export const CREATE_ROUTE = "CREATE_ROUTE";
export const CREATE_ROUTE_SUCCESS = "CREATE_ROUTE_SUCCESS";
export const CREATE_ROUTE_FAILED = "CREATE_ROUTE_FAILED";
export const CREATE_ROUTE_END = "CREATE_ROUTE_END";

export const EDIT_ROUTE = "EDIT_ROUTE";
export const EDIT_ROUTE_SUCCESS = "EDIT_ROUTE_SUCCESS";
export const EDIT_ROUTE_FAILED = "EDIT_ROUTE_FAILED";
export const EDIT_ROUTE_END = "EDIT_ROUTE_END";

export const DELETE_ROUTE = "EDIT_ROUTE";
export const DELETE_ROUTE_SUCCESS = "EDIT_ROUTE_SUCCESS";
export const DELETE_ROUTE_FAILED = "EDIT_ROUTE_FAILED";
export const DELETE_ROUTE_END = "EDIT_ROUTE_END";
