import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "./Login.scss";

import { MotionLoginEmailPassword } from "../../redux/actions/authActions";
import logoMotion from "../../assets/motion-login_miroute-icon.svg";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      agreement: "",
      auth: localStorage.getItem("auth_user"),
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange = (event) => {
    event.persist();
    this.setState({ [event.target.name]: event.target.value });
  };
  handleFormSubmit = (event) => {
    event.preventDefault();
    let email = this.state.email;
    let password = this.state.password;
    this.props.MotionLoginEmailPassword({ email, password });
  };
  componentDidMount() {
    if (this.state.auth != null) {
      window.location.replace("/");
    } else {
      console.log("no se encontro auth en local storage");
    }
  }
  render() {
    return (
      <div className="bg-gradient">
        <div className="bg-photo"></div>
        <div className="bg-gradient-2"></div>
        <div className="logo-motion">
          <a href="https://monitoringinnovation.com" className="link-signin">
            <img src={logoMotion} alt="" />
          </a>
        </div>
        <div className="">
          <form
            onSubmit={this.handleFormSubmit}
            className="form-card white-card"
          >
            <div className="input-div">
              <label>Usuario*</label>
              <input
                type="text"
                placeholder="Nombre de usuario"
                validations={[required]}
                onChange={this.handleChange}
                name="email"
                value={this.state.email}
              />
            </div>

            <div className="input-div">
              <label>Clave de acceso*</label>
              <input
                minlength="6"
                type="password"
                placeholder="Contraseña"
                onChange={this.handleChange}
                name="password"
                value={this.state.password}
                validations={[required]}
              />
            </div>

            <div className="form-selections">
              <a className="red" href="#">
                He olvidado mi contraseña
              </a>
              <br />

              <button className="btn1" type="submit" name="Ingresar">
                Iniciar sesión
              </button>
              <br />

              <a className="red" href="#">
                Registrarme
              </a>
            </div>
          </form>
        </div>
        <div className="copyright">
          <a className="red" href="#">
            <span>&copy; 2020 Copyright / </span> A.B Comercial LTDA
          </a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    MotionLoginEmailPassword: PropTypes.func.isRequired,
    login: state.login,
  };
};

export default connect(mapStateToProps, { MotionLoginEmailPassword })(Login);
