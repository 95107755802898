import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import useRoute from "../../../../../../state/hooks/useRoute";
import "./SelectUnits.scss";

const mapStateToProps = (state) => {
  return {
    UnitsReducer: state.UnitsReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({});

function SelectUnits(props) {
  const { newRoute, setRoute } = useRoute();
  const [units, setUnits] = useState([]);

  const [searchParams] = useState(["name"]);

  useEffect(() => {
    setUnits(props.UnitsReducer.units);
  }, [props.UnitsReducer]);

  const selectAllUnit = (e) => {
    const { checked } = e.target;
    if (checked) {
      setRoute({ ...newRoute, units: [...units] });
    } else {
      setRoute({
        ...newRoute,
        units: [],
      });
    }
  };


  const handleChangeUnit = (e) => {
    const { checked, id } = e.target;
    if (checked) {
      const outUnit = units.find((unit) => unit.id === parseInt(id));
      setRoute({
        ...newRoute,
        units: [...newRoute.units, outUnit],
      });
    } else {
      const outUnit = newRoute.units.filter((unit) => unit.id !== parseInt(id));
      setRoute({
        ...newRoute,
        units: [...outUnit],
      });
    }
  };

  const filter = (event) => {
    var search = event.target.value;
    const data = props.UnitsReducer.units;
    const searchParam = searchParams;
    const newData = data.filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem].toString().toLowerCase().indexOf(search.toLowerCase()) >
          -1
        );
      });
    });

    setUnits(newData);
  };

  return (
    <div className="ModalRutas-paradas">
      {!props.UnitsReducer.isLoading ? (
        <form className="ModalRutas-paradas-form column">
          <div className="paradas-header row">
            <div className="header-item row">
              <input
                type="checkbox"
                className="header-item-check m-checkbox__input switch"
                onChange={selectAllUnit}
                checked={newRoute.units.length === units.length ? true : false}
              />
              <div className="header-item-check-text">Seleccionar todo</div>
            </div>
            <div className="header-item">
              <input
                type="search"
                name=""
                id=""
                placeholder="Buscar"
                className="header-item-input"
                onChange={filter}
              />
            </div>
          </div>
          <div className="paradas-body">
            {units.map((unit) => {
              let exit = newRoute.units.find(
                (unitRoute) => unitRoute.id === unit.id
              );
              return (
                <div className="paradas-body-item row" key={unit.id}>
                  <input
                    name="isGoing"
                    type="checkbox"
                    className="m-checkbox__input switch"
                    onChange={handleChangeUnit}
                    id={unit.id}
                    checked={exit ? true : false}
                  />
                  <div>
                    <div className="paradas-body-item-text">{unit.name}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </form>
      ) : (
        <div>Loading</div>
      )}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectUnits);
